import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontNavigation } from 'styles';

export const ToolbarNavigationWrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
`;

const NavigationLinkBase = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;

  background: ${p => p.theme.color.primary_turquoise};

  &.active {
    background: ${p => p.theme.color.primary_turquoise_dark};
  }

  &:hover {
    background: ${p => p.theme.color.primary_turquoise_dark};
  }
`;

export const NavigationLinkBig = styled(NavigationLinkBase)`
  padding: ${p => `${p.theme.toRem(22)} ${p.theme.toRem(16)}`};
`;

export const NavigationLinkSmall = styled(NavigationLinkBase)`
  padding: ${p => `${p.theme.toRem(22)} ${p.theme.toRem(10)}`};
`;

export const NavigationLabel = styled.span`
  ${FontNavigation};
  color: ${props => props.theme.color.primary_dark_blue};
  overflow: hidden;
  max-width: ${p => p.theme.toRem(120)};
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
`;

import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { BlackLink } from 'components/Link';
import { IconClose } from 'icons';
import { AlignmentEnum, OuterWrapper } from 'layouts/OuterWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskTemplate, useTaskTemplateLoadedTemplate } from 'stores/TaskTemplate';
import { textToTaskType } from './helpers';
import { messages } from './messages';
import { ContentGrid, CustomMandarinLink, CustomTransparentLink, Wrapper } from './style';

export function TaskTemplateDetailHeader() {
  const { t } = useTranslation();
  const loadedTaskTemplate: TaskTemplate | null = useTaskTemplateLoadedTemplate();
  const activeClass: SchoolClassComplete | SchoolClass | null = useSchoolClassActive();

  if (!loadedTaskTemplate) {
    return null;
  }

  const urlSearchParamsForTaskCreation = new URLSearchParams({
    templateId: loadedTaskTemplate?.id!,
  });

  const linkToTaskCreation: string = LinkBuilder.CreateTask(
    activeClass?.id!,
    textToTaskType(loadedTaskTemplate.text_type.meta.name!),
  );

  return (
    <Wrapper>
      <OuterWrapper $alignment={AlignmentEnum.CENTER}>
        <ContentGrid>
          <BlackLink to={LinkBuilder.TaskTemplates(activeClass?.id!)}>{t(messages.backToTemplates)}</BlackLink>
          <span />
          <CustomMandarinLink to={[linkToTaskCreation, urlSearchParamsForTaskCreation.toString()].join('?')}>
            {t(messages.setTask)}
          </CustomMandarinLink>
          <CustomTransparentLink to={LinkBuilder.TaskTemplates(activeClass?.id!)}>
            <IconClose />
          </CustomTransparentLink>
        </ContentGrid>
      </OuterWrapper>
    </Wrapper>
  );
}

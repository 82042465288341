import styled from 'styled-components';

interface ImageProps {
  $height?: number;
  $width?: number;
}

export const Wrapper = styled.div<ImageProps>`
  height: ${p => (p.$height ? `${p.$height}px` : '100%')};
  width: ${p => (p.$width ? `${p.$width}px` : '100%')};
`;

export const StyledImage = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: cover;
`;

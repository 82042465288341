import { IconBlackDot } from 'icons';
import React from 'react';
import { Wrapper } from './style';

export function IconThreeDots() {
  return (
    <Wrapper>
      <IconBlackDot />
      <IconBlackDot />
      <IconBlackDot />
    </Wrapper>
  );
}

import { TransparentButton } from 'components/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontBody, FontNavigation } from 'styles';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: ${p => p.theme.toRem(40)};
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${p => p.theme.toRem(1)};
`;

export const LinkBody = styled(Link)`
  ${FontBody};
  color: ${props => props.theme.color.primary_dark_blue};
  text-decoration: none;
`;

export const StudentNameSpan = styled.p`
  color: ${props => props.theme.color.primary_dark_blue};
  ${FontNavigation}
`;

const BaseButton = styled(TransparentButton)`
  color: ${props => props.theme.color.primary_dark_blue};
`;

export const TeacherLogoutButton = styled(BaseButton)`
  ${FontBody};
`;

export const StudentLogoutButton = styled(BaseButton)`
  ${FontNavigation};
`;

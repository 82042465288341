import styled from 'styled-components';
import { ReactComponent as ActiveCheckedCircle } from './active-checked-circle.svg';
import { ReactComponent as ArrowDownBlack } from './arrow-down-black.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRightNoCircle } from './arrow-right-no-circle.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowSort } from './arrow-sort.svg';
import { ReactComponent as BlackDot } from './black-dot.svg';
import { ReactComponent as Bold } from './bold.svg';
import { ReactComponent as Bullet } from './bullet.svg';
import { ReactComponent as CameraWhite } from './camera-white.svg';
import { ReactComponent as CheckedPetrol } from './checked-petrol.svg';
import { ReactComponent as CheckedBlack } from './checked.svg';
import { ReactComponent as CloseSmall } from './close-small.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as DoubleArrowLeft } from './double-arrow-left.svg';
import { ReactComponent as DoubleArrowRight } from './double-arrow-right.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as DownloadBlack } from './download_black.svg';
import { ReactComponent as DownloadWhite } from './download_white.svg';
import { ReactComponent as ExpandWhite } from './expand-white.svg';
import { ReactComponent as CloseWhite } from './icon-close-white.svg';
import { ReactComponent as InactiveCheckedCircle } from './inactive-checked-circle.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Italic } from './italic.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as MinusWhite } from './minus-white.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Picture } from './picture.svg';
import { ReactComponent as PlusWhite } from './plus-white.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Print } from './print.svg';
import { ReactComponent as SearchWhite } from './search-white.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as TaskNoImage } from './task-no-image.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Underline } from './underline.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Warning } from './warning.svg';

export const IconPlus = styled(Plus)`
  width: 24px;
  height: 24px;
`;

export const IconClose = styled(Close)`
  width: 32px;
  height: 32px;
`;

export const IconCloseMedium = styled(Close)`
  width: 19px;
  height: 19px;
`;

export const IconBold = styled(Bold)`
  width: 30px;
  height: 30px;
`;

export const IconBullet = styled(Bullet)`
  width: 30px;
  height: 30px;
`;

export const IconItalic = styled(Italic)`
  width: 30px;
  height: 30px;
`;

export const IconUnderline = styled(Underline)`
  width: 30px;
  height: 30px;
`;

export const IconList = styled(List)`
  width: 30px;
  height: 30px;
`;

export const IconArrowDownBlack = styled(ArrowDownBlack)`
  width: 24px;
  height: 24px;
`;

export const IconTrash = styled(Trash)`
  width: 24px;
  height: 24px;
`;

export const IconArrowSort = styled(ArrowSort)`
  width: 14px;
  height: 8px;
`;

export const IconPrint = styled(Print)`
  width: 24px;
  height: 24px;
`;

export const IconDownload = styled(Download)`
  width: 24px;
  height: 24px;
`;

export const IconDownloadWhite = styled(DownloadWhite)`
  width: 20px;
  height: 20px;
`;

export const IconDownloadBlack = styled(DownloadBlack)`
  width: 20px;
  height: 20px;
`;

export const IconTaskNoImage = styled(TaskNoImage)`
  width: 48px;
  height: 50x;
`;

export const IconDoubleArrowLeft = styled(DoubleArrowLeft)`
  width: 14px;
  height: 12x;
`;

export const IconDoubleArrowRight = styled(DoubleArrowRight)`
  width: 14px;
  height: 12x;
`;

export const IconCheckedPetrol = styled(CheckedPetrol)`
  width: 24px;
  height: 22x;
`;

export const IconActiveCheckedCircle = styled(ActiveCheckedCircle)`
  width: 30px;
  height: 30px;
`;

export const IconInactiveCheckedCircle = styled(InactiveCheckedCircle)`
  width: 30px;
  height: 30px;
`;

export const IconUsers = styled(Users)`
  width: 22px;
  height: 22px;
`;

export const IconCloseSmall = styled(CloseSmall)`
  width: 12px;
  height: 12px;
`;

export const IconArrowLeft = styled(ArrowLeft)`
  width: 30px;
  height: 30px;
`;

export const IconArrowRight = styled(ArrowRight)`
  width: 30px;
  height: 30px;
`;

export const IconPencil = styled(Pencil)`
  width: 21px;
  height: 21px;
`;

export const IconPicture = styled(Picture)`
  width: 30px;
  height: 30px;
`;

export const IconArrowRightNoCircle = styled(ArrowRightNoCircle)`
  width: 20px;
  height: 16px;
`;

export const IconSearch = styled(Search)`
  width: 24px;
  height: 24px;
`;

export const IconSearchWhite = styled(SearchWhite)`
  width: 24px;
  height: 24px;
`;

export const IconCheckedBlack = styled(CheckedBlack)`
  width: 24px;
  height: 24px;
`;

export const IconBlackDot = styled(BlackDot)`
  width: 5px;
  height: 5px;
`;

export const IconInfo = styled(Info)`
  width: 20px;
  height: 20px;
`;

export const IconCloseWhite = styled(CloseWhite)`
  width: 30px;
  height: 30px;
`;

export const IconExpandWhite = styled(ExpandWhite)`
  width: 30px;
  height: 30px;
`;

export const IconPlusWhite = styled(PlusWhite)`
  width: 30px;
  height: 30px;
`;

export const IconMinusWhite = styled(MinusWhite)`
  width: 30px;
  height: 30px;
`;

export const IconCameraWhite = styled(CameraWhite)`
  width: 30px;
  height: 30px;
`;

export const IconWarning = styled(Warning)`
  width: 28px;
  height: 28px;
`;

export const IconWarningMedium = styled(Warning)`
  width: 24px;
  height: 24px;
`;

export * from './IconThreeDots';

export enum Routes {
  Init = '/',
  Login = '/login',
  LoginWithCredentials = '/login/:schoolNumber/:token',
  LoginWithSchoolNumber = '/login/:schoolNumber',
  Home = '/start',
  TextPrint = '/print/text/:textId',

  /* STUDENT */
  SelectTextType = '/meine-texte/neuer',
  CreateText = '/meine-texte/neu/:textType',
  EditText = '/meine-texte/bearbeiten/:textType/text/:textId',
  MyTexts = '/meine-texte',
  TextDetail = '/meine-texte/detail/:textId',
  TeacherTextDetail = '/meine-texte/klasse/:classId/texte/:textId',
  MyTasks = '/meine-aufgaben',
  TextReviewer = '/meine-aufgaben/text/:textId/reviewers',
  StudentLibrary = '/lesetexte',
  StudentWriteTextWithTaskId = '/meine-aufgaben/aufgabe/:taskId',
  StudentWriteTextWithTaskTextId = '/meine-aufgaben/texte/:textId',
  MyWorld = '/meine-welt',

  /* TEACHER */
  ClassOverview = '/lehrkraft/class-overview',
  TeacherTasksOverview = '/lehrkraft/klasse/:classId/tasks',
  SelectTaskType = '/lehrkraft/klasse/:classId/tasks/new-task',
  ClassStatistics = '/lehrkraft/klasse/:classId/statistik',
  CreateTask = '/lehrkraft/klasse/:classId/create-task/:taskType',
  TeacherEditTask = '/lehrkraft/klasse/:classId/edit-task/:taskId',
  ClassDashboard = '/lehrkraft/klasse/:classId/dashboard',
  TextControl = '/lehren/klasse/:classId/textkontrolle',
  TextLibrary = '/lehren/klasse/:classId/bibliothek',
  ClassEditor = '/lehren/lerngruppen/:classId/bearbeiten',
  TaskTracking = '/lehren/lerngruppen/:classId/aufgabe/:taskId/verfolgen',
  TeacherReviewText = '/lehren/klasse/:classId/bearbeiten/texte/:textId',
  TeacherTaskTemplates = '/lehren/klasse/:classId/aufgaben-vorlagen',
  TeacherTaskTemplateDetail = '/lehren/klasse/:classId/aufgaben-vorlagen/template/:templateId',
}

export interface HelmetProps {
  title: string;
  description: string;
}

export enum RouteRoleEnum {
  TEACHER = 'teacher',
  STUDENT = 'student',
}

export enum OriginEnum {
  REVIEWER = 'reviewer',
  MYTEXTS = 'mytexts',
}

export enum UrlParamsEnum {
  ORIGIN = 'origin',
}

import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { Image } from 'components/Image';
import React from 'react';
import { Routes } from 'routes';
import { ToolbarLogoButton, ToolBarLogoLink } from './style';

interface ToolbarLogoProps {
  redirectUrl?: string;
  handleClickAction?: () => void;
}

export function ToolbarLogo({ redirectUrl, handleClickAction }: ToolbarLogoProps) {
  const { isTeacher } = useUserAccountContext();

  const loadedClass: SchoolClass | SchoolClassComplete | null = useSchoolClassActive();

  let link: string = Routes.Home;

  if (isTeacher() && loadedClass) {
    link = LinkBuilder.TeacherClassDashboard(loadedClass.id!);
  }

  const Logo = () => {
    return <Image src="/assets/logo-small.svg" alt="Skribi" width={106} height={34} />;
  };

  return !!handleClickAction ? (
    <ToolbarLogoButton type="button" onClick={handleClickAction}>
      <Logo />
    </ToolbarLogoButton>
  ) : (
    <ToolBarLogoLink to={redirectUrl ?? link}>
      <Logo />
    </ToolBarLogoLink>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface SchoolPartial
 */
export interface SchoolPartial {
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  number: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolPartial
   */
  email?: string;
}

/**
 * Check if a given object implements the SchoolPartial interface.
 */
export function instanceOfSchoolPartial(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'number' in value;

  return isInstance;
}

export function SchoolPartialFromJSON(json: any): SchoolPartial {
  return SchoolPartialFromJSONTyped(json, false);
}

export function SchoolPartialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolPartial {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    number: json['number'],
    type: !exists(json, 'type') ? undefined : json['type'],
    address: !exists(json, 'address') ? undefined : json['address'],
    postCode: !exists(json, 'post_code') ? undefined : json['post_code'],
    locality: !exists(json, 'locality') ? undefined : json['locality'],
    telephone: !exists(json, 'telephone') ? undefined : json['telephone'],
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function SchoolPartialToJSON(value?: SchoolPartial | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    number: value.number,
    type: value.type,
    address: value.address,
    post_code: value.postCode,
    locality: value.locality,
    telephone: value.telephone,
    email: value.email,
  };
}

import { OuterWrapper } from 'layouts/OuterWrapper';
import styled from 'styled-components';

export const Wrapper = styled(OuterWrapper)`
  grid-template-columns: ${p => `${p.theme.toRem(106)} auto ${p.theme.toRem(85)}`};
  align-items: center;
`;

export const NavigationPadding = styled.div`
  padding: ${p => `0 ${p.theme.toRem(10)} 0 ${p.theme.toRem(30)}`};
  width: 100%;
`;

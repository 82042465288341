/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TeacherBasic } from '../models';
import { TeacherBasicFromJSON } from '../models';
import * as runtime from '../runtime';

export interface AddTeacherToClassRequest {
  classId: string;
  teacherId: string;
}

export interface GetTeachersOfClassRequest {
  classId: string;
}

export interface RemoveTeacherFromClassRequest {
  classId: string;
  teacherId: string;
}

/**
 *
 */
export class ClassTeachersApi extends runtime.BaseAPI {
  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school. * The referenced class must be accessible to its school. * The referenced teacher must be member of the same school. # Postconditions * The referenced teacher is added as a member of the class.
   * Adds a teacher to a class
   */
  async addTeacherToClassRaw(
    requestParameters: AddTeacherToClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling addTeacherToClass.',
      );
    }

    if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
      throw new runtime.RequiredError(
        'teacherId',
        'Required parameter requestParameters.teacherId was null or undefined when calling addTeacherToClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}/teachers/{teacherId}`
          .replace(`{${'classId'}}`, encodeURIComponent(String(requestParameters.classId)))
          .replace(`{${'teacherId'}}`, encodeURIComponent(String(requestParameters.teacherId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school. * The referenced class must be accessible to its school. * The referenced teacher must be member of the same school. # Postconditions * The referenced teacher is added as a member of the class.
   * Adds a teacher to a class
   */
  async addTeacherToClass(
    requestParameters: AddTeacherToClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.addTeacherToClassRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must be a member of the referenced class or must be allowed to manage any school.
   * Gets the teachers of a class
   */
  async getTeachersOfClassRaw(
    requestParameters: GetTeachersOfClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TeacherBasic>>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling getTeachersOfClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}/teachers`.replace(
          `{${'classId'}}`,
          encodeURIComponent(String(requestParameters.classId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TeacherBasicFromJSON));
  }

  /**
   * ## Preconditions * Authenticated user must be a member of the referenced class or must be allowed to manage any school.
   * Gets the teachers of a class
   */
  async getTeachersOfClass(
    requestParameters: GetTeachersOfClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TeacherBasic>> {
    const response = await this.getTeachersOfClassRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school. * The referenced teacher must be member of the class. * There must be at least one additional teacher assigned to the class. # Postconditions * The referenced teacher is removed from the class.
   * Removes a teacher from a class
   */
  async removeTeacherFromClassRaw(
    requestParameters: RemoveTeacherFromClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling removeTeacherFromClass.',
      );
    }

    if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
      throw new runtime.RequiredError(
        'teacherId',
        'Required parameter requestParameters.teacherId was null or undefined when calling removeTeacherFromClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}/teachers/{teacherId}`
          .replace(`{${'classId'}}`, encodeURIComponent(String(requestParameters.classId)))
          .replace(`{${'teacherId'}}`, encodeURIComponent(String(requestParameters.teacherId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school. * The referenced teacher must be member of the class. * There must be at least one additional teacher assigned to the class. # Postconditions * The referenced teacher is removed from the class.
   * Removes a teacher from a class
   */
  async removeTeacherFromClass(
    requestParameters: RemoveTeacherFromClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.removeTeacherFromClassRaw(requestParameters, initOverrides);
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface StudentPartial
 */
export interface StudentPartial {
  /**
   *
   * @type {string}
   * @memberof StudentPartial
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof StudentPartial
   */
  lastName?: string;
  /**
   *
   * @type {{ [key: string]: string | undefined; }}
   * @memberof StudentPartial
   */
  profile?: { [key: string]: string | undefined };
}

/**
 * Check if a given object implements the StudentPartial interface.
 */
export function instanceOfStudentPartial(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function StudentPartialFromJSON(json: any): StudentPartial {
  return StudentPartialFromJSONTyped(json, false);
}

export function StudentPartialFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentPartial {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    profile: !exists(json, 'profile') ? undefined : json['profile'],
  };
}

export function StudentPartialToJSON(value?: StudentPartial | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.firstName,
    last_name: value.lastName,
    profile: value.profile,
  };
}

import { H1, H2 } from 'components/Headings';
import { Image } from 'components/Image';
import DOMPurify from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TaskTemplate,
  useTaskTemplateGetOrderedGradesFromLoadedTemplate,
  useTaskTemplateLoadedTemplate,
} from 'stores/TaskTemplate';
import { defaultTheme } from 'styles/defaultTheme';
import { messages } from './messages';
import {
  AditionalMaterialLink,
  AditionalTextsBlock,
  AditionalTextTitle,
  BorderedWrapper,
  ContentWrapper,
  ImageWrapper,
  MetadataWrapper,
  MetaLabel,
  MetaValue,
  TextBlock,
  TitleBlock,
  Wrapper,
} from './style';
import { TaskTemplateDetailHeader } from './TaskTemplateDetailHeader';

export function TaskTemplateDetailContent() {
  const { t } = useTranslation();
  const loadedTemplate: TaskTemplate | null = useTaskTemplateLoadedTemplate();
  const templateGrades: string | undefined = useTaskTemplateGetOrderedGradesFromLoadedTemplate()?.join(', ');

  if (!loadedTemplate) {
    return null;
  }

  return (
    <Wrapper>
      <TaskTemplateDetailHeader />

      <ContentWrapper>
        <TitleBlock>
          <H1>{loadedTemplate.title}</H1>

          <MetadataWrapper>
            <MetaLabel>{t(messages.textFunction)}</MetaLabel>
            <MetaValue>{loadedTemplate.text_function.meta.name}</MetaValue>

            <MetaLabel>{t(messages.suitableForGrades)}</MetaLabel>
            <MetaValue>{templateGrades}</MetaValue>

            <MetaLabel>{t(messages.textType)}</MetaLabel>
            <MetaValue>{loadedTemplate.text_type.meta.name}</MetaValue>
          </MetadataWrapper>
        </TitleBlock>

        {!!loadedTemplate?.image?.meta?.file && (
          <ImageWrapper>
            <Image alt={loadedTemplate.image.meta.alt ?? loadedTemplate.title} src={loadedTemplate.image.meta.file} />
          </ImageWrapper>
        )}

        <TextBlock dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(loadedTemplate.task_text) }} />

        <AditionalTextsBlock>
          {!!loadedTemplate.support_text_writing && (
            <BorderedWrapper $borderColor={defaultTheme.colors.primary_mandarin}>
              <AditionalTextTitle>{t(messages.textForWriter)}</AditionalTextTitle>
              <TextBlock
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(loadedTemplate.support_text_writing) }}
              />
            </BorderedWrapper>
          )}

          {!!loadedTemplate.support_text_commenting && (
            <BorderedWrapper $borderColor={defaultTheme.colors.primary_turquoise_dark}>
              <AditionalTextTitle>{t(messages.textForReviewer)}</AditionalTextTitle>
              <TextBlock
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(loadedTemplate.support_text_commenting) }}
              />
            </BorderedWrapper>
          )}
        </AditionalTextsBlock>

        {loadedTemplate.additional_material && loadedTemplate.additional_material.length > 0 && (
          <AditionalTextsBlock>
            <H2>{t(messages.aditionalInformation)}</H2>
            {loadedTemplate.additional_material.map(material => (
              <AditionalMaterialLink href={material.meta.file} target="_blank" rel="noreferrer">
                {t(messages.linkForMaterial, { materialName: material.meta.name })}
              </AditionalMaterialLink>
            ))}
          </AditionalTextsBlock>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

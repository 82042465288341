import { css } from 'styled-components';

import quicksandRegularEot300 from './quicksand-v28-latin-300.eot';
import quicksandRegularSvg300 from './quicksand-v28-latin-300.svg';
import quicksandRegularTtf300 from './quicksand-v28-latin-300.ttf';
import quicksandRegularWoff300 from './quicksand-v28-latin-300.woff';
import quicksandRegularWoff2300 from './quicksand-v28-latin-300.woff2';

import quicksandRegularEot400 from './quicksand-v28-latin-regular.eot';
import quicksandRegularSvg400 from './quicksand-v28-latin-regular.svg';
import quicksandRegularTtf400 from './quicksand-v28-latin-regular.ttf';
import quicksandRegularWoff400 from './quicksand-v28-latin-regular.woff';
import quicksandRegularWoff2400 from './quicksand-v28-latin-regular.woff2';

import quicksandRegularEot500 from './quicksand-v28-latin-500.eot';
import quicksandRegularSvg500 from './quicksand-v28-latin-500.svg';
import quicksandRegularTtf500 from './quicksand-v28-latin-500.ttf';
import quicksandRegularWoff500 from './quicksand-v28-latin-500.woff';
import quicksandRegularWoff2500 from './quicksand-v28-latin-500.woff2';

import quicksandRegularEot600 from './quicksand-v28-latin-600.eot';
import quicksandRegularSvg600 from './quicksand-v28-latin-600.svg';
import quicksandRegularTtf600 from './quicksand-v28-latin-600.ttf';
import quicksandRegularWoff600 from './quicksand-v28-latin-600.woff';
import quicksandRegularWoff2600 from './quicksand-v28-latin-600.woff2';

import quicksandRegularEot700 from './quicksand-v28-latin-700.eot';
import quicksandRegularSvg700 from './quicksand-v28-latin-700.svg';
import quicksandRegularTtf700 from './quicksand-v28-latin-700.ttf';
import quicksandRegularWoff700 from './quicksand-v28-latin-700.woff';
import quicksandRegularWoff2700 from './quicksand-v28-latin-700.woff2';

export const Index = css`
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    src: url(${quicksandRegularEot300});
    src: local(''), url(${quicksandRegularEot300}?#iefix) format('embedded-opentype'),
      url(${quicksandRegularWoff2300}) format('woff2'), url(${quicksandRegularWoff300}) format('woff'),
      url(${quicksandRegularTtf300}) format('truetype'), url(${quicksandRegularSvg300}#Quicksand) format('svg');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url(${quicksandRegularEot400});
    src: local(''), url(${quicksandRegularEot400}?#iefix) format('embedded-opentype'),
      url(${quicksandRegularWoff2400}) format('woff2'), url(${quicksandRegularWoff400}) format('woff'),
      url(${quicksandRegularTtf400}) format('truetype'), url(${quicksandRegularSvg400}#Quicksand) format('svg');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: url(${quicksandRegularEot500});
    src: local(''), url(${quicksandRegularEot500}?#iefix) format('embedded-opentype'),
      url(${quicksandRegularWoff2500}) format('woff2'), url(${quicksandRegularWoff500}) format('woff'),
      url(${quicksandRegularTtf500}) format('truetype'), url(${quicksandRegularSvg500}#Quicksand) format('svg');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    src: url(${quicksandRegularEot600});
    src: local(''), url(${quicksandRegularEot600}?#iefix) format('embedded-opentype'),
      url(${quicksandRegularWoff2600}) format('woff2'), url(${quicksandRegularWoff600}) format('woff'),
      url(${quicksandRegularTtf600}) format('truetype'), url(${quicksandRegularSvg600}#Quicksand) format('svg');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url(${quicksandRegularEot700});
    src: local(''), url(${quicksandRegularEot700}?#iefix) format('embedded-opentype'),
      url(${quicksandRegularWoff2700}) format('woff2'), url(${quicksandRegularWoff700}) format('woff'),
      url(${quicksandRegularTtf700}) format('truetype'), url(${quicksandRegularSvg700}#Quicksand) format('svg');
  }
`;

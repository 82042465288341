import { _t } from 'utils/messages';
const scope = 'WriteTaskToolbar';
export const messages = {
  save: _t(`${scope}.save`),
  obtainFeedback: _t(`${scope}.obtainFeedback`),
  submit: _t(`${scope}.submit`),
  close: _t(`${scope}.close`),
  areYouSure: _t(`${scope}.areYouSure`),
  yes: _t(`${scope}.yes`),
  no: _t(`${scope}.no`),
  taskClosed: _t(`${scope}.taskClosed`),
  delete: _t(`${scope}.delete`),
  activateTask: _t(`${scope}.activateTask`),
  areYouSureToActivate: _t(`${scope}.areYouSureToActivate`),
  taskActivatedSuccessfully: _t(`${scope}.taskActivatedSuccessfully`),
  areYouSureToDelete: _t(`${scope}.areYouSureToDelete`),
  taskDeletedSuccesfuly: _t(`${scope}.taskDeletedSuccesfuly`),
};

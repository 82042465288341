import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import { HelmetProps, RouteRoleEnum, Routes } from '@skribi/shared/src/resources/routes/types';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  requiredRole?: RouteRoleEnum;
  helmetProps: HelmetProps;
}

export const ProtectedRoute = ({ requiredRole, helmetProps }: ProtectedRouteProps) => {
  const { t } = useTranslation();

  const { isAuthenticated, isStudent, isTeacher } = useUserAccountContext();

  if (!isAuthenticated) {
    return <Navigate to={Routes.Login} replace />;
  }

  if (
    (requiredRole === RouteRoleEnum.STUDENT && !isStudent()) ||
    (requiredRole === RouteRoleEnum.TEACHER && !isTeacher())
  ) {
    return <Navigate to={Routes.Home} replace />;
  }

  return (
    <>
      <Helmet>
        <title>{t(helmetProps.title)}</title>
        <meta name="description" content={t(helmetProps.description) ?? ''} />
      </Helmet>
      <Outlet />
    </>
  );
};

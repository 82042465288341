/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserStub } from './user-stub';
/**
 * 
 * @export
 * @interface TextAuthorStatusAggregate
 */
export interface TextAuthorStatusAggregate {
    /**
     * 
     * @type {UserStub}
     * @memberof TextAuthorStatusAggregate
     */
    author?: UserStub;
    /**
     * 
     * @type {string}
     * @memberof TextAuthorStatusAggregate
     */
    status?: TextAuthorStatusAggregateStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TextAuthorStatusAggregate
     */
    count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TextAuthorStatusAggregateStatusEnum {
    Open = 'open',
    Draft = 'draft',
    InDiscussion = 'in_discussion',
    InReview = 'in_review',
    Published = 'published'
}


/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileReferenceComplete } from "./file-reference-complete";
import { UserStubComplete } from "./user-stub-complete";
/**
 *
 * @export
 * @interface TaskComplete
 */
export interface TaskComplete {
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  classId: string;
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof TaskComplete
   */
  created?: Date;
  /**
   *
   * @type {Array<UserStubComplete>}
   * @memberof TaskComplete
   */
  assignees: Array<UserStubComplete>;
  /**
   *
   * @type {Date}
   * @memberof TaskComplete
   */
  due?: Date;
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  textCategory: TaskCompleteTextCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  goal?: TaskCompleteGoalEnum;
  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  status?: TaskCompleteStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof TaskComplete
   */
  publishResults?: boolean;
  /**
   *
   * @type {FileReferenceComplete}
   * @memberof TaskComplete
   */
  image?: FileReferenceComplete;

  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  authorAssistance?: string;

  /**
   *
   * @type {string}
   * @memberof TaskComplete
   */
  reviewerAssistance?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TaskCompleteTextCategoryEnum {
  Story = "story",
  Poem = "poem",
  Joke = "joke",
  Letter = "letter",
  Email = "email",
  Free = "free",
  NonFictional = "non_fictional",
}
/**
 * @export
 * @enum {string}
 */
export enum TaskCompleteGoalEnum {
  Storytelling = "storytelling",
  Reporting = "reporting",
  Describing = "describing",
  Argumentation = "argumentation",
  Other = "other",
}
/**
 * @export
 * @enum {string}
 */
export enum TaskCompleteStatusEnum {
  Draft = "draft",
  Active = "active",
  Done = "done",
}

import { Configuration, NotesApi, TasksApi, TextsApi } from '@skribi/openapi/src/text-api';
import {
  NoteBasic,
  NoteBasicTypeEnum,
  QueryResponseNoteBasic,
  QueryResponseTaskBasic,
  SubmissionCompleteTypeEnum,
  TaskBasic,
  TaskComplete,
  TextBasic,
  TextComplete,
} from '@skribi/openapi/src/text-api/models';
import {
  getResposeErrorTypeByStatusCode,
  ResponseCodesEnum,
  ResponseErrorsEnum,
} from '@skribi/shared/src/resources/error';
import { getAppConfigParam } from '@skribi/shared/src/utils/helpers';
import globalAxios, { AxiosResponse } from 'axios';
import { QueryTextNoEffectsResponse, queryTexts, setLoadedTextState, setTextListState } from 'stores/Text';
import { getAxiosRequestConfig } from 'utils/helpers';
import {
  NoteBasicFromJson,
  QueryResponseTaskBasicFromJson,
  TaskCompleteFromJson,
  TaskCompleteToJson,
  TaskPartialToJson,
  TextCompleteFromJson,
} from 'utils/parser';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { setNoteListState } from '../Note';
import {
  CreateTaskRequestParams,
  DeleteTaskImageProps,
  DeleteTaskRequestParams,
  GetTaskRequestParams,
  getTaskTextAndFeedbacksByTaskIdParams,
  QueryTaskRequestParams,
  QueryTaskWithNotesRequestParams,
  TaskActions,
  TaskState,
  UpdateTaskRequestParams,
} from './types';

const taskApiBaseconfig: Configuration = {
  basePath: getAppConfigParam('textApiUrl'),
};

globalAxios.interceptors.request.use(config => {
  if (config.data && typeof config.data === 'string') {
    config.data = config.data.replace('classId', 'class_id');
    config.data = config.data.replace('textCategory', 'text_category');
    config.data = config.data.replace('publishResults', 'publish_results');
  }
  return config;
});

const useTask = create<TaskState>()((set, get) => ({
  isRequestInProgress: false,
  error: null,
  loadedTask: null,
  taskList: [],
  taskListCount: 0,
  actions: {
    queryTask: async (requestParams: QueryTaskRequestParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<QueryResponseTaskBasic> = await new TasksApi(taskApiBaseconfig).queryTasks(
          requestParams._class,
          requestParams.status,
          requestParams.offset,
          requestParams.limit,
          await getAxiosRequestConfig(),
        );

        const parsedData: QueryResponseTaskBasic = QueryResponseTaskBasicFromJson(response.data);
        if (requestParams.updateCountList) {
          set({ isRequestInProgress: false, taskList: parsedData.results, taskListCount: parsedData.totalCount });
        } else {
          set({ isRequestInProgress: false, taskList: parsedData.results });
        }
      } catch (error: any) {
        console.error('queryTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    createTask: async (
      requestParams: CreateTaskRequestParams & { onEnd: (ok: TaskComplete | undefined) => void },
    ): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });

        const response: AxiosResponse<TaskComplete> = await new TasksApi(taskApiBaseconfig).createTask(
          TaskCompleteToJson(data.task),
          await getAxiosRequestConfig(),
        );

        set({ isRequestInProgress: false, loadedTask: TaskCompleteFromJson(response.data) });
        onEnd(TaskCompleteFromJson(response.data));
      } catch (error: any) {
        console.error('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(undefined);
      }
    },

    getTask: async (requestParams: GetTaskRequestParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<TaskComplete> = await new TasksApi(taskApiBaseconfig).getTask(
          requestParams.id,
          await getAxiosRequestConfig(),
        );

        set({ isRequestInProgress: false, loadedTask: TaskCompleteFromJson(response.data) });
      } catch (error: any) {
        console.error('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    updateTask: async (requestParams: UpdateTaskRequestParams & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });
        const response: AxiosResponse<TaskComplete> = await new TasksApi(taskApiBaseconfig).updateTask(
          data.id,
          data.body ? TaskPartialToJson(data.body) : undefined,
          await getAxiosRequestConfig(),
        );

        set({ isRequestInProgress: false, loadedTask: TaskCompleteFromJson(response.data) });
        onEnd(true);
      } catch (error: any) {
        console.error('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(false);
      }
    },

    clearLoadedTask: () => {
      set({ loadedTask: null });
    },

    deleteTask: async (requestParams: DeleteTaskRequestParams & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });

        await new TasksApi(taskApiBaseconfig).deleteTask(data.id, await getAxiosRequestConfig());

        set({ isRequestInProgress: false });
        onEnd(true);
      } catch (error: any) {
        console.error('deleteTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(false);
      }
    },

    queryTaskWithNotes: async (requestParams: QueryTaskWithNotesRequestParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<QueryResponseTaskBasic> = await new TasksApi(taskApiBaseconfig).queryTasks(
          requestParams._class,
          requestParams.status,
          requestParams.offset,
          requestParams.limit,
          await getAxiosRequestConfig(),
        );

        const parsedData: QueryResponseTaskBasic = QueryResponseTaskBasicFromJson(response.data);
        const tasksId: Array<string> = [];

        let filteredTasks: Array<TaskBasic> | undefined = parsedData.results;

        if (requestParams.filterIsAssignee) {
          filteredTasks = filteredTasks?.filter(task => task.assignees.find(el => el.id === requestParams.textAuthor));
        }

        set({ taskList: filteredTasks });

        if (parsedData.results) {
          parsedData.results.forEach(task => {
            if (task.id) {
              tasksId.push(task.id);
            }
          });

          const axiosConfig = await getAxiosRequestConfig();

          const response: QueryTextNoEffectsResponse | undefined = await queryTexts({
            _class: requestParams._class,
            author: [requestParams.textAuthor],
            submissionTask: tasksId,
          });

          if (response) {
            const textList: Array<TextBasic> = response.textList;

            const reviewerTextList: QueryTextNoEffectsResponse | undefined = await queryTexts({
              _class: requestParams._class,
              submissionReviewer: [requestParams.textAuthor],
            });

            if (reviewerTextList) {
              reviewerTextList.textList.forEach(text => {
                textList.push(text);
              });
            }

            if (textList.length > 0) {
              setTextListState(textList);

              const textListId: Array<string> = textList.map(text => text.id!);

              const notesResponse: AxiosResponse<QueryResponseNoteBasic> = await new NotesApi(
                taskApiBaseconfig,
              ).queryNotes(
                requestParams._class,
                undefined,
                undefined,
                undefined,
                textListId,
                undefined,
                undefined,
                undefined,
                undefined,
                axiosConfig,
              );

              const noteList: Array<NoteBasic> = [];
              if (notesResponse.data.results) {
                notesResponse.data.results.forEach(note => {
                  noteList.push(NoteBasicFromJson(note));
                });
              }

              setNoteListState(noteList);
            }
          }
        }
        set({ isRequestInProgress: false });
      } catch (error: any) {
        console.error('queryTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    getTaskTextAndFeedbacksByTaskId: async (requestParams: getTaskTextAndFeedbacksByTaskIdParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const axiosConfig = await getAxiosRequestConfig();

        const getTaskResponse: AxiosResponse<TaskComplete> = await new TasksApi(taskApiBaseconfig).getTask(
          requestParams.taskId,
          axiosConfig,
        );

        if (getTaskResponse.data) {
          const loadedTask: TaskComplete = TaskCompleteFromJson(getTaskResponse.data);

          set({ loadedTask: loadedTask });

          if (loadedTask) {
            const textListResponse: QueryTextNoEffectsResponse | undefined = await queryTexts({
              _class: [requestParams.classId],
              author: requestParams.userId ? [requestParams.userId] : undefined,
              submissionType: [SubmissionCompleteTypeEnum.Task],
              submissionTask: [loadedTask.id!],
              offset: 0,
              limit: 1,
            });

            if (textListResponse && textListResponse.textList.length > 0) {
              const loadTextResult: AxiosResponse<TextComplete> = await new TextsApi(taskApiBaseconfig).getText(
                textListResponse.textList[0].id!,
                axiosConfig,
              );

              if (loadTextResult.data) {
                const loadedText: TextComplete = TextCompleteFromJson(loadTextResult.data);
                setLoadedTextState(loadedText);

                if (loadedTask) {
                  const loadedTask: TaskComplete = TaskCompleteFromJson(getTaskResponse.data);
                  setLoadedTask(loadedTask);

                  const queryNotesResponse: AxiosResponse<QueryResponseNoteBasic> = await new NotesApi(
                    taskApiBaseconfig,
                  ).queryNotes(
                    [requestParams.classId],
                    !requestParams.isTeacher ? [NoteBasicTypeEnum.Feedback] : undefined,
                    undefined,
                    requestParams.userId && loadedText.author?.id !== requestParams.userId && !requestParams.isTeacher
                      ? [requestParams.userId]
                      : undefined,
                    [loadedText?.id!],
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    axiosConfig,
                  );

                  if (queryNotesResponse.data.results) {
                    setNoteListState(queryNotesResponse.data.results.map(note => NoteBasicFromJson(note)));
                  } else {
                    setNoteListState(null);
                  }
                }
              }
            }
          }
        }

        set({ isRequestInProgress: false });
      } catch (error: any) {
        console.error('queryTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    uploadTaskImage: async (requestParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const axiosConfig = await getAxiosRequestConfig();

        await new TasksApi(taskApiBaseconfig).uploadTaskImage(
          requestParams.body,
          requestParams.id,
          requestParams.contentType,
          axiosConfig,
        );
      } catch (error: any) {
        console.error('uploadTaskImage function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    deleteTaskImage: async (requestParams: DeleteTaskImageProps & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const axiosConfig = await getAxiosRequestConfig();

        await new TasksApi(taskApiBaseconfig).deleteTaskImage(requestParams.id, axiosConfig);

        requestParams.onEnd(true);
      } catch (error: any) {
        console.error('deleteTaskImage function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(false);
      }
    },
  },
}));

export const useTaskIsRequestInProgress = (): boolean => useTask(state => state.isRequestInProgress);

export const useTaskError = (): ResponseErrorsEnum | null => useTask(state => state.error);

export const useTaskLoadedTask = (): TaskComplete | null => useTask(state => state.loadedTask);

export const useTaskTasklist = (): Array<TaskBasic> => useTask(state => state.taskList, shallow);

export const useTaskTaskListCount = (): number | null => useTask(state => state.taskListCount);

export const useTaskActions = (): TaskActions => useTask(state => state.actions);

export const setLoadedTask = (params: TaskComplete) => {
  useTask.setState({ loadedTask: params });
};

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface SchoolClass
 */
export interface SchoolClass {
  /**
   *
   * @type {string}
   * @memberof SchoolClass
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolClass
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof SchoolClass
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof SchoolClass
   */
  updated?: Date;
  /**
   *
   * @type {string}
   * @memberof SchoolClass
   */
  status?: SchoolClassStatusEnum;
  /**
   *
   * @type {number}
   * @memberof SchoolClass
   */
  year?: number;
  /**
   *
   * @type {Set<string>}
   * @memberof SchoolClass
   */
  teacherIds?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof SchoolClass
   */
  teacherName?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolClass
   */
  access?: SchoolClassAccessEnum;
  /**
   *
   * @type {Set<string>}
   * @memberof SchoolClass
   */
  levels?: Set<SchoolClassLevelsEnum>;
  /**
   *
   * @type {Set<number>}
   * @memberof SchoolClass
   */
  grades?: Set<number>;
  /**
   *
   * @type {string}
   * @memberof SchoolClass
   */
  userId?: string;
}

/**
 * @export
 */
export const SchoolClassStatusEnum = {
  Active: 'active',
  Deleted: 'deleted',
  Revoked: 'revoked',
} as const;
export type SchoolClassStatusEnum = (typeof SchoolClassStatusEnum)[keyof typeof SchoolClassStatusEnum];

/**
 * @export
 */
export const SchoolClassAccessEnum = {
  Member: 'member',
  School: 'school',
} as const;
export type SchoolClassAccessEnum = (typeof SchoolClassAccessEnum)[keyof typeof SchoolClassAccessEnum];

/**
 * @export
 */
export const SchoolClassLevelsEnum = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const;
export type SchoolClassLevelsEnum = (typeof SchoolClassLevelsEnum)[keyof typeof SchoolClassLevelsEnum];

/**
 * Check if a given object implements the SchoolClass interface.
 */
export function instanceOfSchoolClass(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function SchoolClassFromJSON(json: any): SchoolClass {
  return SchoolClassFromJSONTyped(json, false);
}

export function SchoolClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolClass {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
    updated: !exists(json, 'updated') ? undefined : new Date(json['updated']),
    status: !exists(json, 'status') ? undefined : json['status'],
    year: !exists(json, 'year') ? undefined : json['year'],
    teacherIds: !exists(json, 'teacher_ids') ? undefined : json['teacher_ids'],
    teacherName: !exists(json, 'teacher_name') ? undefined : json['teacher_name'],
    access: !exists(json, 'access') ? undefined : json['access'],
    levels: !exists(json, 'levels') ? undefined : json['levels'],
    grades: !exists(json, 'grades') ? undefined : json['grades'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
  };
}

export function SchoolClassToJSON(value?: SchoolClass | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    created: value.created === undefined ? undefined : value.created.toISOString(),
    updated: value.updated === undefined ? undefined : value.updated.toISOString(),
    status: value.status,
    year: value.year,
    teacher_ids: value.teacherIds,
    teacher_name: value.teacherName,
    access: value.access,
    levels: value.levels,
    grades: value.grades,
    user_id: value.userId,
  };
}

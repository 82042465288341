/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from "../base";
import { ReportComplete } from "../models";
/**
 * TextReportsApi - axios parameter creator
 * @export
 */
export const TextReportsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the text * Referenced text must belong to a task submission with status in_discussion * Authenticated user must be a reviewer of the submission ## Postconditions * A report for the referenced text is created or updated.
     * @summary Adds a report to a text
     * @param {ReportComplete} body
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTextReport: async (
      body: ReportComplete,
      id: string,
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling addTextReport."
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling addTextReport."
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling addTextReport."
        );
      }
      const localVarPath = `/texts/{id}/reports/{user_id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "*/*";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the text ## Postconditions * All reports of the text are deleted.
     * @summary Deletes all reports of a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearTextReports: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling clearTextReports."
        );
      }
      const localVarPath = `/texts/{id}/reports`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be the owner of the report or must be allowed to manage the associated class ## Postconditions * The report for the referenced text is deleted.
     * @summary Removes a report from a text
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeNoteReport1: async (
      id: string,
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling removeNoteReport1."
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling removeNoteReport1."
        );
      }
      const localVarPath = `/texts/{id}/reports/{user_id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TextReportsApi - functional programming interface
 * @export
 */
export const TextReportsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the text * Referenced text must belong to a task submission with status in_discussion * Authenticated user must be a reviewer of the submission ## Postconditions * A report for the referenced text is created or updated.
     * @summary Adds a report to a text
     * @param {ReportComplete} body
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTextReport(
      body: ReportComplete,
      id: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ReportComplete>>
    > {
      const localVarAxiosArgs = await TextReportsApiAxiosParamCreator(
        configuration
      ).addTextReport(body, id, userId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the text ## Postconditions * All reports of the text are deleted.
     * @summary Deletes all reports of a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearTextReports(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await TextReportsApiAxiosParamCreator(
        configuration
      ).clearTextReports(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be the owner of the report or must be allowed to manage the associated class ## Postconditions * The report for the referenced text is deleted.
     * @summary Removes a report from a text
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeNoteReport1(
      id: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await TextReportsApiAxiosParamCreator(
        configuration
      ).removeNoteReport1(id, userId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TextReportsApi - factory interface
 * @export
 */
export const TextReportsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the text * Referenced text must belong to a task submission with status in_discussion * Authenticated user must be a reviewer of the submission ## Postconditions * A report for the referenced text is created or updated.
     * @summary Adds a report to a text
     * @param {ReportComplete} body
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTextReport(
      body: ReportComplete,
      id: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ReportComplete>> {
      return TextReportsApiFp(configuration)
        .addTextReport(body, id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the text ## Postconditions * All reports of the text are deleted.
     * @summary Deletes all reports of a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearTextReports(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return TextReportsApiFp(configuration)
        .clearTextReports(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be the owner of the report or must be allowed to manage the associated class ## Postconditions * The report for the referenced text is deleted.
     * @summary Removes a report from a text
     * @param {string} id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeNoteReport1(
      id: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return TextReportsApiFp(configuration)
        .removeNoteReport1(id, userId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TextReportsApi - object-oriented interface
 * @export
 * @class TextReportsApi
 * @extends {BaseAPI}
 */
export class TextReportsApi extends BaseAPI {
  /**
   * ## Preconditions * Authenticated user must be member of the class associated to the text * Referenced text must belong to a task submission with status in_discussion * Authenticated user must be a reviewer of the submission ## Postconditions * A report for the referenced text is created or updated.
   * @summary Adds a report to a text
   * @param {ReportComplete} body
   * @param {string} id
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextReportsApi
   */
  public async addTextReport(
    body: ReportComplete,
    id: string,
    userId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ReportComplete>> {
    return TextReportsApiFp(this.configuration)
      .addTextReport(body, id, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class associated to the text ## Postconditions * All reports of the text are deleted.
   * @summary Deletes all reports of a text
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextReportsApi
   */
  public async clearTextReports(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return TextReportsApiFp(this.configuration)
      .clearTextReports(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be the owner of the report or must be allowed to manage the associated class ## Postconditions * The report for the referenced text is deleted.
   * @summary Removes a report from a text
   * @param {string} id
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextReportsApi
   */
  public async removeNoteReport1(
    id: string,
    userId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return TextReportsApiFp(this.configuration)
      .removeNoteReport1(id, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

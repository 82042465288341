import { HelmetProps } from '@skribi/shared/src/resources/routes/types';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

interface OpenRouteProps {
  helmetProps: HelmetProps;
}

export const OpenRoute = ({ helmetProps }: OpenRouteProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t(helmetProps.title)}</title>
        <meta name="description" content={t(helmetProps.description) ?? ''} />
      </Helmet>
      <Outlet />
    </>
  );
};

import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { NavigationLogout } from 'components/NavigationLogout';
import { ToolbarLogo } from 'components/ToolbarLogo';
import { ToolbarNavigation } from 'components/ToolbarNavigation';
import { NavigationItem } from 'components/ToolbarNavigation/types';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from 'routes';
import { messages } from './messages';
import { NavigationPadding, Wrapper } from './style';

export function ClassOverview() {
  const { t } = useTranslation();
  const activeClass: SchoolClass | SchoolClassComplete | null = useSchoolClassActive();

  let toolbarItems: Array<NavigationItem> = [
    {
      itemLabel: t(messages.learningGroups),
      url: Routes.ClassOverview,
    },
  ];

  if (activeClass) {
    toolbarItems = [
      ...toolbarItems,
      {
        itemLabel: activeClass.name,
        url: LinkBuilder.TeacherClassDashboard(activeClass?.id!),
        activeUrlList: [Routes.ClassEditor.replace(':classId', activeClass.id!)],
      },
      {
        itemLabel: t(messages.statistics),
        url: Routes.ClassStatistics.replace(':classId', activeClass?.id!),
      },
      {
        itemLabel: t(messages.read),
        url: Routes.TextLibrary.replace(':classId', activeClass?.id!),
      },
      {
        itemLabel: t(messages.task),
        url: Routes.TeacherTasksOverview.replace(':classId', activeClass?.id!),
      },
      {
        itemLabel: t(messages.textControl),
        url: [Routes.TextControl.replace(':classId', activeClass?.id!), 'page=1'].join('?'),
      },
    ];
  }

  return (
    <Wrapper $alignment={AlignmentEnum.CENTER}>
      <ToolbarLogo />
      <NavigationPadding>
        <ToolbarNavigation navigationItems={toolbarItems} />
      </NavigationPadding>
      <NavigationLogout />
    </Wrapper>
  );
}

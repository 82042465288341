import { useSchoolClassActions, useSchoolClassError } from '@skribi/school-utils/src/store/SchoolClass';
import {
  GetNoteErrorMessage,
  GetSchoolClassErrorMessage,
  GetTextErrorMessage,
  ResponseErrorsEnum,
} from '@skribi/shared/src/resources/error';
import { ErrorAlert } from 'components/ErrorAlert';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNoteActions, useNoteError } from 'stores/Note';
import { useTextActions, useTextError } from 'stores/Text';
import { Wrapper } from './style';

export function ErrorWatcher() {
  const { t } = useTranslation();

  const schoolClassError: ResponseErrorsEnum | null = useSchoolClassError();
  const { clearError } = useSchoolClassActions();

  const textError: ResponseErrorsEnum | null = useTextError();
  const { clearTextError } = useTextActions();

  const noteError: ResponseErrorsEnum | null = useNoteError();
  const { clearNoteError } = useNoteActions();

  const handleClearSchoolClassError = useCallback(() => {
    clearError();
  }, [clearError]);

  const handleClearTextError = useCallback(() => {
    clearTextError();
  }, [clearTextError]);

  const handleClearNoteError = useCallback(() => {
    clearNoteError();
  }, [clearNoteError]);

  if (!schoolClassError && !textError && !noteError) {
    return null;
  }

  return (
    <Wrapper>
      {!!schoolClassError && (
        <ErrorAlert
          message={t(GetSchoolClassErrorMessage(schoolClassError))}
          clearErrorMessage={handleClearSchoolClassError}
        />
      )}

      {!!textError && (
        <ErrorAlert message={t(GetTextErrorMessage(textError))} clearErrorMessage={handleClearTextError} />
      )}

      {!!noteError && (
        <ErrorAlert message={t(GetNoteErrorMessage(noteError))} clearErrorMessage={handleClearNoteError} />
      )}
    </Wrapper>
  );
}

import { _t } from 'utils/messages';
const scope = 'TaskWithTextToolbar';
export const messages = {
  save: _t(`${scope}.save`),
  obtainFeedback: _t(`${scope}.obtainFeedback`),
  feedback: _t(`${scope}.feedback`),
  submit: _t(`${scope}.submit`),
  getFeedback: _t(`${scope}.getFeedback`),
  comments: _t(`${scope}.comments`),
  open: _t(`${scope}.open`),
  completed: _t(`${scope}.completed`),
  none: _t(`${scope}.none`),
  allowFeedback: _t(`${scope}.allowFeedback`),
  change: _t(`${scope}.change`),
  close: _t(`${scope}.close`),
  textIsSubmitted: _t(`${scope}.textIsSubmitted`),
  yes: _t(`${scope}.yes`),
  no: _t(`${scope}.no`),
  textUpdated: _t(`${scope}.textUpdated`),
  openText: _t(`${scope}.openText`),
  paragraph1: _t(`${scope}.paragraph1`),
  paragraph2: _t(`${scope}.paragraph2`),
  paragraph3: _t(`${scope}.paragraph3`),
  cancel: _t(`${scope}.cancel`),
  requestRelease: _t(`${scope}.requestRelease`),
  keep: _t(`${scope}.keep`),
  finish: _t(`${scope}.finish`),
  reviewAgain: _t(`${scope}.reviewAgain`),
  submittedAndReady: _t(`${scope}.submittedAndReady`),
  messageToAuthor: _t(`${scope}.messageToAuthor`),
  sendAndDone: _t(`${scope}.sendAndDone`),
  cancelSending: _t(`${scope}.cancelSending`),
  pleaseReviewAgain: _t(`${scope}.pleaseReviewAgain`),
  returnTask: _t(`${scope}.returnTask`),
};

import styled from 'styled-components';
import { FontTeaserTitle } from './typography';

export const ModalTitle = styled.p`
  ${FontTeaserTitle};
  text-align: center;
`;

export const ModalTitleLeft = styled.p`
  ${FontTeaserTitle};
  text-align: left;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${p => p.theme.toRem(15)};
  width: 100%;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${p => p.theme.toRem(15)};
`;

import { _t } from 'utils/messages';
const scope = 'TaskTemplateDetailContent';
export const messages = {
  textFunction: _t(`${scope}.metadata.textFunction`),
  suitableForGrades: _t(`${scope}.metadata.suitableForGrades`),
  textType: _t(`${scope}.metadata.textType`),
  textForWriter: _t(`${scope}.aditionalTexts.textForWriter`),
  textForReviewer: _t(`${scope}.aditionalTexts.textForReviewer`),
  aditionalInformation: _t(`${scope}.aditionalInformation.aditionalInformation`),
  linkForMaterial: _t(`${scope}.aditionalInformation.linkForMaterial`),
};

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SchoolBasic, SchoolComplete, SchoolPartial } from '../models';
import { SchoolBasicFromJSON, SchoolCompleteFromJSON, SchoolCompleteToJSON, SchoolPartialToJSON } from '../models';
import * as runtime from '../runtime';

export interface CreateSchoolRequest {
  schoolComplete?: SchoolComplete;
}

export interface GetSchoolRequest {
  schoolId: string;
}

export interface QuerySchoolsRequest {
  offset?: number;
  number?: string;
}

export interface UpdateSchoolRequest {
  schoolId: string;
  schoolPartial?: SchoolPartial;
}

/**
 *
 */
export class SchoolsApi extends runtime.BaseAPI {
  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school. ## Postconditions * A school is created
   * Creates a school
   */
  async createSchoolRaw(
    requestParameters: CreateSchoolRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/schools`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SchoolCompleteToJSON(requestParameters.schoolComplete),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school. ## Postconditions * A school is created
   * Creates a school
   */
  async createSchool(
    requestParameters: CreateSchoolRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createSchoolRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must be member of the school or must be allowed to manage any school.
   * Gets a school
   */
  async getSchoolRaw(
    requestParameters: GetSchoolRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SchoolComplete>> {
    if (requestParameters.schoolId === null || requestParameters.schoolId === undefined) {
      throw new runtime.RequiredError(
        'schoolId',
        'Required parameter requestParameters.schoolId was null or undefined when calling getSchool.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/schools/{schoolId}`.replace(`{${'schoolId'}}`, encodeURIComponent(String(requestParameters.schoolId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => SchoolCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be member of the school or must be allowed to manage any school.
   * Gets a school
   */
  async getSchool(
    requestParameters: GetSchoolRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolComplete> {
    const response = await this.getSchoolRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * In order to list all schools, user must be authenticated and must be allowed to manage any school.
   * Queries schools
   */
  async querySchoolsRaw(
    requestParameters: QuerySchoolsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<SchoolBasic>>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.number !== undefined) {
      queryParameters['number'] = requestParameters.number;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/schools`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SchoolBasicFromJSON));
  }

  /**
   * ## Preconditions * In order to list all schools, user must be authenticated and must be allowed to manage any school.
   * Queries schools
   */
  async querySchools(
    requestParameters: QuerySchoolsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<SchoolBasic>> {
    const response = await this.querySchoolsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school. ## Postconditions * The school is updated
   * Updates a school
   */
  async updateSchoolRaw(
    requestParameters: UpdateSchoolRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SchoolComplete>> {
    if (requestParameters.schoolId === null || requestParameters.schoolId === undefined) {
      throw new runtime.RequiredError(
        'schoolId',
        'Required parameter requestParameters.schoolId was null or undefined when calling updateSchool.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/schools/{schoolId}`.replace(`{${'schoolId'}}`, encodeURIComponent(String(requestParameters.schoolId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: SchoolPartialToJSON(requestParameters.schoolPartial),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => SchoolCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school. ## Postconditions * The school is updated
   * Updates a school
   */
  async updateSchool(
    requestParameters: UpdateSchoolRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolComplete> {
    const response = await this.updateSchoolRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

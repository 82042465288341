/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FileReferenceBasic } from './file-reference-basic';
import { UserStubBasic } from './user-stub-basic';
/**
 * 
 * @export
 * @interface TaskBasic
 */
export interface TaskBasic {
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    classId: string;
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof TaskBasic
     */
    created?: Date;
    /**
     * 
     * @type {Array<UserStubBasic>}
     * @memberof TaskBasic
     */
    assignees: Array<UserStubBasic>;
    /**
     * 
     * @type {Date}
     * @memberof TaskBasic
     */
    due?: Date;
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    textCategory: TaskBasicTextCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    goal?: TaskBasicGoalEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskBasic
     */
    status?: TaskBasicStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TaskBasic
     */
    publishResults?: boolean;
    /**
     * 
     * @type {FileReferenceBasic}
     * @memberof TaskBasic
     */
    image?: FileReferenceBasic;
}

/**
    * @export
    * @enum {string}
    */
export enum TaskBasicTextCategoryEnum {
    Story = 'story',
    Poem = 'poem',
    Joke = 'joke',
    Letter = 'letter',
    Email = 'email',
    Free = 'free',
    NonFictional = 'non_fictional'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskBasicGoalEnum {
    Storytelling = 'storytelling',
    Reporting = 'reporting',
    Describing = 'describing',
    Argumentation = 'argumentation',
    Other = 'other'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskBasicStatusEnum {
    Draft = 'draft',
    Active = 'active',
    Done = 'done'
}


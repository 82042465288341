import styled, { css } from 'styled-components';
import { FontLabel } from 'styles';
import { BaloonSize } from './types';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${p => p.theme.toRem(30)};
  align-items: center;
  position: relative;
  height: ${p => p.theme.toRem(22)};

  svg {
    position: absolute;
    bottom: -5px;
  }
`;

const BaloonTopBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  ${FontLabel};
`;

const BallonSizeBigCss = css`
  height: ${p => p.theme.toRem(31)};
  width: ${p => p.theme.toRem(38)};
`;

const BallonSizeSmallCss = css`
  height: ${p => p.theme.toRem(19)};
  width: 100%;
`;

type BaloonProps = {
  $size: BaloonSize;
};

export const BaloonTopBlue = styled(BaloonTopBase)<BaloonProps>`
  background: ${p => p.theme.color.primary_turquoise};
  color: ${p => p.theme.color.primary_black};

  ${p => (p.$size === 'big' ? BallonSizeBigCss : BallonSizeSmallCss)};
`;

export const BaloonTopBlack = styled(BaloonTopBase)<BaloonProps>`
  background: ${p => p.theme.color.primary_black};
  color: ${p => p.theme.color.primary_white};

  ${p => (p.$size === 'big' ? BallonSizeBigCss : BallonSizeSmallCss)};
`;

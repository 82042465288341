import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import { SchoolClassBasic } from '@skribi/school-utils/src/resources';
import {
  useSchoolClassActions,
  useSchoolClassIsRequestInProgress,
  useSchoolClassList,
} from '@skribi/school-utils/src/store/SchoolClass';
import { createUniqueSchoolClassName } from '@skribi/school-utils/src/utils/SchoolClassUtils';
import { Loader } from '@skribi/shared/src/components/Loader';
import { MandarinButton } from 'components/Button';
import { ClassCard } from 'components/ClassCard';
import { H1 } from 'components/Headings';
import { IconPlus } from 'icons';
import { AlignmentEnum, OuterWrapper } from 'layouts/OuterWrapper';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'routes';
import { messages } from './messages';
import { ClassItemsGrid, PageWrapper, Wrapper } from './style';

export function ClassOverviewContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getFirstSchoolId, getUserName, forceUpdateToken } = useUserAccountContext();

  const { createClass } = useSchoolClassActions();

  const classList: Array<SchoolClassBasic> | null = useSchoolClassList();

  const orderedClassList: Array<SchoolClassBasic> = useMemo(() => {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    return classList && classList.length > 0
      ? classList.filter(el => el.id).sort((a, b) => collator.compare(a.name, b.name))
      : [];
  }, [classList]);

  const isClassListLoading: boolean = useSchoolClassIsRequestInProgress();

  const handleCreateClassClick = useCallback(async () => {
    if (classList) {
      await createClass({
        schoolId: getFirstSchoolId(),
        schoolClassComplete: {
          name: createUniqueSchoolClassName(classList),
          teacherName: getUserName(),
        },
        onEnd: (schoolClassId: string) => {
          if (schoolClassId) {
            forceUpdateToken(() => {
              navigate(Routes.ClassEditor.replace(':classId', schoolClassId));
            });
          }
        },
      });
    }
  }, [getFirstSchoolId, classList, createClass, getUserName, navigate, forceUpdateToken]);

  if (isClassListLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <OuterWrapper $alignment={AlignmentEnum.CENTER}>
        <Wrapper>
          <H1>{t(messages.myLearningGroups)}</H1>
          <ClassItemsGrid>
            {orderedClassList.map(classInfo => (
              <ClassCard key={classInfo.id!} classInfo={classInfo} />
            ))}
          </ClassItemsGrid>

          <MandarinButton onClick={handleCreateClassClick}>
            <IconPlus />
            <span>{t(messages.newLearningGroup)}</span>
          </MandarinButton>
        </Wrapper>
      </OuterWrapper>
    </PageWrapper>
  );
}

import { ButtonBaseCss } from 'components/Button';
import { Link, Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FontBodyBold, FontButton, FontButtonBig, FontButtonSecondary } from 'styles';

export const MandarinLink = styled(RouterLink)`
  ${ButtonBaseCss};
  text-decoration: none;
  background: ${props => props.theme.color.primary_mandarin};
  padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};
`;

export const TurquoiseLink = styled(RouterLink)`
  ${ButtonBaseCss};
  text-decoration: none;
  background: ${props => props.theme.color.primary_turquoise};
  padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};
`;

export const BlackLink = styled(RouterLink)`
  ${ButtonBaseCss};
  text-decoration: none;
  background: ${props => props.theme.color.primary_black};
  padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};

  color: ${p => p.theme.color.primary_white};
`;

export const MandarinLinkSecondary = styled(MandarinLink)`
  ${FontButtonSecondary};
  line-height: normal;
`;

const LinkBigBaseStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${p => p.theme.toRem(7)};
  border-radius: 100px;
  text-decoration: none;

  padding: ${p => `${p.theme.toRem(20)} ${p.theme.toRem(40)}`};

  background: ${p => p.theme.color.primary_black};

  ${FontButtonBig};
  color: ${props => props.theme.color.primary_black};

  &:disabled {
    cursor: default;
  }
`;

interface CustomColorBigLinkProps {
  $color: string;
}

export const CustomColorBigLink = styled(RouterLink)<CustomColorBigLinkProps>`
  ${LinkBigBaseStyle};
  border: none;
  background: ${p => p.$color};
`;

export const TransparentLink = styled(Link)`
  background: transparent;
  border: none;
  text-decoration: none;
`;

export const TransparentContent = styled.span`
  color: transparent;
`;

export const WhiteLink = styled(Link)`
  ${ButtonBaseCss};

  background: ${p => p.theme.color.primary_white};
  color: ${p => p.theme.color.primary_black};
  padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};
`;

export const WhiteLinkBoldLabel = styled(Link)`
  ${ButtonBaseCss};

  background: ${p => p.theme.color.primary_white};
  color: ${p => p.theme.color.primary_black};
  padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};

  ${FontBodyBold};
`;

export const WhiteWithBlackBorderLink = styled(Link)`
  ${ButtonBaseCss};
  padding: ${p => `${p.theme.toRem(6)} ${p.theme.toRem(15)}`};
  border: ${p => `1px solid ${p.theme.color.primary_black}`};
  background: ${p => p.theme.color.primary_white};

  ${FontButton};
`;

export const BlackRoundedLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.theme.toRem(44)};
  height: ${p => p.theme.toRem(44)};
  border-radius: ${p => p.theme.toRem(100)};
  border: none;

  background: ${p => p.theme.color.primary_black};
`;

export const SecondaryButtonLink = styled(Link)`
  padding: ${p => `${p.theme.toRem(12)} ${p.theme.toRem(20)}`};
  text-decoration: none;
  background: ${p => p.theme.color.primary_black};
  border-radius: ${p => p.theme.toRem(100)};

  ${FontButton};
  color: ${p => p.theme.color.primary_white};
`;

import { SchoolClassBasic } from '../resources';

export const createUniqueSchoolClassName = (classes: Array<SchoolClassBasic>): string => {
  let newClassName: string;
  let index = classes.length + 1;
  let existingSchoolClass: SchoolClassBasic | undefined;
  // PREVENT THE CREATION OF A CLASS WITH A NAME THAT IS ALREADY USED
  do {
    newClassName = `Klasse ${index}`;
    existingSchoolClass = classes.find((schoolClass: SchoolClassBasic) => schoolClass.name === newClassName);
    index++;
  } while (existingSchoolClass);
  return newClassName;
};

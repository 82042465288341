/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from "../base";
import { TextRating } from "../models";
/**
 * RatingsApi - axios parameter creator
 * @export
 */
export const RatingsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * The user's rating for the text is deleted.
     * @summary Deletes a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTextRating: async (
      textId: string,
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'textId' is not null or undefined
      if (textId === null || textId === undefined) {
        throw new RequiredError(
          "textId",
          "Required parameter textId was null or undefined when calling deleteTextRating."
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling deleteTextRating."
        );
      }
      const localVarPath = `/texts/{text_id}/rating/{user_id}`
        .replace(`{${"text_id"}}`, encodeURIComponent(String(textId)))
        .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published.
     * @summary Gets a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTextRating: async (
      textId: string,
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'textId' is not null or undefined
      if (textId === null || textId === undefined) {
        throw new RequiredError(
          "textId",
          "Required parameter textId was null or undefined when calling getTextRating."
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling getTextRating."
        );
      }
      const localVarPath = `/texts/{text_id}/rating/{user_id}`
        .replace(`{${"text_id"}}`, encodeURIComponent(String(textId)))
        .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * A rating is added to the text. If the user rated the text before, the rating is updated.
     * @summary Updates a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {TextRating} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTextRating: async (
      textId: string,
      userId: string,
      body?: TextRating,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'textId' is not null or undefined
      if (textId === null || textId === undefined) {
        throw new RequiredError(
          "textId",
          "Required parameter textId was null or undefined when calling updateTextRating."
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          "userId",
          "Required parameter userId was null or undefined when calling updateTextRating."
        );
      }
      const localVarPath = `/texts/{text_id}/rating/{user_id}`
        .replace(`{${"text_id"}}`, encodeURIComponent(String(textId)))
        .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RatingsApi - functional programming interface
 * @export
 */
export const RatingsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * The user's rating for the text is deleted.
     * @summary Deletes a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTextRating(
      textId: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await RatingsApiAxiosParamCreator(
        configuration
      ).deleteTextRating(textId, userId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published.
     * @summary Gets a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTextRating(
      textId: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TextRating>>
    > {
      const localVarAxiosArgs = await RatingsApiAxiosParamCreator(
        configuration
      ).getTextRating(textId, userId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * A rating is added to the text. If the user rated the text before, the rating is updated.
     * @summary Updates a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {TextRating} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTextRating(
      textId: string,
      userId: string,
      body?: TextRating,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await RatingsApiAxiosParamCreator(
        configuration
      ).updateTextRating(textId, userId, body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RatingsApi - factory interface
 * @export
 */
export const RatingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * The user's rating for the text is deleted.
     * @summary Deletes a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTextRating(
      textId: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return RatingsApiFp(configuration)
        .deleteTextRating(textId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published.
     * @summary Gets a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTextRating(
      textId: string,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TextRating>> {
      return RatingsApiFp(configuration)
        .getTextRating(textId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * A rating is added to the text. If the user rated the text before, the rating is updated.
     * @summary Updates a text rating
     * @param {string} textId
     * @param {string} userId
     * @param {TextRating} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTextRating(
      textId: string,
      userId: string,
      body?: TextRating,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return RatingsApiFp(configuration)
        .updateTextRating(textId, userId, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RatingsApi - object-oriented interface
 * @export
 * @class RatingsApi
 * @extends {BaseAPI}
 */
export class RatingsApi extends BaseAPI {
  /**
   * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * The user's rating for the text is deleted.
   * @summary Deletes a text rating
   * @param {string} textId
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatingsApi
   */
  public async deleteTextRating(
    textId: string,
    userId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return RatingsApiFp(this.configuration)
      .deleteTextRating(textId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published.
   * @summary Gets a text rating
   * @param {string} textId
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatingsApi
   */
  public async getTextRating(
    textId: string,
    userId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TextRating>> {
    return RatingsApiFp(this.configuration)
      .getTextRating(textId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must match user_id and must be a member of the associated class of the text. * The text must be published. ## Postconditions * A rating is added to the text. If the user rated the text before, the rating is updated.
   * @summary Updates a text rating
   * @param {string} textId
   * @param {string} userId
   * @param {TextRating} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RatingsApi
   */
  public async updateTextRating(
    textId: string,
    userId: string,
    body?: TextRating,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return RatingsApiFp(this.configuration)
      .updateTextRating(textId, userId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

import { KeycloakService, UserAccountProvider } from '@skribi/keycloak-provider/src';
import { ScreenBlocker } from 'components/ScreenBlocker';
import dayjs from 'dayjs';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'sanitize.css/sanitize.css';
import { ThemeProvider } from 'styled-components';
import './locales/i18n';
import { Router } from './routes';
import { defaultTheme, GlobalStyle } from './styles';
require('dayjs/locale/de');
dayjs.locale('de');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const vh100Fix = (): void => {
  MOUNT_NODE.style.setProperty('--vh100', window.innerHeight + 'px');
  MOUNT_NODE.style.setProperty('--vw100', window.innerWidth + 'px');
};

window.addEventListener('resize', vh100Fix);
vh100Fix();

const renderApp = keycloak =>
  root.render(
    <HelmetProvider>
      <UserAccountProvider keycloak={keycloak}>
        <ThemeProvider theme={defaultTheme}>
          <ScreenBlocker />
          <Router />
          <GlobalStyle />
        </ThemeProvider>
      </UserAccountProvider>
    </HelmetProvider>,
  );

KeycloakService.initKeycloak(renderApp);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

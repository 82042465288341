/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { QueryResponseTextAuthorStatusAggregate } from '../models';
/**
 * TextStatisticsApi - axios parameter creator
 * @export
 */
export const TextStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ## Preconditions * The ID of the authenticated user must be equal to the `author` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each text 
         * @summary Aggregates text statistics grouped by author and status
         * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
         * @param {Array<string>} [author] Repeat to filter multiple user IDs.
         * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
         * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
         * @param {Array<string>} [category] Repeat to filter multiple text categories.
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTextsByAuthorAndStatus: async (_class?: Array<string>, author?: Array<string>, submissionType?: Array<string>, submissionTask?: Array<string>, category?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/text-statistics/by-author-and-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_class) {
                localVarQueryParameter['class'] = _class;
            }

            if (author) {
                localVarQueryParameter['author'] = author;
            }

            if (submissionType) {
                localVarQueryParameter['submission.type'] = submissionType;
            }

            if (submissionTask) {
                localVarQueryParameter['submission.task'] = submissionTask;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TextStatisticsApi - functional programming interface
 * @export
 */
export const TextStatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ## Preconditions * The ID of the authenticated user must be equal to the `author` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each text 
         * @summary Aggregates text statistics grouped by author and status
         * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
         * @param {Array<string>} [author] Repeat to filter multiple user IDs.
         * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
         * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
         * @param {Array<string>} [category] Repeat to filter multiple text categories.
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateTextsByAuthorAndStatus(_class?: Array<string>, author?: Array<string>, submissionType?: Array<string>, submissionTask?: Array<string>, category?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<QueryResponseTextAuthorStatusAggregate>>> {
            const localVarAxiosArgs = await TextStatisticsApiAxiosParamCreator(configuration).aggregateTextsByAuthorAndStatus(_class, author, submissionType, submissionTask, category, offset, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TextStatisticsApi - factory interface
 * @export
 */
export const TextStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ## Preconditions * The ID of the authenticated user must be equal to the `author` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each text 
         * @summary Aggregates text statistics grouped by author and status
         * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
         * @param {Array<string>} [author] Repeat to filter multiple user IDs.
         * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
         * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
         * @param {Array<string>} [category] Repeat to filter multiple text categories.
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateTextsByAuthorAndStatus(_class?: Array<string>, author?: Array<string>, submissionType?: Array<string>, submissionTask?: Array<string>, category?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<QueryResponseTextAuthorStatusAggregate>> {
            return TextStatisticsApiFp(configuration).aggregateTextsByAuthorAndStatus(_class, author, submissionType, submissionTask, category, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TextStatisticsApi - object-oriented interface
 * @export
 * @class TextStatisticsApi
 * @extends {BaseAPI}
 */
export class TextStatisticsApi extends BaseAPI {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the `author` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each text 
     * @summary Aggregates text statistics grouped by author and status
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [author] Repeat to filter multiple user IDs.
     * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
     * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
     * @param {Array<string>} [category] Repeat to filter multiple text categories.
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextStatisticsApi
     */
    public async aggregateTextsByAuthorAndStatus(_class?: Array<string>, author?: Array<string>, submissionType?: Array<string>, submissionTask?: Array<string>, category?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<QueryResponseTextAuthorStatusAggregate>> {
        return TextStatisticsApiFp(this.configuration).aggregateTextsByAuthorAndStatus(_class, author, submissionType, submissionTask, category, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}

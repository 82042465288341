import React, { InputHTMLAttributes, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { CustomValidationMessage, SmallPaddingTextArea, Wrapper } from './style';

interface FloatingHeightTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  rows?: number;
}

export const FloatingHeightTextArea = React.forwardRef((props: FloatingHeightTextAreaProps, ref) => {
  const { t } = useTranslation();

  const [showMessage, setShowMessage] = useState<boolean>(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const calculateTextAreaHeight = useCallback(() => {
    const currentInput = textAreaRef.current;

    if (currentInput) {
      if (currentInput.scrollHeight > currentInput.clientHeight) {
        currentInput.style.height = `${currentInput.scrollHeight}px`;
      }
    }
  }, []);

  useEffect(() => {
    const currentInput = textAreaRef.current;

    if (currentInput) {
      currentInput.addEventListener('keyup', calculateTextAreaHeight);
    }

    return () => {
      currentInput?.removeEventListener('keyup', calculateTextAreaHeight);
    };
  }, [calculateTextAreaHeight]);

  const getInputValue = useCallback(() => {
    return textAreaRef.current?.value;
  }, []);

  const clearInputText = useCallback(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.value = '';
    }
  }, []);

  const checkValidation = useCallback(e => {
    if (textAreaRef.current && textAreaRef.current.value === '') {
      e.preventDefault();
      e.stopPropagation();

      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, []);

  useImperativeHandle(ref, () => ({ getInputValue, clearInputText }));

  return (
    <Wrapper>
      <SmallPaddingTextArea ref={textAreaRef} {...props} onInvalid={e => checkValidation(e)} />

      {showMessage && <CustomValidationMessage>{t(messages.textRequired)}</CustomValidationMessage>}
    </Wrapper>
  );
});

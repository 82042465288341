/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface TextPartial
 */
export interface TextPartial {
    /**
     * 
     * @type {string}
     * @memberof TextPartial
     */
    category: TextPartialCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof TextPartial
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TextPartial
     */
    content: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TextPartialCategoryEnum {
    Story = 'story',
    Poem = 'poem',
    Joke = 'joke',
    Letter = 'letter',
    Email = 'email',
    Free = 'free',
    NonFictional = 'non_fictional'
}


import { DefaultTheme } from 'styled-components';

export const baseFontSize: Pick<DefaultTheme, 'baseFontSize'>['baseFontSize'] = 16;

const breakpoints_in_px: Pick<DefaultTheme, 'breakpoints'>['breakpoints'] = {
  tablet: 1024,
  desktop: 1224,
};

export const font: Pick<DefaultTheme, 'font'>['font'] = {
  family: 'Quicksand',
  style: {
    normal: 'normal',
    italic: 'italic',
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semi_bold: 600,
    bold: 700,
    black: 900,
  },
  toRem: (pxValue: number): string => `${pxValue / baseFontSize}rem`,
  toEm: (pxValue: number): string => `${pxValue / baseFontSize}em`,
};

export const color: Pick<DefaultTheme, 'color'>['color'] = {
  primary_turquoise: '#94e7ec',
  primary_turquoise_dark: '#4bc9d4',
  primary_mandarin: '#fda215',
  primary_dark_blue: '#001a5c',
  primary_dark_blue_10: 'rgba(0, 26, 92, 0.1)',
  primary_dark_blue_30: 'rgba(0, 26, 92, 0.3)',
  primary_yellow: '#ffee97',
  primary_mango: '#ffc657',
  primary_black: '#000',
  primary_white: '#fff',
  primary_green: '#316020',
  primary_red: '#f00',

  blue_opacity_80: 'rgba(107, 221, 246, 0.8)',
  red_error_alert_bg: '#f8d7da',
  red_error_alert_font: '#83223c',
  aditional_highlights_rose: '#ffdad0',
  dark_blue_2: '#66769d',
  cool_grey_2: '#5a6262',
  divomath_black: '#082e36',

  secondary_black_70: 'rgba(0, 0, 0, 0.7)',
  secondary_turquoise_middle: '#bff7fb',
  turquoise_5: '#bff7fb',
  secondary_papaya: '#ffb7b7',
  secondary_turquoise_light: '#e0f9fb',
  secondary_mango_light: '#ffd788',
  secondary_citrus: '#fff38a',
  secondary_sky: '#c9e5ff',
  secondary_petrol: '#007aa0',
  turquoise_1: '#007aa0',
  secondary_rose: '#ffe0d8',
  secondary_dark_blue_50: '#aeb5c8',
  secondary_dark_blue_30: '#e0e1e7',
  secondary_egg: '#ffeac2',
  secondary_mandarin_light: '#fdb544',
  secondary_mandarin_dark: '#e98d00',
  secondary_grey_light: '#f9f7f6',
  secondary_grey_blur: '#a3a7b0',
  secondary_subnav_disselect: '#c6b8af',
  secondary_subnav_disselect_20: '#efeae8',
  secondary_lilac: '#ddcdff',
  secondary_mud: '#d9d0cb',
  secondary_apple: '#c3ffa7',
  secondary_stone: '#d7e0e0',
  secondary_flip_screen: '#ffa944',
  warm_grey: '#eee9e7',
  dark_blue: '#e5e8ef',
  modal: {
    wrapperBackground: 'rgba(107, 221, 246, 0.8)',
    background: '#fff',
  },
};

export const defaultTheme: DefaultTheme = {
  baseFontSize,
  breakpoints: breakpoints_in_px,
  color,
  colors: color,
  font,
  toRem: (pxValue: number): string => `${pxValue / baseFontSize}rem`,
  toEm: (pxValue: number): string => `${pxValue / baseFontSize}em`,
};

import { create } from 'zustand';
import { getAppConfigParam } from '../../utils/helpers';
import { ConfigState } from './types';

export const getConfigParam = (paramName: string): any => {
  const useConfigStore = create<ConfigState>()((set, get) => ({
    schoolApiUrl: getAppConfigParam('schoolApiUrl'),
    tokenMinValidity: 15, // ACCESS TOKEN MINIMUN VALIDITY IN SECONDS,
    teacherRegisterUrl: getAppConfigParam('teacherRegisterUrl') ?? window.location.href,
  }));

  return useConfigStore.getState()[paramName];
};

export const useConfigSchoolApiUrl = (): string => getConfigParam('schoolApiUrl');
export const useConfigTokenMinValidity = (): number => getConfigParam('tokenMinValidity');
export const useConfigTeacherRegisterUrl = (): string => getConfigParam('teacherRegisterUrl');

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface TeacherProvision
 */
export interface TeacherProvision {
  /**
   *
   * @type {string}
   * @memberof TeacherProvision
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherProvision
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherProvision
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherProvision
   */
  lastName?: string;
}

/**
 * Check if a given object implements the TeacherProvision interface.
 */
export function instanceOfTeacherProvision(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TeacherProvisionFromJSON(json: any): TeacherProvision {
  return TeacherProvisionFromJSONTyped(json, false);
}

export function TeacherProvisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherProvision {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: !exists(json, 'username') ? undefined : json['username'],
    password: !exists(json, 'password') ? undefined : json['password'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
  };
}

export function TeacherProvisionToJSON(value?: TeacherProvision | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    password: value.password,
    first_name: value.firstName,
    last_name: value.lastName,
  };
}

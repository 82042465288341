import { SchoolBasic } from '@skribi/school-utils/src/resources';
import Cookies from 'universal-cookie';
import { create } from 'zustand';
import { COOKIE_NAME, UserObject } from '../../types';
import { AuthTokenState } from './types';
import { createUserObject, fetchAccessToken } from './utils';

export const useAuthToken = create<AuthTokenState>()(set => ({
  hasTokenLoginError: false,
  actions: {
    doTokenLogin: async (token: Array<string> | string, schoolData: SchoolBasic, redirectUri?: string) => {
      try {
        const user: UserObject | null = createUserObject(token, schoolData);
        const tokenData: any = await fetchAccessToken(user);
        const cookies: Cookies = new Cookies();

        if ('access_token' in tokenData && 'refresh_token' in tokenData) {
          cookies.remove(COOKIE_NAME);
          cookies.set(
            COOKIE_NAME,
            {
              accessToken: tokenData['access_token'],
              refreshToken: tokenData['refresh_token'],
            },
            { path: '/', sameSite: 'strict', secure: true },
          );
          window.location.replace(redirectUri ?? window.location.origin);
        }
      } catch (response: any) {
        set({ hasTokenLoginError: true });
        console.log('Fetch error: Token', response);
      }
    },
  },
}));

export const useAuthTokenHasError = () => useAuthToken(state => state.hasTokenLoginError);
export const useAuthTokenActions = () => useAuthToken(state => state.actions);

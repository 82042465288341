import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationLabel, NavigationLinkBig, NavigationLinkSmall, ToolbarNavigationWrapper } from './style';
import { NavigationItem } from './types';

interface ToolbarNavigationProps {
  navigationItems: Array<NavigationItem>;
}

export function ToolbarNavigation({ navigationItems }: ToolbarNavigationProps) {
  const { isTeacher } = useUserAccountContext();

  const { pathname } = useLocation();

  const NavLinkElement = isTeacher() ? NavigationLinkSmall : NavigationLinkBig;
  return (
    <ToolbarNavigationWrapper>
      {navigationItems.map(navItem => (
        <NavLinkElement
          className={navItem.activeUrlList ? (navItem.activeUrlList.includes(pathname) ? 'active' : '') : ''}
          key={navItem.itemLabel}
          to={navItem.url}
        >
          <NavigationLabel>{navItem.itemLabel}</NavigationLabel>
        </NavLinkElement>
      ))}
    </ToolbarNavigationWrapper>
  );
}

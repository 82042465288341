import styled from 'styled-components';
import { ReactComponent as Close } from './icons/close.svg';

interface ModalAlertProps {
  $isVisible: boolean;
}

export const ModalAlertWrapper = styled.div<ModalAlertProps>`
  position: absolute;
  top: 0;
  left: 0;
  background: ${p => p.theme.colors.modal.wrapperBackground};
  display: ${props => (props.$isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 3;
`;

interface ModalContentBlockProps {
  $noWidth?: boolean;
}

export const ModalContentBlock = styled.div<ModalContentBlockProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  background: ${p => p.theme.colors.modal.background};
  color: ${p => p.theme.colors.modal.textColor};
  width: ${p => (p.$noWidth ? 'fit-content' : p.theme.toRem(415))};
  padding: 20px 20px 60px 20px;
  row-gap: 22px;
  border-radius: 5px;
  box-shadow: 0 0px 20px 0 #0000004d;
`;

export const CloseModalBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const CloseModalButton = styled.button`
  border: none;
  background: transparent;
  width: fit-content;
`;

export const IconClose = styled(Close)`
  width: 24px;
  height: 24px;
`;

import {
  AggregateQueryResponseTextAuthorAggregateBasic,
  AuthorAggregateBasic,
  CommentBasic,
  CommentComplete,
  EMailComplete,
  FeedbackBasic,
  FeedbackComplete,
  FileReferenceComplete,
  FreeSubmissionComplete,
  NoteBasic,
  NoteBasicTypeEnum,
  NoteCompleteTypeEnum,
  QueryResponseNoteBasic,
  QueryResponseTaskBasic,
  ReportBasic,
  ReportComplete,
  SnippetBasic,
  SubmissionCompleteTypeEnum,
  TaskBasic,
  TaskComplete,
  TaskPartial,
  TaskSubmissionComplete,
  TextBasic,
  TextComplete,
  TextRating,
} from '@skribi/openapi/src/text-api';

export const ReportCompleteFromJson = (json: any): ReportComplete => {
  return {
    id: json['id'],
    name: json['name'],
    created: json['created'] ? new Date(json['created']) : undefined,
  };
};

export const TextCompleteFromJson = (json: any): TextComplete | EMailComplete => {
  return {
    id: json['id'],
    classId: json['class_id'],
    title: json['title'],
    created: new Date(json['created']),
    updated: new Date(json['updated']),
    author: json['author'],
    category: json['category'],
    content: json['content'],
    submission: SubmissionCompleteFromJson(json['submission']),
    access: json['access'],
    from: json['from'] ? json['from'] : undefined,
    subject: json['subject'] ? json['subject'] : undefined,
    to: json['to'] ? json['to'] : undefined,
    wordCount: json['word_count'] ? json['word_count'] : undefined,
    reports: json['reports'] ? json['reports'].map(json => ReportCompleteFromJson(json)) : [],
    noteReports: json['note_reports'],
    responses: json['responses'],
  };
};

export const TextBasicFromJson = (json: any): TextBasic => {
  return {
    category: json['category'],
    classId: json['class_id'],
    submission: {
      type: json['submission']['type'],
      status: json['submission']['status'],
      taskId: json['submission']['task_id'],
    },
    title: json['title'],
    access: json['access'],
    author: {
      id: json['author']['id'],
      name: json['author']['name'],
    },
    created: new Date(json['created']),
    updated: new Date(json['updated']),
    id: json['id'],
    wordCount: json['word_count'] ? json['word_count'] : undefined,
    reports: json['reports'] ? json['reports'].map(json => ReportCompleteFromJson(json) as ReportBasic) : [],
    noteReports: json['note_reports'],
  };
};

export const SubmissionCompleteFromJson = (json: any): TaskSubmissionComplete | FreeSubmissionComplete => {
  if (json['type'] === SubmissionCompleteTypeEnum.Free) {
    return {
      type: json['type'],
      status: json['status'],
    };
  } else {
    return {
      type: json['type'],
      status: json['status'],
      taskId: json['task_id'],
      reviewers: json['reviewers'],
    };
  }
};

export const AuthorAggregateBasicToJson = (json: any): AuthorAggregateBasic => {
  return {
    author: {
      id: json['author']['id'],
      name: json['author']['name'],
    },
    count: parseInt(json['count']),
  };
};

export const AggregateQueryResponseTextAuthorAggregateBasicFromJson = (
  json: any,
): AggregateQueryResponseTextAuthorAggregateBasic => {
  return {
    results: json['results'].map(el => TextBasicFromJson(el)),
    totalCount: json['total_count'],
    aggregates: json['aggregates'] ? json['aggregates'].map(json => AuthorAggregateBasicToJson(json)) : [],
  };
};

export const QueryResponseTaskBasicFromJson = (json: any): QueryResponseTaskBasic => {
  return {
    results: json['results'].map(task => TaskBasicFromJson(task)),
    totalCount: json['total_count'],
  };
};

export const FileReferenceCompleteFromJson = (json: any): FileReferenceComplete | undefined => {
  if (json) {
    return {
      expires: json['expires'] ? new Date(json['expires']) : undefined,
      url: json['url'] ? json['url'] : undefined,
    };
  }

  return undefined;
};

export const TaskBasicFromJson = (json: any): TaskBasic => {
  return {
    classId: json['class_id'],
    due: json['due'] ? new Date(json['due']) : undefined,
    textCategory: json['text_category'],
    title: json['title'],
    created: new Date(json['created']),
    description: json['description'],
    goal: json['goal'],
    id: json['id'],
    publishResults: json['publish_results'],
    status: json['status'],
    assignees: json['assignees'],
    image: json['image'] ? FileReferenceCompleteFromJson(json['image']) : undefined,
  };
};

export const TaskCompleteFromJson = (json: any): TaskComplete => {
  return {
    classId: json['class_id'],
    due: json['due'] ? new Date(json['due']) : undefined,
    textCategory: json['text_category'],
    title: json['title'],
    created: new Date(json['created']),
    description: json['description'],
    goal: json['goal'],
    id: json['id'],
    publishResults: json['publish_results'],
    status: json['status'],
    assignees: json['assignees'],
    image: json['image'] ? FileReferenceCompleteFromJson(json['image']) : undefined,
    authorAssistance: json['author_assistance'],
    reviewerAssistance: json['reviewer_assistance'],
  };
};

export const TaskCompleteToJson = (task: TaskComplete): any => {
  return {
    ...task,
    class_id: task.classId,
    text_category: task.textCategory,
    publish_results: task.publishResults,
    author_assistance: task.authorAssistance,
    reviewer_assistance: task.reviewerAssistance,
  };
};

export const TaskPartialToJson = (task: TaskPartial): any => {
  return {
    ...task,
    text_category: task.textCategory,
    publish_results: task.publishResults,
    author_assistance: task.authorAssistance,
    reviewer_assistance: task.reviewerAssistance,
  };
};

export const SnippetBasicFromJson = (json: any): SnippetBasic => {
  return {
    content: json['content'],
    offset: json['offset'],
  };
};

export const ReportBasicFromJson = (json: any): ReportBasic | ReportComplete => {
  return {
    id: json['id'],
    name: json['name'],
    created: new Date(json['created']),
  };
};

export const NoteBasicFromJson = (json: any): FeedbackBasic | CommentBasic => {
  const noteBasic: NoteBasic = {
    type: json['type'],
    id: json['id'],
    classId: json['class_id'],
    text: {
      id: json['text']['id'],
    },
    created: new Date(json['created']),
    user: json['user'],
    message: json['message'],
    status: json['status'],
    reports: json['reports'].map(json => ReportBasicFromJson(json)),
  };

  if (json['type'] === NoteBasicTypeEnum.Feedback) {
    return {
      ...noteBasic,
      snippet: json['snippet'] ? SnippetBasicFromJson(json['snippet']) : undefined,
    };
  } else {
    return noteBasic;
  }
};

export const QueryResponseNoteBasicFromJson = (json: any): QueryResponseNoteBasic => {
  return {
    results: json['results'],
    totalCount: json['total_count'],
  };
};

export const FeedbackCompleteFromJson = (json: any): FeedbackComplete => {
  return {
    classId: json['class_id'],
    message: json['message'],
    text: json['text'],
    type: json['type'],
    created: new Date(json['created']),
    id: json['id'],
    snippet: {
      content: json['snippet'] && json['snippet']['content'] ? json['snippet']['content'] : '',
      offset: json['snippet'] && json['snippet']['offset'] ? json['snippet']['offset'] : 0,
    },
    status: json['status'],
    user: {
      id: json['user']['id'],
      name: json['user']['name'],
      role: json['user']['role'],
    },
    reports: json['reports'] ? json['reports'].map(json => ReportBasicFromJson(json)) : [],
  };
};

export const CommentCompleteFromJson = (json: any): CommentComplete => {
  return {
    classId: json['class_id'],
    message: json['message'],
    text: json['text'],
    type: json['type'],
    created: new Date(json['created']),
    id: json['id'],
    status: json['status'],
    user: {
      id: json['user']['id'],
      name: json['user']['name'],
      role: json['user']['role'],
    },
    reports: json['reports'] ? json['reports'].map(json => ReportBasicFromJson(json)) : [],
  };
};

export const NoteCompleteFromJson = (json: any): FeedbackComplete | CommentComplete => {
  if (json['type'] === NoteCompleteTypeEnum.Feedback) {
    return FeedbackCompleteFromJson(json);
  } else {
    return CommentCompleteFromJson(json);
  }
};

export const TextRatingFromJson = (json: any): TextRating => {
  return {
    response: json['response'],
    classId: json['class_id'] ?? '',
    created: json['created'] ? new Date(json['created']) : undefined,
    id: json['id'],
    text: json['text'],
    user: json['user'],
  };
};

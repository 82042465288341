import { css } from 'styled-components';
import {} from './defaultTheme';

export const FontBody = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.toEm(16)};
  line-height: 1.31;
`;

export const FontBodyBold = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(16)};
  line-height: 1.31;
`;

export const FontBodyBoldUnderline = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(16)};
  text-decoration: underline;
  line-height: 1.31;
`;

export const FontLabel = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(14)};
  line-height: 1.28;
  letter-spacing: 0.28px;
`;

export const FontLabelUnderline = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(14)};
  line-height: 1.28;
  letter-spacing: 0.28px;
  text-decoration: underline;
`;

export const FontButton = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(16)};
  line-height: 1.25;
`;

export const FontButtonSecondary = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(14)};
  line-height: 1.428;
`;

export const FontButtonBig = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(28)};
  line-height: 0.714;
`;

export const FontNavigation = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(17)};
  line-height: normal;
`;

export const FontTeacherLink = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.semi_bold};
  font-size: ${props => props.theme.font.toEm(17)};
  line-height: normal;
  text-decoration: underline;
`;

export const FontH1 = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(32)};
  line-height: 1.125;
`;

export const FontH2 = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(24)};
  line-height: 1.166;
  letter-spacing: 0.48px;
`;

export const FontH3 = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.semi_bold};
  font-size: ${props => props.theme.font.toEm(21)};
  line-height: 1.333;
`;

export const FontSubnav = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.semi_bold};
  font-size: ${props => props.theme.font.toEm(15)};
  line-height: 0.652;
`;

export const FontLabelLight = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.toEm(14)};
  line-height: normal;
  letter-spacing: 0.14px;
`;

export const FontLabelBold = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(14)};
  line-height: normal;
  letter-spacing: 0.14px;
`;

export const FontTeaserTitle = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(21)};
  line-height: 1.238;
`;

export const FontText = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.toEm(18)};
  line-height: normal;
`;

export const FontTextBold = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(16)};
  line-height: normal;
`;

export const FontPagination = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(18)};
  line-height: normal;
`;

export const FontClassCard = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(42)};
  line-height: 1;
`;

export const FontParagraphTitle = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.toEm(18)};
  line-height: normal;
`;

export const FontCommentContent = css`
  font-style: ${props => props.theme.font.style.normal};
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.toEm(13)};
  line-height: normal;
`;

import { OuterWrapper } from 'layouts/OuterWrapper';
import styled from 'styled-components';

export const Wrapper = styled(OuterWrapper)`
  align-items: center;
  grid-template-columns: ${p => `auto ${p.theme.toRem(28)}`};
  background: ${p => p.theme.color.primary_turquoise};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: ${p => p.theme.toRem(12)};
  padding: ${p => `${p.theme.toRem(24)} 0 ${p.theme.toRem(24)} ${p.theme.toRem(50)}`};
`;

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FreeSubmissionComplete } from "./free-submission-complete";
import { ReportBasic } from "./report-basic";
import { SubmissionBasic } from "./submission-basic";
import { TaskSubmissionComplete } from "./task-submission-complete";
import { UserStubBasic } from "./user-stub-basic";
/**
 *
 * @export
 * @interface TextBasic
 */
export interface TextBasic {
  /**
   *
   * @type {string}
   * @memberof TextBasic
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TextBasic
   */
  category: TextBasicCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof TextBasic
   */
  classId: string;
  /**
   *
   * @type {string}
   * @memberof TextBasic
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof TextBasic
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof TextBasic
   */
  updated?: Date;
  /**
   *
   * @type {UserStubBasic}
   * @memberof TextBasic
   */
  author?: UserStubBasic;
  /**
   *
   * @type {number}
   * @memberof TextBasic
   */
  wordCount?: number;
  /**
   *
   * @type {SubmissionBasic}
   * @memberof TextBasic
   */
  submission: FreeSubmissionComplete | TaskSubmissionComplete;
  /**
   *
   * @type {Array<ReportBasic>}
   * @memberof TextBasic
   */
  reports?: Array<ReportBasic>;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof TextBasic
   */
  noteReports?: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof TextBasic
   */
  access?: TextBasicAccessEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TextBasicCategoryEnum {
  Story = "story",
  Poem = "poem",
  Joke = "joke",
  Letter = "letter",
  Email = "email",
  Free = "free",
  NonFictional = "non_fictional",
}
/**
 * @export
 * @enum {string}
 */
export enum TextBasicAccessEnum {
  Author = "author",
  Reviewer = "reviewer",
  Class = "class",
}

import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import { Routes } from '@skribi/shared/src/resources/routes/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { LinkBody, NameWrapper, StudentLogoutButton, StudentNameSpan, TeacherLogoutButton, Wrapper } from './style';

export function NavigationLogout() {
  const { t } = useTranslation();
  const { logout, isTeacher, getUserName, isStudent } = useUserAccountContext();
  const ButtonElement = isTeacher() ? TeacherLogoutButton : StudentLogoutButton;

  const handleLogoutClick = () => {
    logout();
  };
  const studentName = getUserName();
  const formattedName: string = studentName.length > 12 ? `${studentName.slice(0, 12)}...` : studentName;

  return isStudent() ? (
    <Wrapper>
      <NameWrapper>
        <StudentNameSpan>{formattedName}</StudentNameSpan>
        <LinkBody to={Routes.MyWorld}>{t(messages.myWorld)}</LinkBody>
      </NameWrapper>

      <ButtonElement type="button" onClick={handleLogoutClick}>
        {t(messages.logout)}
      </ButtonElement>
    </Wrapper>
  ) : (
    <ButtonElement type="button" onClick={handleLogoutClick}>
      {t(messages.logout)}
    </ButtonElement>
  );
}

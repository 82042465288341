import { SchoolBasic } from '@skribi/school-utils/src/resources';
import { KeycloakService, keycloakInitOptions } from '../../keycloak/KeycloakService';
import md5Hex from 'md5-hex';
import { COOKIE_NAME, UserObject } from '../../types';
import Keycloak from 'keycloak-js';
import { request } from '../../../src/request';
import Cookies from 'universal-cookie';

/**
 * Extract and concat username and password.
 */
export const createUserObject = (token: Array<string> | string, school: SchoolBasic | null): UserObject | null => {
  if (typeof token === 'string' && token.length === 12) {
    token = token.match(/.{4}/g) ?? [];
  }
  if (school && token.length === 3) {
    return {
      username: md5Hex(`${school.id}${token[0].toUpperCase()}`),
      password: `${token[1].toUpperCase()}${token[2].toUpperCase()}`,
    };
  }
  return null;
};

export const fetchAccessToken = async (user: UserObject | null) => {
  const keycloak: Keycloak = KeycloakService.getKeycloak();
  const requestBody: URLSearchParams = new URLSearchParams();
  if (user && keycloak.clientId) {
    requestBody.append('client_id', keycloak.clientId);
    requestBody.append('grant_type', 'password');
    requestBody.append('username', user.username);
    requestBody.append('password', user.password);
  }
  const requestUri: string = `${keycloak.authServerUrl}realms/${keycloak.realm}/protocol/openid-connect/token`.replace(
    /\/+$/,
    '',
  );
  const requestInit: RequestInit = {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    body: requestBody,
  };

  return await request(requestUri, requestInit);
};

export const reinitializeKeycloak = (tokenData: any, redirectUri?: string) => {
  const cookies: Cookies = new Cookies();
  const keycloak: Keycloak = KeycloakService.getKeycloak();
  keycloak
    .init({
      ...keycloakInitOptions,
      token: tokenData.access_token,
      refreshToken: tokenData.refresh_token,
    })
    .then(authenticated => {
      if (authenticated) {
        cookies.remove(COOKIE_NAME);
        cookies.set(
          COOKIE_NAME,
          {
            accessToken: keycloak.token,
            refreshToken: keycloak.refreshToken,
          },
          { path: '/' },
        );
        window.location.href = redirectUri ?? window.location.origin;
      }
    });
};

import { create } from 'zustand';
import { ToastMessageActions, ToastMessageState, ToastMessageType, updateToastParamsRequest } from './types';

const useToastMessage = create<ToastMessageState>()((set, get) => ({
  message: null,
  type: null,
  showToast: false,
  actions: {
    setToastMessageParams: (requestParams: updateToastParamsRequest): void => {
      set(state => ({
        message: requestParams.message ?? state.message,
        showToast: requestParams.showToast ?? state.showToast,
        type: requestParams.type ?? state.type,
      }));
    },
  },
}));

export const useToastMessageActions = (): ToastMessageActions => useToastMessage(state => state.actions);
export const useToastMessageMessage = (): string | null => useToastMessage(state => state.message);
export const useToastMessageType = (): ToastMessageType | null => useToastMessage(state => state.type);
export const useToastMessageShowToast = (): boolean => useToastMessage(state => state.showToast);

import { NavigationLogout } from 'components/NavigationLogout';
import { ToolbarLogo } from 'components/ToolbarLogo';
import { ToolbarNavigation } from 'components/ToolbarNavigation';
import { NavigationItem } from 'components/ToolbarNavigation/types';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from 'routes';
import { messages } from './messages';
import { Wrapper } from './style';

export function Main() {
  const { t } = useTranslation();
  const toolbarItems: Array<NavigationItem> = [
    {
      itemLabel: t(messages.mySkribi),
      url: Routes.Home,
    },
    {
      itemLabel: t(messages.myTexts),
      url: Routes.MyTexts,
    },
    {
      itemLabel: t(messages.library),
      url: Routes.StudentLibrary,
    },
    {
      itemLabel: t(messages.tasks),
      url: Routes.MyTasks,
    },
  ];

  return (
    <Wrapper $alignment={AlignmentEnum.CENTER}>
      <ToolbarLogo />
      <ToolbarNavigation navigationItems={toolbarItems} />
      <NavigationLogout />
    </Wrapper>
  );
}

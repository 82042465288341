import React from 'react';
import { StyledImage, Wrapper } from './style';

interface ImageProps {
  height?: number;
  width?: number;
  src: string;
  alt: string;
}

export function Image({ height, width, src, alt }: ImageProps) {
  return (
    <Wrapper $height={height} $width={width}>
      <StyledImage src={src} alt={alt} />
    </Wrapper>
  );
}

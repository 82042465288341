/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { createQueryString } from "@skribi/shared/src/utils/helpers";
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from "../base";
import {
  FileReferenceComplete,
  QueryResponseTaskBasic,
  TaskComplete,
  TaskPartial,
} from "../models";
/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * A task is created.
     * @summary Creates a task
     * @param {TaskComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTask: async (
      body: TaskComplete,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createTask."
        );
      }
      const localVarPath = `/tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The specified task is deleted.
     * @summary Deletes a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTask: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteTask."
        );
      }
      const localVarPath = `/tasks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The image attached to the task is deleted.
     * @summary Deletes the image file attached to a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTaskImage: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteTaskImage."
        );
      }
      const localVarPath = `/tasks/{id}/image`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the task
     * @summary Gets the complete representation of a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTask: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getTask."
        );
      }
      const localVarPath = `/tasks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each task
     * @summary Queries basic representations of tasks
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryTasks: async (
      _class?: Array<string>,
      status?: Array<string>,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (_class) {
        localVarQueryParameter["class"] = _class;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      const query = createQueryString(
        localVarQueryParameter,
        new URLSearchParams(localVarUrlObj.search)
      );

      for (const key in options.params) {
        query.set(key, options.params[key]);
      }

      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The status or other properties of a task are updated. * If status was updated to active, texts for assigned students are created.
     * @summary Updates status or other properties of a task
     * @param {string} id
     * @param {TaskPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTask: async (
      id: string,
      body?: TaskPartial,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateTask."
        );
      }
      const localVarPath = `/tasks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "*/*";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The uploaded file is attached to the task. * Any image previously attached to the task is deleted.
     * @summary Uploads an image file for a task
     * @param {any} body
     * @param {string} id
     * @param {string} [contentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadTaskImage: async (
      body: any,
      id: string,
      contentType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling uploadTaskImage."
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling uploadTaskImage."
        );
      }
      const localVarPath = `/tasks/{id}/image`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = { ...configuration.baseOptions };
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contentType !== undefined && contentType !== null) {
        localVarHeaderParameter["Content-Type"] = String(contentType);
      } else {
        localVarHeaderParameter["Content-Type"] = "image/png";
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();

      localVarRequestOptions.data = body;

      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      if (localVarRequestOptions.headers) {
        localVarRequestOptions.headers["Content-Type"] = String(contentType);
      }

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function (configuration?: Configuration) {
  return {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * A task is created.
     * @summary Creates a task
     * @param {TaskComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTask(
      body: TaskComplete,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TaskComplete>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).createTask(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The specified task is deleted.
     * @summary Deletes a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTask(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).deleteTask(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The image attached to the task is deleted.
     * @summary Deletes the image file attached to a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTaskImage(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).deleteTaskImage(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the task
     * @summary Gets the complete representation of a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTask(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TaskComplete>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).getTask(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each task
     * @summary Queries basic representations of tasks
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryTasks(
      _class?: Array<string>,
      status?: Array<string>,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<QueryResponseTaskBasic>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).queryTasks(_class, status, offset, limit, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The status or other properties of a task are updated. * If status was updated to active, texts for assigned students are created.
     * @summary Updates status or other properties of a task
     * @param {string} id
     * @param {TaskPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTask(
      id: string,
      body?: TaskPartial,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TaskComplete>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).updateTask(id, body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The uploaded file is attached to the task. * Any image previously attached to the task is deleted.
     * @summary Uploads an image file for a task
     * @param {any} body
     * @param {string} id
     * @param {string} [contentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadTaskImage(
      body: any,
      id: string,
      contentType?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<FileReferenceComplete>>
    > {
      const localVarAxiosArgs = await TasksApiAxiosParamCreator(
        configuration
      ).uploadTaskImage(body, id, contentType, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * A task is created.
     * @summary Creates a task
     * @param {TaskComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTask(
      body: TaskComplete,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TaskComplete>> {
      return TasksApiFp(configuration)
        .createTask(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The specified task is deleted.
     * @summary Deletes a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTask(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return TasksApiFp(configuration)
        .deleteTask(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The image attached to the task is deleted.
     * @summary Deletes the image file attached to a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTaskImage(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return TasksApiFp(configuration)
        .deleteTaskImage(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the task
     * @summary Gets the complete representation of a task
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTask(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TaskComplete>> {
      return TasksApiFp(configuration)
        .getTask(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each task
     * @summary Queries basic representations of tasks
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryTasks(
      _class?: Array<string>,
      status?: Array<string>,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<QueryResponseTaskBasic>> {
      return TasksApiFp(configuration)
        .queryTasks(_class, status, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The status or other properties of a task are updated. * If status was updated to active, texts for assigned students are created.
     * @summary Updates status or other properties of a task
     * @param {string} id
     * @param {TaskPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTask(
      id: string,
      body?: TaskPartial,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TaskComplete>> {
      return TasksApiFp(configuration)
        .updateTask(id, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The uploaded file is attached to the task. * Any image previously attached to the task is deleted.
     * @summary Uploads an image file for a task
     * @param {any} body
     * @param {string} id
     * @param {string} [contentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadTaskImage(
      body: any,
      id: string,
      contentType?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<FileReferenceComplete>> {
      return TasksApiFp(configuration)
        .uploadTaskImage(body, id, contentType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
  /**
   * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * A task is created.
   * @summary Creates a task
   * @param {TaskComplete} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async createTask(
    body: TaskComplete,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TaskComplete>> {
    return TasksApiFp(this.configuration)
      .createTask(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The specified task is deleted.
   * @summary Deletes a task
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async deleteTask(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return TasksApiFp(this.configuration)
      .deleteTask(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The image attached to the task is deleted.
   * @summary Deletes the image file attached to a task
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async deleteTaskImage(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return TasksApiFp(this.configuration)
      .deleteTaskImage(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be member of the class associated to the task
   * @summary Gets the complete representation of a task
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async getTask(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TaskComplete>> {
    return TasksApiFp(this.configuration)
      .getTask(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be member of the classes associated to each task
   * @summary Queries basic representations of tasks
   * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
   * @param {Array<string>} [status] Repeat to filter multiple status values.
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async queryTasks(
    _class?: Array<string>,
    status?: Array<string>,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<QueryResponseTaskBasic>> {
    return TasksApiFp(this.configuration)
      .queryTasks(_class, status, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The status or other properties of a task are updated. * If status was updated to active, texts for assigned students are created.
   * @summary Updates status or other properties of a task
   * @param {string} id
   * @param {TaskPartial} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async updateTask(
    id: string,
    body?: TaskPartial,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TaskComplete>> {
    return TasksApiFp(this.configuration)
      .updateTask(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class associated to the task ## Postconditions * The uploaded file is attached to the task. * Any image previously attached to the task is deleted.
   * @summary Uploads an image file for a task
   * @param {any} body
   * @param {string} id
   * @param {string} [contentType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public async uploadTaskImage(
    body: any,
    id: string,
    contentType?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<FileReferenceComplete>> {
    return TasksApiFp(this.configuration)
      .uploadTaskImage(body, id, contentType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { StudentCredentialBasic } from './StudentCredentialBasic';
import { StudentCredentialBasicFromJSON, StudentCredentialBasicToJSON } from './StudentCredentialBasic';

/**
 *
 * @export
 * @interface StudentBasic
 */
export interface StudentBasic {
  /**
   *
   * @type {string}
   * @memberof StudentBasic
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StudentBasic
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof StudentBasic
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof StudentBasic
   */
  schoolId?: string;
  /**
   *
   * @type {StudentCredentialBasic}
   * @memberof StudentBasic
   */
  credential?: StudentCredentialBasic;
  /**
   *
   * @type {{ [key: string]: string | undefined; }}
   * @memberof StudentBasic
   */
  profile?: { [key: string]: string | undefined };
}

/**
 * Check if a given object implements the StudentBasic interface.
 */
export function instanceOfStudentBasic(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function StudentBasicFromJSON(json: any): StudentBasic {
  return StudentBasicFromJSONTyped(json, false);
}

export function StudentBasicFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentBasic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    schoolId: !exists(json, 'school_id') ? undefined : json['school_id'],
    credential: !exists(json, 'credential') ? undefined : StudentCredentialBasicFromJSON(json['credential']),
    profile: !exists(json, 'profile') ? undefined : json['profile'],
  };
}

export function StudentBasicToJSON(value?: StudentBasic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    first_name: value.firstName,
    last_name: value.lastName,
    school_id: value.schoolId,
    credential: StudentCredentialBasicToJSON(value.credential),
    profile: value.profile,
  };
}

import { TOOLBAR_HEIGHT } from 'components/ContentWrapper/types';
import { OuterWrapper } from 'layouts/OuterWrapper';
import styled from 'styled-components';
import { FontBodyBold, FontButton, FontLabelLight, FontNavigation } from 'styles';

export const Wrapper = styled(OuterWrapper)`
  background: ${p => p.theme.color.primary_turquoise};
  padding: ${p => `0 ${p.theme.toRem(30)} 0 ${p.theme.toRem(80)}`};
  grid-template-columns: auto 30% 4%;
  align-items: center;
  height: ${p => p.theme.toRem(TOOLBAR_HEIGHT)};
  width: 100%;
  column-gap: ${p => p.theme.toRem(66)};
`;

export const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: ${p => p.theme.toRem(12)};
  padding: ${p => `${p.theme.toRem(13)} 0`};
`;

export const ToolbarItemsList = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface ToolbarButtonProps {
  $isActive?: boolean;
}

export const ToolbarItemsListItem = styled.li<ToolbarButtonProps>`
  display: flex;
  column-gap: ${p => p.theme.toRem(7)};
  align-items: center;
  height: 100%;
  background: ${p => (p.$isActive ? p.theme.color.primary_turquoise_dark : p.theme.color.primary_turquoise)};
  padding: ${p => `0 ${p.theme.toRem(15)}`};
  border: none;
  position: relative;

  span {
    ${FontButton};
  }
`;

export const LitItemIconWrapper = styled.button`
  display: flex;
  align-items: center;
  height: ${p => p.theme.toRem(22)};
  column-gap: ${p => p.theme.toRem(7)};
  background: transparent;
  border: none;

  ${FontNavigation};

  svg {
    bottom: -2px;
  }
`;

export const FeedbackPopWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1;
  width: ${p => p.theme.toRem(282)};
`;

export const CommentPopWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1;
  width: ${p => p.theme.toRem(166)};
`;

export const MangoWrapper = styled.div`
  display: flex;
  row-gap: ${p => p.theme.toRem(10)};
  flex-direction: column;
  padding: ${p => `${p.theme.toRem(20)} ${p.theme.toRem(20)} ${p.theme.toRem(30)}`};
  background: ${p => p.theme.color.secondary_mango_light};
  width: 100%;
`;

export const LabelLightContent = styled.p`
  text-align: left;
  span {
    ${FontLabelLight};
  }
`;

export const CommentTypeGrid = styled.div`
  display: grid;
  grid-template-rows: ${p => `repeat(3, ${p.theme.toRem(20)})`};
  row-gap: ${p => p.theme.toRem(7)};
`;

export const CommentTypeRow = styled.div`
  display: grid;
  grid-template-columns: ${p => `${p.theme.toRem(20)} auto`};
  column-gap: ${p => p.theme.toRem(7)};
`;

export const CommentTypeLabel = styled.label`
  ${FontLabelLight};
`;

export const TextSubmittedMessage = styled.p`
  ${FontBodyBold};
  color: ${p => p.theme.color.primary_black};
  text-align: left;
  width: 100%;
`;

export const CloseLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ModalContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: ${p => p.theme.toRem(15)};
  text-align: left;
  padding: ${p => `0 ${p.theme.toRem(40)}`};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${p => p.theme.toRem(10)};
  width: ${p => p.theme.toRem(392)};
`;

export const ModalTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${p => p.theme.toRem(392)};
  text-align: left;
`;

export const FinishTextModalWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  width: 100%;
  text-align: left;
`;

export const TextAreaWrapper = styled.div`
  display: grid;
  grid-template-rows: ${p => `auto ${p.theme.toRem(150)}`};
  row-gap: ${p => p.theme.toRem(10)};
  width: 100%;
  padding: ${p => `${p.theme.toRem(24)} 0 ${p.theme.toRem(40)} 0`};
`;

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import deErrors from './de/errors.json';
import de from './de/translation.json';
import { convertLanguageJsonToObject } from './translations';

export const DEFAULT_LANGUAGE = 'de';
export const SUPPORTED_LANGUAGES: string[] = [DEFAULT_LANGUAGE];

const dateTranslation = { formattedIntlDate: '{{ date, intlDate }}' };

export const translationsJson = {
  de: {
    translation: {
      ...de,
      ...dateTranslation,
    },
    errors: deErrors,
  },
};

convertLanguageJsonToObject(de);

// https://github.com/i18next/react-i18next/issues/1587

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const i18n = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    returnNull: false,
    lng: DEFAULT_LANGUAGE, // overrides language detection
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    load: 'languageOnly',
    resources: translationsJson,
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'intlDate') {
          return new Intl.DateTimeFormat(lng, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(value);
        }
        return value;
      },
    },
  });

import { create } from 'zustand';
import { EditorHelperActions, EditorHelperState } from './types';

const useEditorHelper = create<EditorHelperState>()((set, get) => ({
  hasSelectedContent: false,
  commentColor: null,
  text: '',
  highlightedCommentId: null,
  isEditorLoaded: false,
  submitForm: false,
  hasContentChanged: false,
  actions: {
    setHasSelectedContent: (requestParams: boolean) => {
      set({ hasSelectedContent: requestParams });
    },

    setCommentColor: (requestParams: string | null) => {
      set({ commentColor: requestParams });
    },

    setText(requestParams: string) {
      set({ text: requestParams });
    },

    setHighlightedCommentId(requestParam: string | null) {
      const currentHighlightedCommentId = get().highlightedCommentId;
      set({ highlightedCommentId: requestParam === currentHighlightedCommentId ? null : requestParam });
    },

    setIsEditorLoaded(requestParam: boolean) {
      set({ isEditorLoaded: requestParam });
    },

    setSubmitForm(requestParam: boolean) {
      set({ submitForm: requestParam });
    },

    setHasContentChanged(requestParam: boolean) {
      set({ hasContentChanged: requestParam });
    },
  },
}));

export const useEditorHelperSelectedContent = (): boolean => useEditorHelper(state => state.hasSelectedContent);

export const useEditorHelperCommentColor = (): string | null => useEditorHelper(state => state.commentColor);

export const useEditorHelperActions = (): EditorHelperActions => useEditorHelper(state => state.actions);

export const useEditorHelperText = (): string => useEditorHelper(state => state.text);

export const useEditorHelperEditorIsLoaded = (): boolean => useEditorHelper(state => state.isEditorLoaded);

export const useEditorHelperSubmitForm = (): boolean => useEditorHelper(state => state.submitForm);

export const useEditorHelperHasContentChanged = (): boolean => useEditorHelper(state => state.hasContentChanged);

export const useEditorHelperHighlightCommentId = (): string | null =>
  useEditorHelper(state => state.highlightedCommentId);

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontTeaserTitle } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${p => p.theme.toRem(292)};
  height: ${p => p.theme.toRem(140)};
  background: ${p => p.theme.color.secondary_citrus};
  border-radius: 5px;
  position: relative;
  padding: ${p => `${p.theme.toRem(44)} ${p.theme.toRem(15)}`};
`;

export const ContentLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ClassName = styled.p`
  ${FontTeaserTitle};
  text-decoration: none;
  color: ${p => p.theme.color.primary_black};
  line-height: 2;
`;

export const LinkWrapper = styled.div`
  position: absolute;
  bottom: ${p => p.theme.toRem(-15)};
`;

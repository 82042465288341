import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ToolBarLogoLink = styled(Link)`
  text-decoration: none;
`;

export const ToolbarLogoButton = styled.button`
  background: transparent;
  border: none;
`;

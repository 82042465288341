import { ToastOptions } from 'react-toastify';

export type ToastMessageType = 'success' | 'error';

export enum ToastMessageTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const DEFAULT_TOAST_MESSAGE_CLOSING_TIMEOUT = 3000;

export interface updateToastParamsRequest {
  message?: string | null;
  type?: ToastMessageType | null;
  showToast?: boolean;
}

export interface ToastMessageActions {
  setToastMessageParams(requestParams: updateToastParamsRequest): void;
}

export interface ToastMessageState {
  message: string | null;
  type: ToastMessageType | null;
  showToast: boolean;
  actions: ToastMessageActions;
}

export const DEFAULT_TOAST_MESSAGE_CONFIG: ToastOptions = {
  position: 'top-right',
  autoClose: DEFAULT_TOAST_MESSAGE_CLOSING_TIMEOUT,
  closeButton: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  className: 'ToastMessageBody',
};

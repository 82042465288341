/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { PasswordCredentialCreated } from './PasswordCredentialCreated';
import { PasswordCredentialCreatedFromJSON, PasswordCredentialCreatedToJSON } from './PasswordCredentialCreated';

/**
 *
 * @export
 * @interface TeacherCreated
 */
export interface TeacherCreated {
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  schoolId?: string;
  /**
   *
   * @type {Set<string>}
   * @memberof TeacherCreated
   */
  classIds?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  email?: string;
  /**
   *
   * @type {PasswordCredentialCreated}
   * @memberof TeacherCreated
   */
  credential?: PasswordCredentialCreated;
  /**
   *
   * @type {string}
   * @memberof TeacherCreated
   */
  schoolNumber?: string;
  /**
   *
   * @type {{ [key: string]: string | undefined; }}
   * @memberof TeacherCreated
   */
  profile?: { [key: string]: string | undefined };
}

/**
 * Check if a given object implements the TeacherCreated interface.
 */
export function instanceOfTeacherCreated(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TeacherCreatedFromJSON(json: any): TeacherCreated {
  return TeacherCreatedFromJSONTyped(json, false);
}

export function TeacherCreatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherCreated {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    username: !exists(json, 'username') ? undefined : json['username'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    schoolId: !exists(json, 'school_id') ? undefined : json['school_id'],
    classIds: !exists(json, 'class_ids') ? undefined : json['class_ids'],
    email: !exists(json, 'email') ? undefined : json['email'],
    credential: !exists(json, 'credential') ? undefined : PasswordCredentialCreatedFromJSON(json['credential']),
    schoolNumber: !exists(json, 'school_number') ? undefined : json['school_number'],
    profile: !exists(json, 'profile') ? undefined : json['profile'],
  };
}

export function TeacherCreatedToJSON(value?: TeacherCreated | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    username: value.username,
    first_name: value.firstName,
    last_name: value.lastName,
    school_id: value.schoolId,
    class_ids: value.classIds,
    email: value.email,
    credential: PasswordCredentialCreatedToJSON(value.credential),
    school_number: value.schoolNumber,
    profile: value.profile,
  };
}

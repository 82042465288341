/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { createQueryString } from "@skribi/shared/src/utils/helpers";
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from "../base";
import { NoteComplete, NotePartial, QueryResponseNoteBasic } from "../models";
/**
 * NotesApi - axios parameter creator
 * @export
 */
export const NotesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * ## Preconditions * Depending on the status of the referenced text and the type of note, authenticated user must either be a reviewer of the text or a member of the associated class ## Postconditions * A note for the referenced text is created.
     * @summary Creates a note
     * @param {NoteComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNote: async (
      body: NoteComplete,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createNote."
        );
      }
      const localVarPath = `/notes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the owner of the note or must be allowed to manage the class associated to the note ## Postconditions * The specified note is deleted.
     * @summary Deletes a note
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNote: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteNote."
        );
      }
      const localVarPath = `/notes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
     * @summary Gets the complete representation of a note
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNote: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getNote."
        );
      }
      const localVarPath = `/notes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
     * @summary Queries basic representations of notes
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [type] Repeat to filter multiple types.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {Array<string>} [user] Repeat to filter multiple user IDs.
     * @param {Array<string>} [text] Repeat to filter multiple text IDs.
     * @param {Array<string>} [textAuthor] Repeat to filter multiple user IDs.
     * @param {boolean} [reported] If true, filter only reported notes
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryNotes: async (
      _class?: Array<string>,
      type?: Array<string>,
      status?: Array<string>,
      user?: Array<string>,
      text?: Array<string>,
      textAuthor?: Array<string>,
      reported?: boolean,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/notes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (_class) {
        localVarQueryParameter["class"] = _class;
      }

      if (type) {
        localVarQueryParameter["type"] = type;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      if (user) {
        localVarQueryParameter["user"] = user;
      }

      if (text) {
        localVarQueryParameter["text"] = text;
      }

      if (textAuthor) {
        localVarQueryParameter["text.author"] = textAuthor;
      }

      if (reported !== undefined) {
        localVarQueryParameter["reported"] = reported;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      const query = createQueryString(
        localVarQueryParameter,
        new URLSearchParams(localVarUrlObj.search)
      );

      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text referenced by the note or must be allowed to manage the class associated to the note ## Postconditions * The status of note is updated.
     * @summary Updates the status of a note
     * @param {string} id
     * @param {NotePartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoteStatus: async (
      id: string,
      body?: NotePartial,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateNoteStatus."
        );
      }
      const localVarPath = `/notes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "*/*";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotesApi - functional programming interface
 * @export
 */
export const NotesApiFp = function (configuration?: Configuration) {
  return {
    /**
     * ## Preconditions * Depending on the status of the referenced text and the type of note, authenticated user must either be a reviewer of the text or a member of the associated class ## Postconditions * A note for the referenced text is created.
     * @summary Creates a note
     * @param {NoteComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNote(
      body: NoteComplete,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<NoteComplete>>
    > {
      const localVarAxiosArgs = await NotesApiAxiosParamCreator(
        configuration
      ).createNote(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the owner of the note or must be allowed to manage the class associated to the note ## Postconditions * The specified note is deleted.
     * @summary Deletes a note
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNote(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await NotesApiAxiosParamCreator(
        configuration
      ).deleteNote(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
     * @summary Gets the complete representation of a note
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNote(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<NoteComplete>>
    > {
      const localVarAxiosArgs = await NotesApiAxiosParamCreator(
        configuration
      ).getNote(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
     * @summary Queries basic representations of notes
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [type] Repeat to filter multiple types.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {Array<string>} [user] Repeat to filter multiple user IDs.
     * @param {Array<string>} [text] Repeat to filter multiple text IDs.
     * @param {Array<string>} [textAuthor] Repeat to filter multiple user IDs.
     * @param {boolean} [reported] If true, filter only reported notes
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryNotes(
      _class?: Array<string>,
      type?: Array<string>,
      status?: Array<string>,
      user?: Array<string>,
      text?: Array<string>,
      textAuthor?: Array<string>,
      reported?: boolean,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<QueryResponseNoteBasic>>
    > {
      const localVarAxiosArgs = await NotesApiAxiosParamCreator(
        configuration
      ).queryNotes(
        _class,
        type,
        status,
        user,
        text,
        textAuthor,
        reported,
        offset,
        limit,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text referenced by the note or must be allowed to manage the class associated to the note ## Postconditions * The status of note is updated.
     * @summary Updates the status of a note
     * @param {string} id
     * @param {NotePartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNoteStatus(
      id: string,
      body?: NotePartial,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await NotesApiAxiosParamCreator(
        configuration
      ).updateNoteStatus(id, body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * NotesApi - factory interface
 * @export
 */
export const NotesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * ## Preconditions * Depending on the status of the referenced text and the type of note, authenticated user must either be a reviewer of the text or a member of the associated class ## Postconditions * A note for the referenced text is created.
     * @summary Creates a note
     * @param {NoteComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNote(
      body: NoteComplete,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<NoteComplete>> {
      return NotesApiFp(configuration)
        .createNote(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must either be the owner of the note or must be allowed to manage the class associated to the note ## Postconditions * The specified note is deleted.
     * @summary Deletes a note
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNote(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return NotesApiFp(configuration)
        .deleteNote(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
     * @summary Gets the complete representation of a note
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNote(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<NoteComplete>> {
      return NotesApiFp(configuration)
        .getNote(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
     * @summary Queries basic representations of notes
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [type] Repeat to filter multiple types.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {Array<string>} [user] Repeat to filter multiple user IDs.
     * @param {Array<string>} [text] Repeat to filter multiple text IDs.
     * @param {Array<string>} [textAuthor] Repeat to filter multiple user IDs.
     * @param {boolean} [reported] If true, filter only reported notes
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryNotes(
      _class?: Array<string>,
      type?: Array<string>,
      status?: Array<string>,
      user?: Array<string>,
      text?: Array<string>,
      textAuthor?: Array<string>,
      reported?: boolean,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<QueryResponseNoteBasic>> {
      return NotesApiFp(configuration)
        .queryNotes(
          _class,
          type,
          status,
          user,
          text,
          textAuthor,
          reported,
          offset,
          limit,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text referenced by the note or must be allowed to manage the class associated to the note ## Postconditions * The status of note is updated.
     * @summary Updates the status of a note
     * @param {string} id
     * @param {NotePartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNoteStatus(
      id: string,
      body?: NotePartial,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return NotesApiFp(configuration)
        .updateNoteStatus(id, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotesApi - object-oriented interface
 * @export
 * @class NotesApi
 * @extends {BaseAPI}
 */
export class NotesApi extends BaseAPI {
  /**
   * ## Preconditions * Depending on the status of the referenced text and the type of note, authenticated user must either be a reviewer of the text or a member of the associated class ## Postconditions * A note for the referenced text is created.
   * @summary Creates a note
   * @param {NoteComplete} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotesApi
   */
  public async createNote(
    body: NoteComplete,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<NoteComplete>> {
    return NotesApiFp(this.configuration)
      .createNote(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must either be the owner of the note or must be allowed to manage the class associated to the note ## Postconditions * The specified note is deleted.
   * @summary Deletes a note
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotesApi
   */
  public async deleteNote(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return NotesApiFp(this.configuration)
      .deleteNote(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be member of the class associated to the note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
   * @summary Gets the complete representation of a note
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotesApi
   */
  public async getNote(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<NoteComplete>> {
    return NotesApiFp(this.configuration)
      .getNote(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be member of the classes associated to each note * Authenticated user must either be the owner of the note or the author of the reference text to access feedback
   * @summary Queries basic representations of notes
   * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
   * @param {Array<string>} [type] Repeat to filter multiple types.
   * @param {Array<string>} [status] Repeat to filter multiple status values.
   * @param {Array<string>} [user] Repeat to filter multiple user IDs.
   * @param {Array<string>} [text] Repeat to filter multiple text IDs.
   * @param {Array<string>} [textAuthor] Repeat to filter multiple user IDs.
   * @param {boolean} [reported] If true, filter only reported notes
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotesApi
   */
  public async queryNotes(
    _class?: Array<string>,
    type?: Array<string>,
    status?: Array<string>,
    user?: Array<string>,
    text?: Array<string>,
    textAuthor?: Array<string>,
    reported?: boolean,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<QueryResponseNoteBasic>> {
    return NotesApiFp(this.configuration)
      .queryNotes(
        _class,
        type,
        status,
        user,
        text,
        textAuthor,
        reported,
        offset,
        limit,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must either be the author of the text referenced by the note or must be allowed to manage the class associated to the note ## Postconditions * The status of note is updated.
   * @summary Updates the status of a note
   * @param {string} id
   * @param {NotePartial} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotesApi
   */
  public async updateNoteStatus(
    id: string,
    body?: NotePartial,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return NotesApiFp(this.configuration)
      .updateNoteStatus(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

import { MandarinLink, TransparentLink } from 'components/Link';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.color.primary_white};
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 3fr 1fr;
`;

export const CustomTransparentLink = styled(TransparentLink)`
  align-self: center;
  justify-self: end;
`;

export const CustomMandarinLink = styled(MandarinLink)`
  align-self: center;
  justify-self: center;
`;

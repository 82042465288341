import { Configuration, NotesApi, TasksApi, TextsApi } from '@skribi/openapi/src/text-api';
import { TextSubmissionsApi } from '@skribi/openapi/src/text-api/apis/text-submissions-api';
import {
  AggregateQueryResponseTextAuthorAggregateBasic,
  AuthorAggregateBasic,
  EMailComplete,
  NoteBasic,
  QueryResponseNoteBasic,
  SubmissionBasicStatusEnum,
  SubmissionCompleteStatusEnum,
  SubmissionCompleteTypeEnum,
  TaskComplete,
  TextBasic,
  TextComplete,
  TextCompleteCategoryEnum,
} from '@skribi/openapi/src/text-api/models';
import {
  getResposeErrorTypeByStatusCode,
  ResponseCodesEnum,
  ResponseErrorsEnum,
} from '@skribi/shared/src/resources/error';
import { getAppConfigParam } from '@skribi/shared/src/utils/helpers';
import globalAxios, { AxiosResponse } from 'axios';
import { setNoteListState } from 'stores/Note';
import { setLoadedTask } from 'stores/Task';
import { getAxiosRequestConfig } from 'utils/helpers';
import {
  AggregateQueryResponseTextAuthorAggregateBasicFromJson,
  NoteBasicFromJson,
  TaskCompleteFromJson,
  TextCompleteFromJson,
} from 'utils/parser';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import {
  CreateTextRequest,
  DeleteTextRequest,
  GetMultipleTextsByIdRequest,
  getTaskTextAndFeedbacksByTextIdParams,
  GetTextRequest,
  QueryClassDashboardTextsResult,
  QueryHomeTextsProps,
  QueryStudentTextRequest,
  QueryTextNoEffectsResponse,
  QueryTextsFromTasksParams,
  ReportedTypeEnum,
  TextActions,
  TextState,
  UpdateSubmissionStatusRequest,
  UpdateTextRequest,
} from './types';

const textApiBaseconfig: Configuration = {
  basePath: getAppConfigParam('textApiUrl'),
};

globalAxios.interceptors.request.use(config => {
  if (config.data && typeof config.data === 'string') {
    // The api expects class_id but on models the prop is classId
    config.data = config.data.replace('classId', 'class_id');
    config.data = config.data.replace('taskId', 'task_id');
  }

  return config;
});

const useText = create<TextState>()((set, get) => ({
  isRequestInProgress: false,
  textList: [],
  error: null,
  loadedText: null,
  listCounter: null,
  textAggregateList: [],
  textRating: null,
  actions: {
    queryTextNoEffects: async (
      requestParams: QueryStudentTextRequest,
    ): Promise<QueryTextNoEffectsResponse | undefined> => {
      try {
        const response: AxiosResponse<AggregateQueryResponseTextAuthorAggregateBasic> = await new TextsApi(
          textApiBaseconfig,
        ).queryTexts(
          requestParams._class,
          requestParams.author,
          requestParams.access,
          requestParams.submissionType,
          requestParams.submissionStatus,
          requestParams.submissionTask,
          requestParams.submissionReviewer,
          requestParams.category,
          requestParams.search,
          requestParams.reported,
          requestParams.offset,
          requestParams.limit,
          requestParams.aggregate,
          await getAxiosRequestConfig(),
        );

        const parsedData: AggregateQueryResponseTextAuthorAggregateBasic =
          AggregateQueryResponseTextAuthorAggregateBasicFromJson(response.data);
        return {
          textList: !!parsedData.results ? parsedData.results : [],
          textCounter: !!parsedData.totalCount ? parsedData.totalCount : 0,
          aggregateList: !!parsedData.aggregates ? parsedData.aggregates : [],
        };
      } catch (error: any) {
        console.warn('queryTextNoEffects function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
        });
        return undefined;
      }
    },

    queryText: async (requestParams: QueryStudentTextRequest): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects(requestParams);

        if (response) {
          if (requestParams.updateListCount) {
            set({
              isRequestInProgress: false,
              textList: response.textList,
              listCounter: response.textCounter,
              textAggregateList: response.aggregateList,
            });
          } else {
            set({ isRequestInProgress: false, textList: response.textList, textAggregateList: response.aggregateList });
          }
        } else {
          set({ isRequestInProgress: false, textList: [], listCounter: 0 });
        }
      } catch (error: any) {
        console.warn('queryStudentText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    queryTextForTextControl: async (requestParams: QueryStudentTextRequest): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        if (
          (!requestParams.submissionStatus && !requestParams.reported) ||
          (requestParams.submissionStatus && requestParams.reported)
        ) {
          const textList: TextBasic[] | undefined = [];

          const textInReviewRequestResponse: QueryTextNoEffectsResponse | undefined =
            await get().actions.queryTextNoEffects({
              ...requestParams,
              submissionStatus: [SubmissionBasicStatusEnum.InReview],
            });

          const textReportedRequestResponse: QueryTextNoEffectsResponse | undefined =
            await get().actions.queryTextNoEffects({
              ...requestParams,
              reported: [ReportedTypeEnum.ANY],
            });

          (textInReviewRequestResponse?.textList ?? [])
            .concat(textReportedRequestResponse?.textList ?? [])
            .forEach(item => {
              if (!textList.find((el: TextBasic) => el.id === item.id)) {
                textList.push(item);
              }
            });

          set({
            isRequestInProgress: false,
            textList: textList,
            listCounter: textList.length,
            textAggregateList: [],
          });
        } else {
          const response: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects(
            requestParams,
          );

          if (response) {
            set({
              isRequestInProgress: false,
              textList: response?.textList ?? [],
              listCounter: response.textCounter,
              textAggregateList: [],
            });
          } else {
            set({
              isRequestInProgress: false,
              textList: [],
              listCounter: 0,
              textAggregateList: [],
            });
          }
        }
      } catch (error: any) {
        console.warn('queryTextForTextControl function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    queryTextWithCallback: async (
      requestParams: QueryStudentTextRequest & { onEnd: (textList: Array<TextBasic> | null) => void },
    ): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects(requestParams);

        if (response) {
          if (requestParams.updateListCount) {
            set({
              isRequestInProgress: false,
              textList: response.textList,
              listCounter: response.textCounter,
            });
          } else {
            set({ isRequestInProgress: false, textList: response.textList });
          }

          requestParams.onEnd(response.textList);
        } else {
          requestParams.onEnd([]);
        }
      } catch (error: any) {
        console.warn('queryStudentText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(null);
      }
    },

    createText: async (
      requestParams: CreateTextRequest & { onEnd: (ok: TextComplete | undefined) => void },
    ): Promise<void> => {
      const { onEnd, ...params } = requestParams;

      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<TextComplete> = await new TextsApi(textApiBaseconfig).createText(
          params.text,
          await getAxiosRequestConfig(),
        );
        set({ isRequestInProgress: false, loadedText: TextCompleteFromJson(response.data) });
        onEnd(TextCompleteFromJson(response.data));
      } catch (error: any) {
        console.warn('createText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(undefined);
      }
    },

    clearTextError: () => {
      set({ error: null });
    },

    updateText: async (requestParams: UpdateTextRequest & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        if (!requestParams.silentMode) {
          set({ isRequestInProgress: true });
        }
        const response: AxiosResponse<TextComplete> = await new TextsApi(textApiBaseconfig).updateText(
          data.id,
          data.body,
          await getAxiosRequestConfig(),
        );

        if (!requestParams.silentMode) {
          set({ isRequestInProgress: false, loadedText: response.data });
        }
        onEnd(true);
      } catch (error: any) {
        console.warn('updateText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });

        requestParams.onEnd(false);
      }
    },

    deleteText: async (requestParams: DeleteTextRequest & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });

        await new TextsApi(textApiBaseconfig).deleteText(data.id, await getAxiosRequestConfig());

        set({ isRequestInProgress: false });
        onEnd(true);
      } catch (error: any) {
        console.warn('deleteText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(false);
      }
    },

    getText: async (requestParams: GetTextRequest): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<TextComplete> = await new TextsApi(textApiBaseconfig).getText(
          requestParams.id,
          await getAxiosRequestConfig(),
        );

        // The api returns the class id as class_id but the models needs classId
        set({ isRequestInProgress: false, loadedText: TextCompleteFromJson(response.data) });
      } catch (error: any) {
        console.warn('getText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    clearLoadedText: (): void => {
      set({ loadedText: null });
    },

    updateSubmissionStatus: async (
      requestParams: UpdateSubmissionStatusRequest & { onEnd: (ok: boolean) => void },
    ): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });
        await new TextSubmissionsApi(textApiBaseconfig).updateSubmissionStatus(
          data.id,
          data.body,
          await getAxiosRequestConfig(),
        );
        set({ isRequestInProgress: false });
        onEnd(true);
      } catch (error: any) {
        console.warn('updateSubmissionStatus function error', error);
        requestParams.onEnd(false);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    queryTextsFromTasks: async (queryParams: QueryTextsFromTasksParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects({
          _class: [queryParams.classId],
          submissionType: [SubmissionCompleteTypeEnum.Task],
          submissionTask: queryParams.tasksId,
        });

        if (response) {
          set({ isRequestInProgress: false, textList: response.textList });
        } else {
          set({ isRequestInProgress: false, textList: [] });
        }
      } catch (error: any) {
        console.error('queryTextsFromTasks function error', error);
      }
    },

    getMultipleTextsById: async (
      requestParams: GetMultipleTextsByIdRequest & { onEnd: (textList: Array<TextComplete>) => void },
    ): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: Array<AxiosResponse<TextComplete>> = await Promise.all(
          requestParams.id.map(async textId => {
            return new TextsApi(textApiBaseconfig).getText(textId, await getAxiosRequestConfig());
          }),
        );
        const responseArray: Array<TextComplete> = [];

        const loadedTexts: Array<TextComplete> = response.reduce((prev, current) => {
          if (current.data) {
            responseArray.push(TextCompleteFromJson(current.data));
          }

          return responseArray;
        }, responseArray);

        set({ isRequestInProgress: false });

        requestParams.onEnd(loadedTexts);
      } catch (error: any) {
        console.warn('getText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    getTextAndTask: async (requestParams: GetTextRequest): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<TextComplete> = await new TextsApi(textApiBaseconfig).getText(
          requestParams.id,
          await getAxiosRequestConfig(),
        );

        if (response.data) {
          const loadedText: TextComplete = TextCompleteFromJson(response.data);

          if (loadedText.submission['taskId']) {
            const response: AxiosResponse<TaskComplete> = await new TasksApi(textApiBaseconfig).getTask(
              loadedText.submission['taskId'],
              await getAxiosRequestConfig(),
            );

            if (response.data) {
              setLoadedTask(TaskCompleteFromJson(response.data));
            }
          }

          set({ isRequestInProgress: false, loadedText: loadedText });
        } else {
          set({ isRequestInProgress: false, loadedText: null });
        }
      } catch (error: any) {
        console.warn('getText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    getTaskTextAndFeedbacksByTextId: async (requestParams: getTaskTextAndFeedbacksByTextIdParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const axiosConfig = await getAxiosRequestConfig();

        const getTextResponse: AxiosResponse<TextComplete> = await new TextsApi(textApiBaseconfig).getText(
          requestParams.textId,
          axiosConfig,
        );

        if (getTextResponse.data) {
          const loadedText: TextComplete = TextCompleteFromJson(getTextResponse.data);

          set({ loadedText: loadedText });

          const queryNotesResponse: AxiosResponse<QueryResponseNoteBasic> = await new NotesApi(
            textApiBaseconfig,
          ).queryNotes(
            [requestParams.classId],
            [requestParams.noteType],
            undefined,
            requestParams.userId && loadedText.author?.id !== requestParams.userId && !requestParams.isTeacher
              ? [requestParams.userId]
              : undefined,
            [loadedText?.id!],
            undefined,
            undefined,
            undefined,
            undefined,
            axiosConfig,
          );

          if (queryNotesResponse.data.results && queryNotesResponse.data.results.length > 0) {
            setNoteListState(queryNotesResponse.data.results.map(note => NoteBasicFromJson(note)));
          } else {
            setNoteListState(null);
          }

          if (loadedText.submission['taskId']) {
            const getTaskResponse: AxiosResponse<TaskComplete> = await new TasksApi(textApiBaseconfig).getTask(
              loadedText.submission['taskId'],
              axiosConfig,
            );

            if (getTaskResponse.data) {
              const loadedTask: TaskComplete = TaskCompleteFromJson(getTaskResponse.data);
              setLoadedTask(loadedTask);
            }
          }
        }

        set({ isRequestInProgress: false });
      } catch (error: any) {
        console.error('queryTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    queryTextAndNotes: async (requestParams: QueryStudentTextRequest): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const axiosRequestConfig = await getAxiosRequestConfig();

        const response: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects(requestParams);

        if (response) {
          const textIdList: Array<string> = response.textList.map(el => el.id!);

          const noteList: AxiosResponse<QueryResponseNoteBasic> = await new NotesApi(textApiBaseconfig).queryNotes(
            requestParams._class,
            undefined,
            undefined,
            undefined,
            textIdList,
            undefined,
            undefined,
            undefined,
            undefined,
            axiosRequestConfig,
          );

          if (noteList.data.results) {
            const parsedNotes: Array<NoteBasic> = noteList.data.results.map(el => NoteBasicFromJson(el));

            setNoteListState(parsedNotes);
          }

          if (requestParams.updateListCount) {
            set({ isRequestInProgress: false, textList: response.textList, listCounter: response.textCounter });
          } else {
            set({ isRequestInProgress: false, textList: response.textList });
          }
        } else {
          set({ isRequestInProgress: false, textList: [], listCounter: 0 });
          setNoteListState([]);
        }
      } catch (error: any) {
        console.warn('queryStudentText function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    queryHomeTexts: async (
      requestParams: QueryHomeTextsProps & { onEnd: (textList: Array<TextBasic>) => void },
    ): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const freeTextsResponse: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects({
          _class: [requestParams.classId],
          submissionType: [TextCompleteCategoryEnum.Free],
          author: [requestParams.userId],
          limit: 3,
          offset: 0,
        });

        const reviewerTextsResponse: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects({
          _class: [requestParams.classId],
          submissionReviewer: [requestParams.userId],
        });

        const textList: Array<TextBasic> = [
          ...(freeTextsResponse ? freeTextsResponse.textList : []),
          ...(reviewerTextsResponse ? reviewerTextsResponse.textList : []),
        ];

        set({ isRequestInProgress: false, textList: textList });
        requestParams.onEnd(textList);
      } catch (error: any) {
        console.warn('queryHomeTexts function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd([]);
      }
    },

    queryClassDashboardTexts: async (
      requestParams: QueryStudentTextRequest & { onEnd: (result: QueryClassDashboardTextsResult) => void },
    ): Promise<void> => {
      try {
        const inReviewTextResponse: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects({
          _class: requestParams._class,
          submissionStatus: [SubmissionCompleteStatusEnum.InReview],
        });

        const reportedTextResponse: QueryTextNoEffectsResponse | undefined = await get().actions.queryTextNoEffects({
          _class: requestParams._class,
          reported: [ReportedTypeEnum.ANY],
        });

        requestParams.onEnd({
          inReview: inReviewTextResponse?.textCounter ?? 0,
          reported: reportedTextResponse?.textCounter ?? 0,
        });
      } catch (error: any) {
        console.warn('queryClassDashboardTexts function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });

        return undefined;
      }
    },

    clearTextList: (): void => {
      set({ textList: [], listCounter: 0, textAggregateList: [], isRequestInProgress: false });
    },
  },
}));

export const useTextIsRequestInProgress = (): boolean => useText(state => state.isRequestInProgress);

export const useTextTextList = (): Array<TextBasic> => useText(state => state.textList, shallow);

export const useTextActions = (): TextActions => useText(state => state.actions);

export const useTextError = (): ResponseErrorsEnum | null => useText(state => state.error);

export const useTextListCounter = (): number | null => useText(state => state.listCounter);

export const useTextLoadedText = (): TextComplete | EMailComplete | null => useText(state => state.loadedText);

export const setTextListState = (params: Array<TextBasic>) => {
  useText.setState({ textList: params });
};

export const setLoadedTextState = (params: TextComplete) => {
  useText.setState({ loadedText: params });
};

export const queryTexts = async (params: QueryStudentTextRequest): Promise<QueryTextNoEffectsResponse | undefined> => {
  return await useText.getState().actions.queryTextNoEffects(params);
};

export const useTextTextAggregate = (): Array<AuthorAggregateBasic> =>
  useText(state => state.textAggregateList, shallow);

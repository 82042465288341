import styled from 'styled-components';

export const AppContainer = styled.div`
  height: var(--vh100);
  display: grid;
  justify-content: center;

  grid-template-rows: 1fr auto;

  grid-column-gap: 0;
  grid-template-columns: 1fr;

  grid-template-areas:
    'page--main'
    'page--footer';
`;

interface ContentContainerProps {
  $bgColor?: string;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  grid-area: page--main;
  width: 100%;
  background: ${p => (p.$bgColor ? p.$bgColor : p.theme.color.primary_white)};
  overflow-y: auto;
`;

export const Footer = styled.footer`
  grid-area: page--footer;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -6px 12px 0 rgba(105, 105, 105, 0.15);
  width: 100%;
  background: ${p => p.theme.color.primary_turquoise};
`;

import { ValidationMessage } from 'components/Input';
import styled from 'styled-components';
import { FontBody } from 'styles';

export const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: ${p => p.theme.toRem(20)};
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2) inset;
  border: none;
  resize: none;

  :focus-within {
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2) inset;
    outline-offset: -2px;
  }
`;

export const SmallPaddingTextArea = styled(TextArea)`
  outline-color: transparent;
  padding: ${p => `${p.theme.toRem(9)} ${p.theme.toRem(24)}`};
  height: 100%;
  overflow-y: hidden;
  ${FontBody};
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CustomValidationMessage = styled(ValidationMessage)`
  top: 100%;
`;

export const TextAreaNoShadow = styled.textarea`
  width: 100%;
  height: 100%;
  padding: ${p => p.theme.toRem(20)};
  border: none;
  resize: none;
  min-height: ${p => p.theme.toRem(160)};

  :focus-within {
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2) inset;
    outline-offset: -2px;
  }
`;

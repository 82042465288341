import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${p => p.theme.toRem(512)};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: ${p => p.theme.toRem(80)};
    width: ${p => p.theme.toRem(80)};
  }
`;

import { SchoolBasic } from '@skribi/school-utils/src/resources';
import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import Cookies from 'universal-cookie';
import { useAuthTokenActions } from '../store/AuthToken';
import { KeycloakService } from './KeycloakService';

export interface UserAccountContext {
  isAuthenticated: boolean;
  logout(schoolNumber?: string): void;
  login(requestOptions?: KeycloakLoginOptions): void;
  isStudent(): boolean;
  isTeacher(): boolean;
  getFirstClassId(): string;
  getFirstSchoolId(): string;
  getSchoolIdList(): string[];
  getClasses(): Array<string>;
  getUserId(): string;
  getUserName(): string;
  doStudentLogin(token: Array<string> | string, schoolData: SchoolBasic, redirectUri?: string): void;
  forceUpdateToken(onUpdateCallback?: any): void;
}

/**
 * Default values for the {@link userAccountContext}
 */
const defaultAuthContextValues: UserAccountContext = {
  isAuthenticated: false,
  logout: (schoolNumber?: string) => {},
  login: (requestOptions?: KeycloakLoginOptions) => {},
  isStudent: () => false,
  isTeacher: () => false,
  getFirstClassId: () => '',
  getClasses: () => [],
  getFirstSchoolId: () => '',
  getSchoolIdList: () => [],
  getUserId: () => '',
  getUserName: () => '',
  doStudentLogin: (token: Array<string> | string, schoolData: SchoolBasic, redirectUri?: string) => {},
  forceUpdateToken: (onUpdateCallback?: any) => {},
};
/**
 * Create the AuthContext using the default values.
 */
export const userAccountContext = createContext<UserAccountContext>(defaultAuthContextValues);

/**
 * The props that must be passed to create the {@link UserAccountProvider}.
 */
interface UserProfileContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  keycloak: Keycloak;
}

export const useUserAccountContext = () => {
  return useContext(userAccountContext);
};

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
export const UserAccountProvider = ({ children, keycloak }: PropsWithChildren<UserProfileContextProviderProps>) => {
  const [isAuthenticated] = useState<boolean>(!!keycloak.token);
  const cookies: Cookies = new Cookies();

  const doStudentLogin = async (token: Array<string> | string, schoolData: SchoolBasic, redirectUri?: string) => {
    const { doTokenLogin } = useAuthTokenActions();

    await doTokenLogin(token, schoolData, redirectUri);
  };

  const defaultLoginOptions = {
    redirectUri: `${window.location.origin}`,
  };

  const login = (requestOptions: KeycloakLoginOptions = defaultLoginOptions) => keycloak.login(requestOptions);
  const logout = (schoolNumber: string = '') => {
    const baseUrl: string = window.location.origin;
    const redirectUrl = KeycloakService.isStudent()
      ? `${baseUrl}/login${schoolNumber ? `/${schoolNumber}` : ''}`
      : baseUrl;

    const defaultLogoutOptions = {
      redirectUri: redirectUrl,
    };

    cookies.remove('token_storage', {
      maxAge: 0,
      path: '/',
    });

    keycloak.logout(defaultLogoutOptions);
  };

  const isStudent = (): boolean => KeycloakService.isStudent();
  const isTeacher = (): boolean => KeycloakService.isTeacher();
  const getFirstClassId = (): string => KeycloakService.getFirstClassId();
  const getClasses = (): Array<string> => KeycloakService.getClasses();
  const getFirstSchoolId = (): string => KeycloakService.getFirstSchoolId();
  const getSchoolIdList = (): string[] => KeycloakService.getSchoolIdList();
  const getUserId = (): string => KeycloakService.getUserId();
  const getUserName = (): string => KeycloakService.getUserName();
  const forceUpdateToken = (onUpdateCallback?: any) => KeycloakService.forceUpdateToken(onUpdateCallback);

  const callables = {
    isAuthenticated,
    login,
    logout,
    isStudent,
    isTeacher,
    getFirstClassId,
    getClasses,
    getFirstSchoolId,
    getUserName,
    getUserId,
    doStudentLogin,
    forceUpdateToken,
    getSchoolIdList,
  };

  return <userAccountContext.Provider value={callables}>{children}</userAccountContext.Provider>;
};

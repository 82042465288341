import styled from 'styled-components';
import { media } from 'styles';
import { AlignmentEnum } from './types';

interface BaseOuterWrapperProps {
  $alignment: AlignmentEnum;
}

const BaseOuterWrapper = styled.div<BaseOuterWrapperProps>`
  display: grid;
  justify-items: ${p => p.$alignment};

  margin-right: auto;
  margin-left: auto;

  width: var(--vw100);

  max-width: ${p => p.theme.breakpoints.desktop}px;
`;

export const OuterWrapper = styled(BaseOuterWrapper)`
  padding: ${p => `0 ${p.theme.toRem(46)}`};

  ${media.desktop`
    padding: 0;
  `}
`;

export const OuterWrapperFullHeight = styled(BaseOuterWrapper)`
  height: 100%;
  padding: ${p => `0 ${p.theme.toRem(46)}`};
`;

export const OuterWrapperNoPadding = styled(BaseOuterWrapper)`
  justify-items: ${p => p.$alignment};
`;

import { getAppConfigParam } from '@skribi/shared/src/utils/helpers';

export const TASK_TEMPLATE_DRUPAL_ENDPOINT = 'jsonapi/node/template_schreibauftrag';

const DRUPAL_TASK_TEMPLATE_BASE_URL: string = [getAppConfigParam('drupalApiUrl'), TASK_TEMPLATE_DRUPAL_ENDPOINT].join(
  '/',
);

export const getQueryTaskTemplateUrl = (queryParams?: URLSearchParams): string => {
  if (!!queryParams?.toString()) {
    return [DRUPAL_TASK_TEMPLATE_BASE_URL, queryParams.toString()].join('?');
  } else {
    return DRUPAL_TASK_TEMPLATE_BASE_URL;
  }
};

export const getLoadTaskTemplateByIdUrl = (templateId: string): string => {
  return [DRUPAL_TASK_TEMPLATE_BASE_URL, templateId].join('/');
};

import styled from 'styled-components';
import { FontH1, FontH2, FontH3 } from 'styles';

export const H1 = styled.h1`
  ${FontH1};
`;

export const H2 = styled.h2`
  ${FontH2};
`;

export const InvisibleH1 = styled.h1`
  position: absolute;
  top: -1000px;
`;

export const H3 = styled.h3`
  ${FontH3};
`;

export interface EditorHelperState {
  hasSelectedContent: boolean;
  commentColor: string | null;
  actions: EditorHelperActions;
  text: string;
  highlightedCommentId: string | null;
  isEditorLoaded: boolean;
  submitForm: boolean;
  hasContentChanged: boolean;
}

export interface EditorHelperActions {
  setHasSelectedContent(requestParams: boolean): void;
  setCommentColor(requestParams: string | null): void;
  setText(requestParams: string): void;
  setHighlightedCommentId(requestParam: string | null): void;
  setIsEditorLoaded(requestParams: boolean): void;
  setSubmitForm(requestParam: boolean): void;
  setHasContentChanged(requestParam: boolean): void;
}

export const HIGHLIGHT_COLOR_LIST: Array<string> = [
  '#fff38a',
  '#c9e5ff',
  '#ffdad0',
  '#c3ffa7',
  '#ffeac2',
  '#ffb7b7',
  '#a6e1f4',
  '#6aff9d',
  '#d9d0cb',
  '#bff7fb',
  '#ffd788',
  '#ddcdff',
  '#d7e0e0',
  '#fec1ff',
  '#6bddf6',
];

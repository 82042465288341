/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface SchoolClassBasic
 */
export interface SchoolClassBasic {
  /**
   *
   * @type {string}
   * @memberof SchoolClassBasic
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolClassBasic
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SchoolClassBasic
   */
  year?: number;
  /**
   *
   * @type {string}
   * @memberof SchoolClassBasic
   */
  teacherName?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolClassBasic
   */
  access?: SchoolClassBasicAccessEnum;
  /**
   *
   * @type {Set<string>}
   * @memberof SchoolClassBasic
   */
  levels?: Set<SchoolClassBasicLevelsEnum>;
  /**
   *
   * @type {Set<number>}
   * @memberof SchoolClassBasic
   */
  grades?: Set<number>;
}

/**
 * @export
 */
export const SchoolClassBasicAccessEnum = {
  Member: 'member',
  School: 'school',
} as const;
export type SchoolClassBasicAccessEnum = (typeof SchoolClassBasicAccessEnum)[keyof typeof SchoolClassBasicAccessEnum];

/**
 * @export
 */
export const SchoolClassBasicLevelsEnum = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const;
export type SchoolClassBasicLevelsEnum = (typeof SchoolClassBasicLevelsEnum)[keyof typeof SchoolClassBasicLevelsEnum];

/**
 * Check if a given object implements the SchoolClassBasic interface.
 */
export function instanceOfSchoolClassBasic(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function SchoolClassBasicFromJSON(json: any): SchoolClassBasic {
  return SchoolClassBasicFromJSONTyped(json, false);
}

export function SchoolClassBasicFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolClassBasic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    year: !exists(json, 'year') ? undefined : json['year'],
    teacherName: !exists(json, 'teacher_name') ? undefined : json['teacher_name'],
    access: !exists(json, 'access') ? undefined : json['access'],
    levels: !exists(json, 'levels') ? undefined : json['levels'],
    grades: !exists(json, 'grades') ? undefined : json['grades'],
  };
}

export function SchoolClassBasicToJSON(value?: SchoolClassBasic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    year: value.year,
    teacher_name: value.teacherName,
    access: value.access,
    levels: value.levels,
    grades: value.grades,
  };
}

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SchoolClassBasic, TeacherComplete, TeacherCreated, TeacherProvision } from '../models';
import {
  SchoolClassBasicFromJSON,
  TeacherCompleteFromJSON,
  TeacherCreatedFromJSON,
  TeacherCreatedToJSON,
  TeacherProvisionFromJSON,
} from '../models';
import * as runtime from '../runtime';

export interface CreateTeacherRequest {
  teacherCreated?: TeacherCreated;
}

export interface DeleteTeacherRequest {
  teacherId: string;
  deleteNow?: boolean;
}

export interface GetClassesOfTeacherRequest {
  teacherId: string;
}

export interface GetTeacherRequest {
  teacherId: string;
}

export interface ProvisionTeachersRequest {
  numTeachers?: number;
  teacherCreated?: TeacherCreated;
}

/**
 *
 */
export class TeachersApi extends runtime.BaseAPI {
  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school ## Postconditions * A teacher user is created and assigned to the school.
   * Creates a teacher user in a school
   */
  async createTeacherRaw(
    requestParameters: CreateTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TeacherCreated>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/teachers`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TeacherCreatedToJSON(requestParameters.teacherCreated),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => TeacherCreatedFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school ## Postconditions * A teacher user is created and assigned to the school.
   * Creates a teacher user in a school
   */
  async createTeacher(
    requestParameters: CreateTeacherRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TeacherCreated> {
    const response = await this.createTeacherRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school ## Postconditions * The teacher user is deleted. * All classes and students created by the teacher are deleted.
   * Deletes a teacher user
   */
  async deleteTeacherRaw(
    requestParameters: DeleteTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
      throw new runtime.RequiredError(
        'teacherId',
        'Required parameter requestParameters.teacherId was null or undefined when calling deleteTeacher.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.deleteNow !== undefined) {
      queryParameters['delete_now'] = requestParameters.deleteNow;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/teachers/{teacherId}`.replace(
          `{${'teacherId'}}`,
          encodeURIComponent(String(requestParameters.teacherId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school ## Postconditions * The teacher user is deleted. * All classes and students created by the teacher are deleted.
   * Deletes a teacher user
   */
  async deleteTeacher(
    requestParameters: DeleteTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteTeacherRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school.
   * Gets the classes of a teacher
   */
  async getClassesOfTeacherRaw(
    requestParameters: GetClassesOfTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<SchoolClassBasic>>> {
    if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
      throw new runtime.RequiredError(
        'teacherId',
        'Required parameter requestParameters.teacherId was null or undefined when calling getClassesOfTeacher.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/teachers/{teacherId}/classes`.replace(
          `{${'teacherId'}}`,
          encodeURIComponent(String(requestParameters.teacherId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SchoolClassBasicFromJSON));
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school.
   * Gets the classes of a teacher
   */
  async getClassesOfTeacher(
    requestParameters: GetClassesOfTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<SchoolClassBasic>> {
    const response = await this.getClassesOfTeacherRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school.
   * Gets a teacher user
   */
  async getTeacherRaw(
    requestParameters: GetTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TeacherComplete>> {
    if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
      throw new runtime.RequiredError(
        'teacherId',
        'Required parameter requestParameters.teacherId was null or undefined when calling getTeacher.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/teachers/{teacherId}`.replace(
          `{${'teacherId'}}`,
          encodeURIComponent(String(requestParameters.teacherId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => TeacherCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must match the teacher\'s ID or must be allowed to manage any school.
   * Gets a teacher user
   */
  async getTeacher(
    requestParameters: GetTeacherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TeacherComplete> {
    const response = await this.getTeacherRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school ## Postconditions * The given number of teacher is created and assigned to the school.
   * Creates multiple teacher users based on a given templtae
   */
  async provisionTeachersRaw(
    requestParameters: ProvisionTeachersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TeacherProvision>>> {
    const queryParameters: any = {};

    if (requestParameters.numTeachers !== undefined) {
      queryParameters['num_teachers'] = requestParameters.numTeachers;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/teachers-provisioning`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TeacherCreatedToJSON(requestParameters.teacherCreated),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TeacherProvisionFromJSON));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any school ## Postconditions * The given number of teacher is created and assigned to the school.
   * Creates multiple teacher users based on a given templtae
   */
  async provisionTeachers(
    requestParameters: ProvisionTeachersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TeacherProvision>> {
    const response = await this.provisionTeachersRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

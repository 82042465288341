import React from 'react';
import ReactLoading from 'react-loading';
import { Wrapper } from './style';

export function Loader() {
  return (
    <Wrapper>
      <ReactLoading type="spinningBubbles" color={'#000'} height={100} width={100} />
    </Wrapper>
  );
}

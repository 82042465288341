/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface StudentExport
 */
export interface StudentExport {
  /**
   *
   * @type {string}
   * @memberof StudentExport
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StudentExport
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof StudentExport
   */
  lastName?: string;
}

/**
 * Check if a given object implements the StudentExport interface.
 */
export function instanceOfStudentExport(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function StudentExportFromJSON(json: any): StudentExport {
  return StudentExportFromJSONTyped(json, false);
}

export function StudentExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentExport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
  };
}

export function StudentExportToJSON(value?: StudentExport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    first_name: value.firstName,
    last_name: value.lastName,
  };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface SubmissionPartial
 */
export interface SubmissionPartial {
    /**
     * 
     * @type {string}
     * @memberof SubmissionPartial
     */
    type: SubmissionPartialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionPartial
     */
    status?: SubmissionPartialStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SubmissionPartialTypeEnum {
    Free = 'free',
    Task = 'task'
}
/**
    * @export
    * @enum {string}
    */
export enum SubmissionPartialStatusEnum {
    Open = 'open',
    Draft = 'draft',
    InDiscussion = 'in_discussion',
    InReview = 'in_review',
    Published = 'published'
}


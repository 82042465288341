import { HelmetProps, RouteRoleEnum, Routes } from '@skribi/shared/src/resources/routes/types';
import { Home } from 'pages/Home';
import { Library } from 'pages/Library';
import { Login } from 'pages/Login';
import { CreateText } from 'pages/Student/CreateText';
import { MyTasks } from 'pages/Student/MyTasks';
import { MyTexts } from 'pages/Student/MyTexts';
import { MyWorld } from 'pages/Student/MyWorld';
import { SelectTextType } from 'pages/Student/SelectTextType';
import { WhoComment } from 'pages/Student/WhoComment';
import { ClassDashboard } from 'pages/Teacher/ClassDashboard';
import { ClassManagement } from 'pages/Teacher/ClassManagement/Loadable';
import { ClassOverview } from 'pages/Teacher/ClassOverview';
import { ClassStatistics } from 'pages/Teacher/ClassStatistics';
import { CreateTask } from 'pages/Teacher/CreateTask';
import { SelectTaskType } from 'pages/Teacher/SelectTaskType';
import { TaskOverview } from 'pages/Teacher/TaskOverview';
import { TaskTemplateDetail } from 'pages/Teacher/TaskTemplateDetail';
import { TaskTemplates } from 'pages/Teacher/TaskTemplates';
import { TaskTracking } from 'pages/Teacher/TaskTracking';
import { TextControl } from 'pages/Teacher/TextControl';
import { TextDetail } from 'pages/TextDetail';
import { TextPrint } from 'pages/TextPrint';
import { TextWithTask } from 'pages/TextWithTask';
import { ComponentType } from 'react';
import { titles } from './titles';

export interface RouteConfig {
  path: Routes;
  element: ComponentType<any>;
  helmetProps: HelmetProps;
}

export interface ProtectedRouteConfig extends RouteConfig {
  roleRequired?: RouteRoleEnum;
}

export const protectedRoutes: ProtectedRouteConfig[] = [
  {
    path: Routes.Init,
    element: Home,
    helmetProps: {
      title: titles.Home,
      description: titles.Home,
    },
  },

  {
    path: Routes.Home,
    element: Home,
    helmetProps: {
      title: titles.Home,
      description: titles.Home,
    },
  },

  {
    path: Routes.TextPrint,
    element: TextPrint,
    helmetProps: {
      title: titles.TextPrint,
      description: titles.TextPrint,
    },
  },

  {
    path: Routes.SelectTextType,
    element: SelectTextType,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.SelectTextType,
      description: titles.SelectTextType,
    },
  },

  {
    path: Routes.CreateText,
    element: CreateText,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.CreateText,
      description: titles.CreateText,
    },
  },

  {
    path: Routes.EditText,
    element: CreateText,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.EditText,
      description: titles.EditText,
    },
  },

  {
    path: Routes.MyTexts,
    element: MyTexts,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.MyTexts,
      description: titles.MyTexts,
    },
  },

  {
    path: Routes.TeacherTextDetail,
    element: TextDetail,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.TextDetail,
      description: titles.TextDetail,
    },
  },

  {
    path: Routes.TextDetail,
    element: TextDetail,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.TextDetail,
      description: titles.TextDetail,
    },
  },

  {
    path: Routes.TeacherReviewText,
    element: TextWithTask,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.WriteTask,
      description: titles.WriteTask,
    },
  },

  {
    path: Routes.StudentWriteTextWithTaskId,
    element: TextWithTask,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.WriteTask,
      description: titles.WriteTask,
    },
  },

  {
    path: Routes.StudentWriteTextWithTaskTextId,
    element: TextWithTask,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.WriteTask,
      description: titles.WriteTask,
    },
  },

  {
    path: Routes.MyTasks,
    element: MyTasks,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.MyTasks,
      description: titles.MyTasks,
    },
  },

  {
    path: Routes.TextReviewer,
    element: WhoComment,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.WhoComment,
      description: titles.WhoComment,
    },
  },

  {
    path: Routes.StudentLibrary,
    element: Library,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.Library,
      description: titles.Library,
    },
  },

  {
    path: Routes.TextLibrary,
    element: Library,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.Library,
      description: titles.Library,
    },
  },

  {
    path: Routes.ClassOverview,
    element: ClassOverview,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.ClassOverview,
      description: titles.ClassOverview,
    },
  },

  {
    path: Routes.TeacherTasksOverview,
    element: TaskOverview,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.TaskOverview,
      description: titles.TaskOverview,
    },
  },

  {
    path: Routes.SelectTaskType,
    element: SelectTaskType,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.SelectTaskType,
      description: titles.SelectTaskType,
    },
  },

  {
    path: Routes.ClassStatistics,
    element: ClassStatistics,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.ClassStatistics,
      description: titles.ClassStatistics,
    },
  },

  {
    path: Routes.CreateTask,
    element: CreateTask,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.CreateTask,
      description: titles.CreateTask,
    },
  },

  {
    path: Routes.TeacherEditTask,
    element: CreateTask,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.CreateTask,
      description: titles.CreateTask,
    },
  },

  {
    path: Routes.ClassDashboard,
    element: ClassDashboard,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.ClassDashboard,
      description: titles.ClassDashboard,
    },
  },

  {
    path: Routes.TextControl,
    element: TextControl,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.TextControl,
      description: titles.TextControl,
    },
  },

  {
    path: Routes.ClassEditor,
    element: ClassManagement,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.ClassEditor,
      description: titles.ClassEditor,
    },
  },

  {
    path: Routes.MyWorld,
    element: MyWorld,
    roleRequired: RouteRoleEnum.STUDENT,
    helmetProps: {
      title: titles.MyWorld,
      description: titles.MyWorld,
    },
  },

  {
    path: Routes.TeacherTaskTemplates,
    element: TaskTemplates,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.TaskTemplates,
      description: titles.TaskTemplates,
    },
  },

  {
    path: Routes.TeacherTaskTemplateDetail,
    element: TaskTemplateDetail,
    roleRequired: RouteRoleEnum.TEACHER,
    helmetProps: {
      title: titles.TaskTemplates,
      description: titles.TaskTemplates,
    },
  },
];

export const openRoutes: RouteConfig[] = [
  {
    path: Routes.Login,
    element: Login,
    helmetProps: {
      title: titles.Login,
      description: titles.Login,
    },
  },
  {
    path: Routes.LoginWithCredentials,
    element: Login,
    helmetProps: {
      title: titles.Login,
      description: titles.Login,
    },
  },

  {
    path: Routes.LoginWithSchoolNumber,
    element: Login,
    helmetProps: {
      title: titles.Login,
      description: titles.Login,
    },
  },

  {
    path: Routes.TaskTracking,
    element: TaskTracking,
    helmetProps: {
      title: titles.TaskTracking,
      description: titles.TaskTracking,
    },
  },
];

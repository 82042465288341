import { SubmissionCompleteTypeEnum, TextBasic, TextComplete } from '@skribi/openapi/src/text-api/models';
import { getUrlParamByTextCategory } from '../../utils/helpers';
import { OriginEnum, Routes, UrlParamsEnum } from './types';

export class LinkBuilder {
  public static login(schoolNumber: string, studentToken?: string) {
    const basePath = Routes.LoginWithSchoolNumber.replace(':schoolNumber', schoolNumber);

    if (studentToken) {
      return `${basePath}/${studentToken}`;
    } else {
      return basePath;
    }
  }

  public static SelectTaskType(classid: string) {
    return Routes.SelectTaskType.replace(':classId', classid);
  }

  public static CreateTask(classid: string, taskType: string) {
    return Routes.CreateTask.replace(':classId', classid).replace(':taskType', taskType);
  }

  public static TeacherTasksOverview(classid: string) {
    return Routes.TeacherTasksOverview.replace(':classId', classid);
  }

  public static TeacherTaskTracking(classid: string, taskId: string) {
    return Routes.TaskTracking.replace(':classId', classid).replace(':taskId', taskId);
  }

  public static TeacherClassDashboard(classid: string) {
    return Routes.ClassDashboard.replace(':classId', classid);
  }

  public static TextDetail(textId: string) {
    return Routes.TextDetail.replace(':textId', textId);
  }

  public static TeacherTextDetail(textId: string, classId: string) {
    return Routes.TeacherTextDetail.replace(':textId', textId).replace(':classId', classId);
  }

  public static TeacherTextControl(classId: string) {
    return Routes.TextControl.replace(':classId', classId);
  }

  public static TeacherEditTask(classId: string, taskId: string) {
    return Routes.TeacherEditTask.replace(':classId', classId).replace(':taskId', taskId);
  }

  public static TeacherTaskList(classId: string, taskId: string) {
    return Routes.TeacherEditTask.replace(':classId', classId).replace(':taskId', taskId);
  }

  public static StudentWriteTextWithTask(taskId?: string, textId?: string, origin?: string) {
    let urlString = '';
    if (textId) {
      urlString = Routes.StudentWriteTextWithTaskTextId.replace(':textId', textId);
    } else if (taskId) {
      urlString = Routes.StudentWriteTextWithTaskId.replace(':taskId', taskId);
    }

    if (urlString) {
      return origin ? `${urlString}?origin=${origin}` : urlString;
    }

    return '';
  }

  public static StudentAddTextReviewers(textId: string) {
    return Routes.TextReviewer.replace(':textId', textId);
  }

  public static TeacherReviewText(classId: string, textId: string) {
    return Routes.TeacherReviewText.replace(':textId', textId).replace(':classId', classId);
  }

  public static StudentEditText(text: TextBasic | TextComplete) {
    if (text.submission && text.submission.type === SubmissionCompleteTypeEnum.Task) {
      return `${Routes.StudentWriteTextWithTaskTextId.replace(':textId', text.id!)}?${UrlParamsEnum.ORIGIN}=${
        OriginEnum.MYTEXTS
      }`;
    } else {
      const textCategoryTranslated = getUrlParamByTextCategory(text.category);
      return Routes.EditText.replace(':textId', text.id!).replace(':textType', textCategoryTranslated!);
    }
  }

  public static StudentMyTexts() {
    return Routes.MyTexts;
  }

  public static PrintText(textId: string) {
    return Routes.TextPrint.replace(':textId', textId);
  }

  public static ClassStatistics(classId: string) {
    return Routes.ClassStatistics.replace(':classId', classId);
  }

  public static TaskTemplates(classId: string) {
    return Routes.TeacherTaskTemplates.replace(':classId', classId);
  }

  public static TaskTemplateDetail(classId: string, templateId: string) {
    return Routes.TeacherTaskTemplateDetail.replace(':classId', classId).replace(':templateId', templateId);
  }
}

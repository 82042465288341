import { TransparentButton } from 'components/Button';
import { IconCloseSmall } from 'icons';
import React from 'react';
import { ErrorAlertMessage, ErrorAlertWrapper } from './style';

interface ErrorAlertProps {
  message: string;
  clearErrorMessage?: () => void;
}

export function ErrorAlert({ message, clearErrorMessage }: ErrorAlertProps) {
  return (
    <ErrorAlertWrapper>
      <ErrorAlertMessage>{message}</ErrorAlertMessage>

      {!!clearErrorMessage && (
        <TransparentButton onClick={clearErrorMessage}>
          <IconCloseSmall />
        </TransparentButton>
      )}
    </ErrorAlertWrapper>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface SchoolClassPartial
 */
export interface SchoolClassPartial {
  /**
   *
   * @type {string}
   * @memberof SchoolClassPartial
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SchoolClassPartial
   */
  year?: number;
  /**
   *
   * @type {string}
   * @memberof SchoolClassPartial
   */
  access?: SchoolClassPartialAccessEnum;
  /**
   *
   * @type {Set<string>}
   * @memberof SchoolClassPartial
   */
  levels?: Set<SchoolClassPartialLevelsEnum>;
  /**
   *
   * @type {Set<number>}
   * @memberof SchoolClassPartial
   */
  grades?: Set<number>;
}

/**
 * @export
 */
export const SchoolClassPartialAccessEnum = {
  Member: 'member',
  School: 'school',
} as const;
export type SchoolClassPartialAccessEnum =
  (typeof SchoolClassPartialAccessEnum)[keyof typeof SchoolClassPartialAccessEnum];

/**
 * @export
 */
export const SchoolClassPartialLevelsEnum = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const;
export type SchoolClassPartialLevelsEnum =
  (typeof SchoolClassPartialLevelsEnum)[keyof typeof SchoolClassPartialLevelsEnum];

/**
 * Check if a given object implements the SchoolClassPartial interface.
 */
export function instanceOfSchoolClassPartial(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function SchoolClassPartialFromJSON(json: any): SchoolClassPartial {
  return SchoolClassPartialFromJSONTyped(json, false);
}

export function SchoolClassPartialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolClassPartial {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    year: !exists(json, 'year') ? undefined : json['year'],
    access: !exists(json, 'access') ? undefined : json['access'],
    levels: !exists(json, 'levels') ? undefined : json['levels'],
    grades: !exists(json, 'grades') ? undefined : json['grades'],
  };
}

export function SchoolClassPartialToJSON(value?: SchoolClassPartial | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    year: value.year,
    access: value.access,
    levels: value.levels ? Array.from(value.levels) : [],
    grades: value.grades ? Array.from(value.grades) : [],
  };
}

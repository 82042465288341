/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface NotePartial
 */
export interface NotePartial {
    /**
     * 
     * @type {string}
     * @memberof NotePartial
     */
    type: NotePartialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotePartial
     */
    status?: NotePartialStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NotePartialTypeEnum {
    Comment = 'comment',
    Feedback = 'feedback'
}
/**
    * @export
    * @enum {string}
    */
export enum NotePartialStatusEnum {
    Submitted = 'submitted',
    Done = 'done'
}


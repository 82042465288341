export const NavigationTypeEnum = {
  MAIN: 'main',
  WRITE_TASK: 'write_task',
  TEXT_WITH_TASK: 'text_with_task',
  TEACHER_TEXT_WITH_TASK: 'teacher_text_with_task',
  CLASS_OVERVIEW: 'class_overview',
  NEW_TASK: 'new_task',
  TASK_TRACKING: 'task_tracking',
  TEXT_REVIEWER: 'text_reviewer',
} as const;

export type NavigationType = (typeof NavigationTypeEnum)[keyof typeof NavigationTypeEnum];

const scope = 'PageTitle';

export const titles = {
  Login: `${scope}.login`,
  Home: `${scope}.home`,
  SelectTextType: `${scope}.selectTextType`,
  CreateText: `${scope}.createText`,
  EditText: `${scope}.editText`,
  MyTexts: `${scope}.myTexts`,
  TextDetail: `${scope}.textDetail`,
  MyTasks: `${scope}.myTasks`,
  WriteTask: `${scope}.writeTask`,
  WhoComment: `${scope}.whoComment`,
  Library: `${scope}.library`,
  ClassOverview: `${scope}.classOverview`,
  TaskOverview: `${scope}.taskOverview`,
  SelectTaskType: `${scope}.selectTaskType`,
  ClassStatistics: `${scope}.classStatistics`,
  CreateTask: `${scope}.createTask`,
  ClassDashboard: `${scope}.classDashboard`,
  TextControl: `${scope}.textControl`,
  ClassEditor: `${scope}.classEditor`,
  TaskTracking: `${scope}.taskTracking`,
  TextPrint: `${scope}.textPrint`,
  MyWorld: `${scope}.myWorld`,
  TaskTemplates: `${scope}.taskTemplates`,
};

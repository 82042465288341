import { OuterWrapper } from 'layouts/OuterWrapper';
import styled from 'styled-components';
import { media } from 'styles';

export const Wrapper = styled(OuterWrapper)`
  background: ${p => p.theme.color.primary_turquoise};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${p => `${p.theme.toRem(24)} ${p.theme.toRem(32)} ${p.theme.toRem(24)} ${p.theme.toRem(48)}`};

  ${media.desktop`
    padding: ${p => `${p.theme.toRem(24)} 0`};
  `}
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: ${p => p.theme.toRem(12)};
`;

import { OuterWrapper } from 'layouts/OuterWrapper';
import styled from 'styled-components';
import { FontH3 } from 'styles';

export const Wrapper = styled(OuterWrapper)`
  align-items: center;
  grid-template-columns: ${p => `auto ${p.theme.toRem(28)}`};
  background: ${p => p.theme.color.primary_turquoise};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: ${p => p.theme.toRem(12)};
  padding: ${p => `${p.theme.toRem(24)} 0 ${p.theme.toRem(24)} ${p.theme.toRem(50)}`};
`;

export const ModalContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: ${p => p.theme.toRem(20)};
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: ${p => p.theme.toRem(30)};
  justify-items: center;
`;

export const ModalTitle = styled.p`
  ${FontH3};
`;

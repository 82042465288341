import React from 'react';
import { ReactComponent as BottomBlack } from './assets/bottom-black.svg';
import { ReactComponent as BottomBlue } from './assets/bottom-blue.svg';
import { BaloonTopBlack, BaloonTopBlue, Wrapper } from './style';
import { BaloonColorEnum, BaloonSize } from './types';

interface CommentsBaloonProps {
  theme: BaloonColorEnum;
  label: number;
  size?: BaloonSize;
}

export function CommentsBaloon({ theme, label, size = 'small' }: CommentsBaloonProps) {
  const Ballon = {
    [BaloonColorEnum.BLUE]: BaloonTopBlue,
    [BaloonColorEnum.BLACK]: BaloonTopBlack,
  }[theme];

  const Icon = {
    [BaloonColorEnum.BLUE]: BottomBlue,
    [BaloonColorEnum.BLACK]: BottomBlack,
  }[theme];

  if (!Ballon || !Icon) return null;

  return (
    <Wrapper>
      <Ballon $size={size}>{label}</Ballon>
      <Icon />
    </Wrapper>
  );
}

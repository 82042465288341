/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface StudentCredentialCreated
 */
export interface StudentCredentialCreated {
  /**
   *
   * @type {string}
   * @memberof StudentCredentialCreated
   */
  token?: string;
}

/**
 * Check if a given object implements the StudentCredentialCreated interface.
 */
export function instanceOfStudentCredentialCreated(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function StudentCredentialCreatedFromJSON(json: any): StudentCredentialCreated {
  return StudentCredentialCreatedFromJSONTyped(json, false);
}

export function StudentCredentialCreatedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentCredentialCreated {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: !exists(json, 'token') ? undefined : json['token'],
  };
}

export function StudentCredentialCreatedToJSON(value?: StudentCredentialCreated | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
  };
}

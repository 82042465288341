import styled, { css } from 'styled-components';

interface CustomRadioProps {
  $isChecked: boolean;
}

export const CustomRadio = styled.button<CustomRadioProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.theme.toRem(20)};
  height: ${p => p.theme.toRem(20)};
  border-radius: 1000px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2) inset;
  position: relative;
  background: ${p => p.theme.color.primary_white};
  border: none;

  &::after {
    ${p => (p.$isChecked ? AfterCss : '')};
  }
`;

const AfterCss = css`
  position: absolute;
  content: '';
  width: ${p => p.theme.toRem(10)};
  height: ${p => p.theme.toRem(10)};
  background: ${p => p.theme.color.primary_black};
  border-radius: 1000px;
`;

import {
  getResposeErrorTypeByStatusCode,
  ResponseCodesEnum,
  ResponseErrorsEnum,
} from '@skribi/shared/src/resources/error';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import {
  ClassesApi,
  ClassTeachersApi,
  CreateSchoolClassRequest,
  DeleteSchoolClassRequest,
  GetClassesOfTeacherRequest,
  GetSchoolClassRequest,
  GetTeachersOfClassRequest,
  SchoolClass,
  SchoolClassBasic,
  SchoolClassComplete,
  TeacherBasic,
  TeachersApi,
  UpdateSchoolClassRequest,
} from '../../resources';
import { SchoolClassActions, SchoolClassState } from './types';

const useSchoolClass = create<SchoolClassState>()((set, get) => ({
  isRequestInProgress: false,
  active: null,
  classList: null,
  error: null,
  currentStudentMenuId: null,
  teacherList: null,
  actions: {
    queryClasses: async (queryParams: GetClassesOfTeacherRequest) => {
      try {
        set({ isRequestInProgress: true });
        const queryResult: Array<SchoolClassBasic> = await new TeachersApi().getClassesOfTeacher(queryParams);
        set({ isRequestInProgress: false, classList: queryResult ?? [] });
      } catch (error: any) {
        console.warn('Fetch error: School class', error);
        set({
          isRequestInProgress: false,
          classList: [],
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
        });
      }
    },

    fetchClass: async (queryParams: GetSchoolClassRequest) => {
      try {
        set({ isRequestInProgress: true });
        const result: SchoolClass = await new ClassesApi().getSchoolClass(queryParams);
        set({ isRequestInProgress: false, active: result ?? null });
      } catch (error: any) {
        set({ isRequestInProgress: false, active: null });
        console.warn('Fetch error: School class', error);
      }
    },

    deleteClass: async (queryParams: DeleteSchoolClassRequest) => {
      try {
        set({ isRequestInProgress: true });
        await new ClassesApi().deleteSchoolClass(queryParams);
        set({ isRequestInProgress: false, active: null });
      } catch (error: any) {
        set({ isRequestInProgress: false, active: null });
        console.warn('Fetch error: School class', error);
      }
    },

    updateClass: async (queryParams: UpdateSchoolClassRequest) => {
      try {
        set({ isRequestInProgress: true });
        const updatedClass: SchoolClassComplete = await new ClassesApi().updateSchoolClass(queryParams);
        set({ isRequestInProgress: false, active: updatedClass });
      } catch (error: any) {
        set({ isRequestInProgress: false, active: null });
        console.warn('Fetch error: School class', error);
      }
    },

    createClass: async (requestParams: CreateSchoolClassRequest & { onEnd: (classId: string) => void }) => {
      try {
        const schoolClass: SchoolClassComplete = await new ClassesApi().createSchoolClass({
          schoolId: requestParams.schoolId,
          createStudents: 15,
          schoolClassComplete: requestParams.schoolClassComplete,
        });

        set({ active: schoolClass });

        requestParams.onEnd(schoolClass.id ?? '');
      } catch (error: any) {
        console.log(error);
        set({ error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR) });
      }
    },

    queryClassTeacherList: async (requestParams: GetTeachersOfClassRequest) => {
      try {
        set({ isRequestInProgress: true });
        const teacherClassList: Array<TeacherBasic> = await new ClassTeachersApi().getTeachersOfClass(requestParams);
        set({ isRequestInProgress: false, teacherList: teacherClassList });
      } catch (error: any) {
        console.error('queryClassTeacherListqueryClassTeacherList', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    clearError: () => {
      set({ error: null });
    },

    setCurrentStudentMenuId: (currentStudentMenuId: string | null) => {
      set({ currentStudentMenuId: currentStudentMenuId });
    },

    setSchoolClassError: (requestParams: ResponseErrorsEnum) => {
      set({ error: requestParams });
    },
  },
}));

export const useSchoolClassIsRequestInProgress = (): boolean => useSchoolClass(state => state.isRequestInProgress);

export const useSchoolClassError = (): ResponseErrorsEnum | null => useSchoolClass(state => state.error);

export const useSchoolClassCurrentStudentMenuId = (): string | null =>
  useSchoolClass(state => state.currentStudentMenuId);

export const useSchoolClassActive = (): SchoolClass | SchoolClassComplete | null =>
  useSchoolClass(state => state.active, shallow);

export const useSchoolClassList = (): Array<SchoolClassBasic> | null =>
  useSchoolClass(state => state.classList, shallow);

export const useSchoolClassActions = (): SchoolClassActions => useSchoolClass(state => state.actions);

export const useSchoolClassTeacherList = (): Array<TeacherBasic> | null =>
  useSchoolClass(state => state.teacherList, shallow);

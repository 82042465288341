export const getHtmlToDocxBody = (content: string) => {
  return `
  <!DOCTYPE html>
  <html lang="de">
  <head>
    <meta charset="UTF-8">
    <title>Document</title>
  </head>
  <body>
   ${content}
  </body>
  </html>`;
};

export const getSingleTextBody = (
  title: string,
  content: string,
  studentName: string,
  creationDate: string,
  textType: string,
  addLineBreak: boolean,
): string => {
  return `
  <span>${studentName}</span><br/>
  <span>${creationDate}</span><br/>
  <span>${textType}</span>
  <br />
  <h1>${title}</h1>
  ${content}
  ${addLineBreak ? `<br clear="all" style="page-break-before:always" />` : ''}`;
};

export const USER_BADGE_SIZE: number = 155;

export const MY_WORLD_IMAGE: string = '/assets/images/my-world.webp';

export const COORDINATE_CONSTANT: string = 'coord';
export const SCALE_CONSTANT: string = 'scale';

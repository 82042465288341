import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import { useSchoolClassActions } from '@skribi/school-utils/src/store/SchoolClass';
import { ContentWrapper } from 'components/ContentWrapper';
import { ClassOverviewContent } from 'pages/Teacher/ClassOverview/ClassOverviewContent';
import React, { useCallback, useEffect } from 'react';

export function ClassOverview() {
  const { getFirstSchoolId, getUserId } = useUserAccountContext();

  const { queryClasses } = useSchoolClassActions();

  const handleLoadClassList = useCallback(
    async (schoolId: string): Promise<void> => {
      await queryClasses({ teacherId: getUserId() });
    },
    [getUserId, queryClasses],
  );

  useEffect(() => {
    if (getFirstSchoolId()) {
      handleLoadClassList(getFirstSchoolId());
    }
  }, [handleLoadClassList, getFirstSchoolId]);

  return (
    <ContentWrapper navigationType="class_overview">
      <ClassOverviewContent />
    </ContentWrapper>
  );
}

export const COOKIE_NAME = 'token_storage';

export interface TokenData {
  accessToken: string;
  refreshToken: string;
}

export interface UserObject {
  username: string;
  password: string;
}

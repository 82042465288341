import { useUserAccountContext } from '@skribi/keycloak-provider/src';
import { TaskComplete, TextComplete } from '@skribi/openapi/src/text-api';
import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { MandarinButton } from 'components/Button';
import { TransparentLink } from 'components/Link';
import { IconClose } from 'icons';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OriginEnum, Routes } from 'routes';
import { useTextLoadedText } from 'stores';
import { useEditorHelperActions } from 'stores/EditorHelper';
import { useTaskLoadedTask } from 'stores/Task';
import { messages } from './messages';
import { ButtonWrapper, Wrapper } from './style';

export function TextReviewer() {
  const [t] = useTranslation();

  const { isTeacher } = useUserAccountContext();
  const { setSubmitForm } = useEditorHelperActions();

  const activeTask: TaskComplete | null = useTaskLoadedTask();

  const loadedText: TextComplete | null = useTextLoadedText();

  const activeClass: SchoolClass | SchoolClassComplete | null = useSchoolClassActive();

  const [closeButtonLink, setCloseButtonLink] = useState<string | null>(null);

  useEffect(() => {
    setCloseButtonLink(
      activeTask?.id || (loadedText && loadedText.submission['taskId'])
        ? LinkBuilder.StudentWriteTextWithTask(loadedText?.submission['taskId']!, loadedText?.id!, OriginEnum.REVIEWER)
        : Routes.MyTasks,
    );
  }, [isTeacher, activeClass?.id, activeTask?.id, loadedText]);

  return (
    <React.Fragment>
      <Wrapper $alignment={AlignmentEnum.LEFT}>
        <ButtonWrapper>
          <MandarinButton onClick={() => setSubmitForm(true)}>
            <span>{t(messages.save)}</span>
          </MandarinButton>
        </ButtonWrapper>

        <TransparentLink to={closeButtonLink ? closeButtonLink : Routes.Home}>
          <IconClose title={t(messages.close)} />
        </TransparentLink>
      </Wrapper>
    </React.Fragment>
  );
}

import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActions, useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { ContentWrapper } from 'components/ContentWrapper';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTaskTemplateActions } from 'stores/TaskTemplate';
import { TaskTemplateDetailContent } from './TaskTemplateDetailContent';

type TextControlParams = {
  classId: string;
  templateId: string;
};

export function TaskTemplateDetail() {
  const { classId, templateId } = useParams<TextControlParams>();

  const { loadTaskTemplateById } = useTaskTemplateActions();
  const currentSchoolClass: SchoolClassComplete | SchoolClass | null = useSchoolClassActive();
  const { fetchClass } = useSchoolClassActions();

  useEffect(() => {
    if (templateId) {
      loadTaskTemplateById(templateId);
    }
  }, [loadTaskTemplateById, templateId]);

  useEffect(() => {
    if (classId && !currentSchoolClass?.id) {
      fetchClass({ classId });
    }
  }, [classId, currentSchoolClass?.id, fetchClass]);

  return (
    <ContentWrapper navigationType={'class_overview'}>
      <TaskTemplateDetailContent />
    </ContentWrapper>
  );
}

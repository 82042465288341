import styled from 'styled-components';
import { FontBody, FontBodyBold, FontButton } from 'styles';
import { InputAlignmentType } from './types';

interface InputProps {
  $textAlignment?: InputAlignmentType;
  $outlineColor?: string;
}

const BaseInput = styled.input<InputProps>`
  display: flex;
  align-items: center;
  padding: ${p => `0 ${p.theme.toRem(12)}`};
  background: ${props => props.theme.color.primary_white};
  text-align: ${p => p.$textAlignment ?? 'center'};
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: ${p => p.theme.toRem(772)};
  outline-color: ${p => p.$outlineColor ?? 'transparent'};

  // NEEDS TO WORK ON SAFARI
  -webkit-appearance: none;
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.3) inset;
  border: none;

  ${FontBody};
  color: ${props => props.theme.color.primary_black};

  ::placeholder {
    letter-spacing: normal;
    ${FontBody};
    color: ${props => props.theme.color.primary_black};
  }

  :focus-within {
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2) inset;
    outline-offset: -2px;
  }

  &:disabled {
    color: ${p => p.theme.color.cool_grey_2};
    cursor: default;
  }
`;

export const Input = styled(BaseInput)``;

export const UppercaseInput = styled(BaseInput)`
  text-transform: uppercase;
  min-height: ${p => p.theme.toRem(51)};
`;

export const NoWidthInput = styled(BaseInput)`
  width: 100%;
  max-width: 100%;
`;

export const FileInput = styled.input`
  &::file-selector-button {
    background-color: ${p => p.theme.color.primary_turquoise};
    border-radius: 100px;
    border: none;
    padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};

    ${FontButton};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  height: ${p => p.theme.toRem(50)};
  width: 100%;
`;

export const ValidationMessage = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 0 20px 20px 20px;
  background: ${p => p.theme.color.primary_mango};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
  padding: ${p => `${p.theme.toRem(15)} ${p.theme.toRem(30)}`};
  top: 40px;
  left: 30%;
  z-index: 2;

  ${FontBodyBold};
`;

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { QueryResponseNoteUserTypeAggregate } from '../models';
/**
 * NoteStatisticsApi - axios parameter creator
 * @export
 */
export const NoteStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ## Preconditions * The ID of the authenticated user must be equal to the `user` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each note 
         * @summary Aggregates note statistics grouped by user and type
         * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
         * @param {Array<string>} [status] Repeat to filter multiple status values.
         * @param {Array<string>} [user] Repeat to filter multiple user IDs.
         * @param {Array<string>} [text] Repeat to filter multiple text IDs.
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateNotesByUserAndType: async (_class?: Array<string>, status?: Array<string>, user?: Array<string>, text?: Array<string>, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/note-statistics/by-user-and-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_class) {
                localVarQueryParameter['class'] = _class;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (user) {
                localVarQueryParameter['user'] = user;
            }

            if (text) {
                localVarQueryParameter['text'] = text;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteStatisticsApi - functional programming interface
 * @export
 */
export const NoteStatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ## Preconditions * The ID of the authenticated user must be equal to the `user` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each note 
         * @summary Aggregates note statistics grouped by user and type
         * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
         * @param {Array<string>} [status] Repeat to filter multiple status values.
         * @param {Array<string>} [user] Repeat to filter multiple user IDs.
         * @param {Array<string>} [text] Repeat to filter multiple text IDs.
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateNotesByUserAndType(_class?: Array<string>, status?: Array<string>, user?: Array<string>, text?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<QueryResponseNoteUserTypeAggregate>>> {
            const localVarAxiosArgs = await NoteStatisticsApiAxiosParamCreator(configuration).aggregateNotesByUserAndType(_class, status, user, text, offset, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NoteStatisticsApi - factory interface
 * @export
 */
export const NoteStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ## Preconditions * The ID of the authenticated user must be equal to the `user` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each note 
         * @summary Aggregates note statistics grouped by user and type
         * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
         * @param {Array<string>} [status] Repeat to filter multiple status values.
         * @param {Array<string>} [user] Repeat to filter multiple user IDs.
         * @param {Array<string>} [text] Repeat to filter multiple text IDs.
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateNotesByUserAndType(_class?: Array<string>, status?: Array<string>, user?: Array<string>, text?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<QueryResponseNoteUserTypeAggregate>> {
            return NoteStatisticsApiFp(configuration).aggregateNotesByUserAndType(_class, status, user, text, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteStatisticsApi - object-oriented interface
 * @export
 * @class NoteStatisticsApi
 * @extends {BaseAPI}
 */
export class NoteStatisticsApi extends BaseAPI {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the `user` filter or the authenticated user must be allowed to manage classes * Authenticated user must be member of the classes associated to each note 
     * @summary Aggregates note statistics grouped by user and type
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [status] Repeat to filter multiple status values.
     * @param {Array<string>} [user] Repeat to filter multiple user IDs.
     * @param {Array<string>} [text] Repeat to filter multiple text IDs.
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteStatisticsApi
     */
    public async aggregateNotesByUserAndType(_class?: Array<string>, status?: Array<string>, user?: Array<string>, text?: Array<string>, offset?: number, limit?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<QueryResponseNoteUserTypeAggregate>> {
        return NoteStatisticsApiFp(this.configuration).aggregateNotesByUserAndType(_class, status, user, text, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}

import { createGlobalStyle } from 'styled-components';
import { font } from './defaultTheme';
import { Index } from './fonts/index';
import { FontBody } from './typography';

export const GlobalStyle = createGlobalStyle`
  ${Index};

  html {
    font-size: ${p => p.theme.baseFontSize}px;
  }
  
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: ${font.family};
    ${FontBody};
    border: none;
	  margin: 0;
	  padding: 0;
    color: ${props => props.theme.color.primary_black};
  }

  span {
    color: ${props => props.theme.color.primary_black};
  }

  #root {
    height: 100%;
    width: 100%;
  }

  input, select, textarea {
    font-family: inherit;
    font-size: inherit;
  }

  button {
    font-family: ${font.family};
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, p, span, ol, ul, li, button, a {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
`;

import { ClassOverview } from 'components/Navigation/ClassOverview';
import { Main } from 'components/Navigation/Main';
import { NewTask } from 'components/Navigation/NewTask';
import { TaskTracking } from 'components/Navigation/TaskTracking';
import { TextReviewer } from 'components/Navigation/TextReviewer';
import { TeacherTextWithTask, TextWithTask } from 'components/Navigation/TextWithTask';
import { WriteTask } from 'components/Navigation/WriteTask';
import React from 'react';
import { NavigationType, NavigationTypeEnum } from './types';

interface NavigationProps {
  navigationType: NavigationType;
}

export function Navigation({ navigationType }: NavigationProps) {
  const GetNavComponent = (): JSX.Element => {
    switch (navigationType) {
      case NavigationTypeEnum.MAIN:
        return <Main />;
      case NavigationTypeEnum.WRITE_TASK:
        return <WriteTask />;
      case NavigationTypeEnum.TEXT_WITH_TASK:
        return <TextWithTask />;
      case NavigationTypeEnum.CLASS_OVERVIEW:
        return <ClassOverview />;
      case NavigationTypeEnum.NEW_TASK:
        return <NewTask />;
      case NavigationTypeEnum.TASK_TRACKING:
        return <TaskTracking />;
      case NavigationTypeEnum.TEXT_REVIEWER:
        return <TextReviewer />;
      case NavigationTypeEnum.TEACHER_TEXT_WITH_TASK:
        return <TeacherTextWithTask />;
      default:
        return <Main />;
    }
  };

  return <GetNavComponent />;
}

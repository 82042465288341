/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SchoolClass, SchoolClassBasic, SchoolClassComplete, SchoolClassPartial } from '../models';
import {
  SchoolClassBasicFromJSON,
  SchoolClassCompleteFromJSON,
  SchoolClassCompleteToJSON,
  SchoolClassFromJSON,
  SchoolClassPartialToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface CreateSchoolClassRequest {
  schoolId: string;
  createStudents?: number;
  schoolClassComplete?: SchoolClassComplete;
}

export interface DeleteSchoolClassRequest {
  classId: string;
  deleteNow?: boolean;
}

export interface GetSchoolClassRequest {
  classId: string;
}

export interface GetSchoolClassesRequest {
  schoolId: string;
  excludeOwn?: boolean;
}

export interface UpdateSchoolClassRequest {
  classId: string;
  schoolClassPartial?: SchoolClassPartial;
}

/**
 *
 */
export class ClassesApi extends runtime.BaseAPI {
  /**
   * ## Preconditions * Authenticated user must be member of the school or must be allowed to manage any school. * Authenticated user must be allowed to manage their own or any class. ## Postconditions * A class is created and assigned to the school. * The user is assigned to the school class if they are not allowed to manage any class. * The requested number of students are created and assigned to the school class.
   * Creates a class in a school
   */
  async createSchoolClassRaw(
    requestParameters: CreateSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SchoolClassComplete>> {
    if (requestParameters.schoolId === null || requestParameters.schoolId === undefined) {
      throw new runtime.RequiredError(
        'schoolId',
        'Required parameter requestParameters.schoolId was null or undefined when calling createSchoolClass.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.createStudents !== undefined) {
      queryParameters['create_students'] = requestParameters.createStudents;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/schools/{schoolId}/classes`.replace(
          `{${'schoolId'}}`,
          encodeURIComponent(String(requestParameters.schoolId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SchoolClassCompleteToJSON(requestParameters.schoolClassComplete),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => SchoolClassCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be member of the school or must be allowed to manage any school. * Authenticated user must be allowed to manage their own or any class. ## Postconditions * A class is created and assigned to the school. * The user is assigned to the school class if they are not allowed to manage any class. * The requested number of students are created and assigned to the school class.
   * Creates a class in a school
   */
  async createSchoolClass(
    requestParameters: CreateSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolClassComplete> {
    const response = await this.createSchoolClassRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class ## Postconditions * The class and assigned students are deleted.
   * Deletes a school class
   */
  async deleteSchoolClassRaw(
    requestParameters: DeleteSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling deleteSchoolClass.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.deleteNow !== undefined) {
      queryParameters['delete_now'] = requestParameters.deleteNow;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}`.replace(`{${'classId'}}`, encodeURIComponent(String(requestParameters.classId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class ## Postconditions * The class and assigned students are deleted.
   * Deletes a school class
   */
  async deleteSchoolClass(
    requestParameters: DeleteSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteSchoolClassRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must be member of the class or must be allowed to manage the class
   * Gets a school class
   */
  async getSchoolClassRaw(
    requestParameters: GetSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SchoolClass>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling getSchoolClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}`.replace(`{${'classId'}}`, encodeURIComponent(String(requestParameters.classId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => SchoolClassFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be member of the class or must be allowed to manage the class
   * Gets a school class
   */
  async getSchoolClass(
    requestParameters: GetSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolClass> {
    const response = await this.getSchoolClassRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be member of the school or must be allowed to manage any school. * Authenticated user must be member of classes not accessible by the whole school or must be allowed to manage any school class.
   * Gets the classes of a school
   */
  async getSchoolClassesRaw(
    requestParameters: GetSchoolClassesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<SchoolClassBasic>>> {
    if (requestParameters.schoolId === null || requestParameters.schoolId === undefined) {
      throw new runtime.RequiredError(
        'schoolId',
        'Required parameter requestParameters.schoolId was null or undefined when calling getSchoolClasses.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.excludeOwn !== undefined) {
      queryParameters['exclude_own'] = requestParameters.excludeOwn;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/schools/{schoolId}/classes`.replace(
          `{${'schoolId'}}`,
          encodeURIComponent(String(requestParameters.schoolId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SchoolClassBasicFromJSON));
  }

  /**
   * ## Preconditions * Authenticated user must be member of the school or must be allowed to manage any school. * Authenticated user must be member of classes not accessible by the whole school or must be allowed to manage any school class.
   * Gets the classes of a school
   */
  async getSchoolClasses(
    requestParameters: GetSchoolClassesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<SchoolClassBasic>> {
    const response = await this.getSchoolClassesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class ## Postconditions * The name, year, levels or grades of the specified class are updated.
   * Updates a school class
   */
  async updateSchoolClassRaw(
    requestParameters: UpdateSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SchoolClassComplete>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling updateSchoolClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/classes/{classId}`.replace(`{${'classId'}}`, encodeURIComponent(String(requestParameters.classId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: SchoolClassPartialToJSON(requestParameters.schoolClassPartial),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => SchoolClassCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class ## Postconditions * The name, year, levels or grades of the specified class are updated.
   * Updates a school class
   */
  async updateSchoolClass(
    requestParameters: UpdateSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SchoolClassComplete> {
    const response = await this.updateSchoolClassRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

export enum ResponseErrorsEnum {
  BAD_REQUEST = 1,
  UNAUTHORIZED = 2,
  FORBIDDEN = 3,
  NOT_FOUND = 4,
  METHOD_NOT_ALLOWED = 5,
  REQUEST_TIMEOUT = 6,
  CONFLICT = 7,
  INTERNAL_SERVER_ERROR = 8,
  NOT_IMPLEMENTED = 9,
  BAD_GATEWAY = 10,
  SERVICE_UNAVAILABLE = 11,
  GATEWAY_TIMEOUT = 12,
  UNHANDLED_ERROR = 13,
  GONE = 14,
  UNHANDLED_ERROR_1 = 15,
  UNHANDLED_ERROR_2 = 16,
  UNHANDLED_ERROR_3 = 17,
}

export enum ResponseCodesEnum {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 410,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  UNHANDLED_ERROR = 999,
  UNHANDLED_ERROR_1 = 998,
  UNHANDLED_ERROR_2 = 997,
  UNHANDLED_ERROR_3 = 996,
  GONE = 410,
}

export const getResposeErrorTypeByStatusCode = (statusCode: number) => {
  switch (statusCode) {
    case ResponseCodesEnum.BAD_REQUEST:
      return ResponseErrorsEnum.BAD_REQUEST;
    case ResponseCodesEnum.UNAUTHORIZED:
      return ResponseErrorsEnum.UNAUTHORIZED;
    case ResponseCodesEnum.FORBIDDEN:
      return ResponseErrorsEnum.FORBIDDEN;
    case ResponseCodesEnum.NOT_FOUND:
      return ResponseErrorsEnum.NOT_FOUND;
    case ResponseCodesEnum.METHOD_NOT_ALLOWED:
      return ResponseErrorsEnum.METHOD_NOT_ALLOWED;
    case ResponseCodesEnum.REQUEST_TIMEOUT:
      return ResponseErrorsEnum.REQUEST_TIMEOUT;
    case ResponseCodesEnum.CONFLICT:
      return ResponseErrorsEnum.CONFLICT;
    case ResponseCodesEnum.GONE:
      return ResponseErrorsEnum.GONE;
    case ResponseCodesEnum.INTERNAL_SERVER_ERROR:
      return ResponseErrorsEnum.INTERNAL_SERVER_ERROR;
    case ResponseCodesEnum.NOT_IMPLEMENTED:
      return ResponseErrorsEnum.NOT_IMPLEMENTED;
    case ResponseCodesEnum.BAD_GATEWAY:
      return ResponseErrorsEnum.BAD_GATEWAY;
    case ResponseCodesEnum.SERVICE_UNAVAILABLE:
      return ResponseErrorsEnum.SERVICE_UNAVAILABLE;
    case ResponseCodesEnum.GATEWAY_TIMEOUT:
      return ResponseErrorsEnum.GATEWAY_TIMEOUT;
    case ResponseCodesEnum.UNHANDLED_ERROR_1:
      return ResponseErrorsEnum.UNHANDLED_ERROR_1;
    case ResponseCodesEnum.UNHANDLED_ERROR_2:
      return ResponseErrorsEnum.UNHANDLED_ERROR_2;
    case ResponseCodesEnum.UNHANDLED_ERROR_3:
      return ResponseErrorsEnum.UNHANDLED_ERROR_3;
    default:
      return ResponseErrorsEnum.UNHANDLED_ERROR;
  }
};

export const genericErrorMessages = {
  1: 'GenericErrorMessage.400',
  2: 'GenericErrorMessage.401',
  3: 'GenericErrorMessage.403',
  4: 'GenericErrorMessage.404',
  5: 'GenericErrorMessage.405',
  6: 'GenericErrorMessage.408',
  7: 'GenericErrorMessage.409',
  8: 'GenericErrorMessage.410',
  9: 'GenericErrorMessage.500',
  10: 'GenericErrorMessage.501',
  11: 'GenericErrorMessage.502',
  12: 'GenericErrorMessage.503',
  13: 'GenericErrorMessage.unknown',
  14: 'GenericErrorMessage.504',
};

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FreeSubmissionComplete } from "./free-submission-complete";
import { ReportComplete } from "./report-complete";
import { SubmissionComplete } from "./submission-complete";
import { TaskSubmissionComplete } from "./task-submission-complete";
import { UserStubComplete } from "./user-stub-complete";
/**
 *
 * @export
 * @interface TextComplete
 */
export interface TextComplete {
  /**
   *
   * @type {string}
   * @memberof TextComplete
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TextComplete
   */
  category: TextCompleteCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof TextComplete
   */
  classId: string;
  /**
   *
   * @type {string}
   * @memberof TextComplete
   */
  title: string;
  /**
   *
   * @type {Date}
   * @memberof TextComplete
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof TextComplete
   */
  updated?: Date;
  /**
   *
   * @type {UserStubComplete}
   * @memberof TextComplete
   */
  author?: UserStubComplete;
  /**
   *
   * @type {string}
   * @memberof TextComplete
   */
  content: string;
  /**
   *
   * @type {number}
   * @memberof TextComplete
   */
  wordCount?: number;
  /**
   *
   * @type {SubmissionComplete}
   * @memberof TextComplete
   */
  submission: FreeSubmissionComplete | TaskSubmissionComplete;
  /**
   * Aggregated responses from text ratings.
   * @type {{ [key: string]: number; }}
   * @memberof TextComplete
   */
  responses?: { [key: string]: number };
  /**
   *
   * @type {Array<ReportComplete>}
   * @memberof TextComplete
   */
  reports?: Array<ReportComplete>;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof TextComplete
   */
  noteReports?: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof TextComplete
   */
  access?: TextCompleteAccessEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TextCompleteCategoryEnum {
  Story = "story",
  Poem = "poem",
  Joke = "joke",
  Letter = "letter",
  Email = "email",
  Free = "free",
  NonFictional = "non_fictional",
}
/**
 * @export
 * @enum {string}
 */
export enum TextCompleteAccessEnum {
  Author = "author",
  Reviewer = "reviewer",
  Class = "class",
}

import { SchoolClass, SchoolClassComplete } from '@skribi/school-utils/src/resources';
import { useSchoolClassActive } from '@skribi/school-utils/src/store/SchoolClass';
import { LinkBuilder } from '@skribi/shared/src/resources/routes/LinkBuilder';
import { TransparentLink } from 'components/Link';
import { IconClose } from 'icons';
import { AlignmentEnum } from 'layouts/OuterWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { Wrapper } from './style';

export function NewTask() {
  const { t } = useTranslation();

  const loadedClass: SchoolClass | SchoolClassComplete | null = useSchoolClassActive();

  return (
    <Wrapper $alignment={AlignmentEnum.RIGHT}>
      <TransparentLink to={LinkBuilder.TeacherTasksOverview(loadedClass?.id!)}>
        <IconClose title={t(messages.close)} />
      </TransparentLink>
    </Wrapper>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import { createQueryString } from "@skribi/shared/src/utils/helpers";
import { BaseAPI, BASE_PATH, RequestArgs, RequiredError } from "../base";
import {
  AggregateQueryResponseTextAuthorAggregateBasic,
  TextComplete,
  TextPartial,
} from "../models";

/**
 * TextsApi - axios parameter creator
 * @export
 */
export const TextsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be a member of the associated class ## Postconditions * A text is created.
     * @summary Creates a text
     * @param {TextComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createText: async (
      body: TextComplete,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createText."
        );
      }
      const localVarPath = `/texts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The specified text is deleted.
     * @summary Deletes a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteText: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling deleteText."
        );
      }
      const localVarPath = `/texts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the text, if the text is published * Authenticated user must be either the author or a reviewer of the text or must be allow tho manged the class associated to the text
     * @summary Gets the complete representation of a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getText: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getText."
        );
      }
      const localVarPath = `/texts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = createQueryString(
        localVarQueryParameter,
        new URLSearchParams(localVarUrlObj.search)
      );

      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each text
     * @summary Queries basic representations of texts
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [author] Repeat to filter multiple user IDs.
     * @param {Array<string>} [access] Repeat to filter multiple access values.
     * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
     * @param {Array<string>} [submissionStatus] Repeat to filter multiple status values.
     * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
     * @param {Array<string>} [submissionReviewer] Repeat to filter multiple user IDs.
     * @param {Array<string>} [category] Repeat to filter multiple text categories.
     * @param {string} [search] Searches in title
     * @param {Array<string>} [reported] Filters reported texts or texts with reported notes. Repeat to filter by multiple report targets.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {boolean} [aggregate] If true, submissions are additionally aggregated by author.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queryTexts: async (
      _class?: Array<string>,
      author?: Array<string>,
      access?: Array<string>,
      submissionType?: Array<string>,
      submissionStatus?: Array<string>,
      submissionTask?: Array<string>,
      submissionReviewer?: Array<string>,
      category?: Array<string>,
      search?: string,
      reported?: Array<string>,
      offset?: number,
      limit?: number,
      aggregate?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/texts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (_class) {
        localVarQueryParameter["class"] = _class;
      }

      if (author) {
        localVarQueryParameter["author"] = author;
      }

      if (access) {
        localVarQueryParameter["access"] = access;
      }

      if (submissionType) {
        localVarQueryParameter["submission.type"] = submissionType;
      }

      if (submissionStatus) {
        localVarQueryParameter["submission.status"] = submissionStatus;
      }

      if (submissionTask) {
        localVarQueryParameter["submission.task"] = submissionTask;
      }

      if (submissionReviewer) {
        localVarQueryParameter["submission.reviewer"] = submissionReviewer;
      }

      if (category) {
        localVarQueryParameter["category"] = category;
      }

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (reported) {
        localVarQueryParameter["reported"] = reported;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (aggregate !== undefined) {
        localVarQueryParameter["aggregate"] = aggregate;
      }

      const query = createQueryString(
        localVarQueryParameter,
        new URLSearchParams(localVarUrlObj.search)
      );

      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The title, content or other properties of a text are updated.
     * @summary Updates title, content and other properties of a text
     * @param {string} id
     * @param {TextPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateText: async (
      id: string,
      body?: TextPartial,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling updateText."
        );
      }
      const localVarPath = `/texts/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "*/*";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TextsApi - functional programming interface
 * @export
 */
export const TextsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be a member of the associated class ## Postconditions * A text is created.
     * @summary Creates a text
     * @param {TextComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createText(
      body: TextComplete,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TextComplete>>
    > {
      const localVarAxiosArgs = await TextsApiAxiosParamCreator(
        configuration
      ).createText(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The specified text is deleted.
     * @summary Deletes a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteText(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await TextsApiAxiosParamCreator(
        configuration
      ).deleteText(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the text, if the text is published * Authenticated user must be either the author or a reviewer of the text or must be allow tho manged the class associated to the text
     * @summary Gets the complete representation of a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getText(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TextComplete>>
    > {
      const localVarAxiosArgs = await TextsApiAxiosParamCreator(
        configuration
      ).getText(id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each text
     * @summary Queries basic representations of texts
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [author] Repeat to filter multiple user IDs.
     * @param {Array<string>} [access] Repeat to filter multiple access values.
     * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
     * @param {Array<string>} [submissionStatus] Repeat to filter multiple status values.
     * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
     * @param {Array<string>} [submissionReviewer] Repeat to filter multiple user IDs.
     * @param {Array<string>} [category] Repeat to filter multiple text categories.
     * @param {string} [search] Searches in title
     * @param {Array<string>} [reported] Filters reported texts or texts with reported notes. Repeat to filter by multiple report targets.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {boolean} [aggregate] If true, submissions are additionally aggregated by author.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryTexts(
      _class?: Array<string>,
      author?: Array<string>,
      access?: Array<string>,
      submissionType?: Array<string>,
      submissionStatus?: Array<string>,
      submissionTask?: Array<string>,
      submissionReviewer?: Array<string>,
      category?: Array<string>,
      search?: string,
      reported?: Array<string>,
      offset?: number,
      limit?: number,
      aggregate?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<
        AxiosResponse<AggregateQueryResponseTextAuthorAggregateBasic>
      >
    > {
      const localVarAxiosArgs = await TextsApiAxiosParamCreator(
        configuration
      ).queryTexts(
        _class,
        author,
        access,
        submissionType,
        submissionStatus,
        submissionTask,
        submissionReviewer,
        category,
        search,
        reported,
        offset,
        limit,
        aggregate,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The title, content or other properties of a text are updated.
     * @summary Updates title, content and other properties of a text
     * @param {string} id
     * @param {TextPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateText(
      id: string,
      body?: TextPartial,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TextComplete>>
    > {
      const localVarAxiosArgs = await TextsApiAxiosParamCreator(
        configuration
      ).updateText(id, body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TextsApi - factory interface
 * @export
 */
export const TextsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be a member of the associated class ## Postconditions * A text is created.
     * @summary Creates a text
     * @param {TextComplete} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createText(
      body: TextComplete,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TextComplete>> {
      return TextsApiFp(configuration)
        .createText(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The specified text is deleted.
     * @summary Deletes a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteText(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<void>> {
      return TextsApiFp(configuration)
        .deleteText(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be member of the class associated to the text, if the text is published * Authenticated user must be either the author or a reviewer of the text or must be allow tho manged the class associated to the text
     * @summary Gets the complete representation of a text
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getText(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TextComplete>> {
      return TextsApiFp(configuration)
        .getText(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must be member of the classes associated to each text
     * @summary Queries basic representations of texts
     * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
     * @param {Array<string>} [author] Repeat to filter multiple user IDs.
     * @param {Array<string>} [access] Repeat to filter multiple access values.
     * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
     * @param {Array<string>} [submissionStatus] Repeat to filter multiple status values.
     * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
     * @param {Array<string>} [submissionReviewer] Repeat to filter multiple user IDs.
     * @param {Array<string>} [category] Repeat to filter multiple text categories.
     * @param {string} [search] Searches in title
     * @param {Array<string>} [reported] Filters reported texts or texts with reported notes. Repeat to filter by multiple report targets.
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {boolean} [aggregate] If true, submissions are additionally aggregated by author.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queryTexts(
      _class?: Array<string>,
      author?: Array<string>,
      access?: Array<string>,
      submissionType?: Array<string>,
      submissionStatus?: Array<string>,
      submissionTask?: Array<string>,
      submissionReviewer?: Array<string>,
      category?: Array<string>,
      search?: string,
      reported?: Array<string>,
      offset?: number,
      limit?: number,
      aggregate?: boolean,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AggregateQueryResponseTextAuthorAggregateBasic>> {
      return TextsApiFp(configuration)
        .queryTexts(
          _class,
          author,
          access,
          submissionType,
          submissionStatus,
          submissionTask,
          submissionReviewer,
          category,
          search,
          reported,
          offset,
          limit,
          aggregate,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The title, content or other properties of a text are updated.
     * @summary Updates title, content and other properties of a text
     * @param {string} id
     * @param {TextPartial} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateText(
      id: string,
      body?: TextPartial,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TextComplete>> {
      return TextsApiFp(configuration)
        .updateText(id, body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TextsApi - object-oriented interface
 * @export
 * @class TextsApi
 * @extends {BaseAPI}
 */
export class TextsApi extends BaseAPI {
  /**
   * ## Preconditions * The ID of the authenticated user must be equal to the author's ID * Authenticated user must be a member of the associated class ## Postconditions * A text is created.
   * @summary Creates a text
   * @param {TextComplete} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextsApi
   */
  public async createText(
    body: TextComplete,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TextComplete>> {
    return TextsApiFp(this.configuration)
      .createText(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The specified text is deleted.
   * @summary Deletes a text
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextsApi
   */
  public async deleteText(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return TextsApiFp(this.configuration)
      .deleteText(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be member of the class associated to the text, if the text is published * Authenticated user must be either the author or a reviewer of the text or must be allow tho manged the class associated to the text
   * @summary Gets the complete representation of a text
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextsApi
   */
  public async getText(
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TextComplete>> {
    return TextsApiFp(this.configuration)
      .getText(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must be member of the classes associated to each text
   * @summary Queries basic representations of texts
   * @param {Array<string>} [_class] Repeat to filter multiple class IDs. Defaults to the classes the user is member of.
   * @param {Array<string>} [author] Repeat to filter multiple user IDs.
   * @param {Array<string>} [access] Repeat to filter multiple access values.
   * @param {Array<string>} [submissionType] Repeat to filter multiple submission types.
   * @param {Array<string>} [submissionStatus] Repeat to filter multiple status values.
   * @param {Array<string>} [submissionTask] Repeat to filter multiple task IDs.
   * @param {Array<string>} [submissionReviewer] Repeat to filter multiple user IDs.
   * @param {Array<string>} [category] Repeat to filter multiple text categories.
   * @param {string} [search] Searches in title
   * @param {Array<string>} [reported] Filters reported texts or texts with reported notes. Repeat to filter by multiple report targets.
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {boolean} [aggregate] If true, submissions are additionally aggregated by author.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextsApi
   */
  public async queryTexts(
    _class?: Array<string>,
    author?: Array<string>,
    access?: Array<string>,
    submissionType?: Array<string>,
    submissionStatus?: Array<string>,
    submissionTask?: Array<string>,
    submissionReviewer?: Array<string>,
    category?: Array<string>,
    search?: string,
    reported?: Array<string>,
    offset?: number,
    limit?: number,
    aggregate?: boolean,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AggregateQueryResponseTextAuthorAggregateBasic>> {
    return TextsApiFp(this.configuration)
      .queryTexts(
        _class,
        author,
        access,
        submissionType,
        submissionStatus,
        submissionTask,
        submissionReviewer,
        category,
        search,
        reported,
        offset,
        limit,
        aggregate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   * ## Preconditions * Authenticated user must either be the author of the text or must be allowed to manage the class associated to the text ## Postconditions * The title, content or other properties of a text are updated.
   * @summary Updates title, content and other properties of a text
   * @param {string} id
   * @param {TextPartial} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TextsApi
   */
  public async updateText(
    id: string,
    body?: TextPartial,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TextComplete>> {
    return TextsApiFp(this.configuration)
      .updateText(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReportBasic } from './report-basic';
import { TextStubBasic } from './text-stub-basic';
import { UserInRoleBasic } from './user-in-role-basic';
/**
 * 
 * @export
 * @interface NoteBasic
 */
export interface NoteBasic {
    /**
     * 
     * @type {string}
     * @memberof NoteBasic
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteBasic
     */
    type: NoteBasicTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NoteBasic
     */
    classId: string;
    /**
     * 
     * @type {TextStubBasic}
     * @memberof NoteBasic
     */
    text: TextStubBasic;
    /**
     * 
     * @type {Date}
     * @memberof NoteBasic
     */
    created?: Date;
    /**
     * 
     * @type {UserInRoleBasic}
     * @memberof NoteBasic
     */
    user?: UserInRoleBasic;
    /**
     * 
     * @type {string}
     * @memberof NoteBasic
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof NoteBasic
     */
    status?: NoteBasicStatusEnum;
    /**
     * 
     * @type {Array<ReportBasic>}
     * @memberof NoteBasic
     */
    reports?: Array<ReportBasic>;
}

/**
    * @export
    * @enum {string}
    */
export enum NoteBasicTypeEnum {
    Comment = 'comment',
    Feedback = 'feedback'
}
/**
    * @export
    * @enum {string}
    */
export enum NoteBasicStatusEnum {
    Submitted = 'submitted',
    Done = 'done'
}


import { genericErrorMessages, ResponseErrorsEnum } from '../helper';

export const GetSchoolClassErrorMessage = (error: ResponseErrorsEnum): string => {
  switch (error) {
    case ResponseErrorsEnum.UNHANDLED_ERROR:
      return 'Teacher.ClassEditor.ClassInfo.errorAtPdf';
    default:
      return genericErrorMessages[error];
  }
};

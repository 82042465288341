import styled, { css } from 'styled-components';
import { FontButton, FontButtonSecondary } from 'styles';

export const ButtonBaseCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  column-gap: ${p => p.theme.toRem(7)};
  border-radius: 100px;
  color: ${props => props.theme.color.primary_black};
  border: none;
`;

const DisabledButtonStyle = css`
  &:disabled {
    opacity: 0.5;
  }
`;

const ButtonBase = styled.button`
  ${ButtonBaseCss};
  padding: ${p => `${p.theme.toRem(10)} ${p.theme.toRem(20)}`};

  ${FontButton};

  ${DisabledButtonStyle};
`;

export const ButtonSecondaryBase = styled.button`
  ${ButtonBaseCss};
  padding: ${p => `${p.theme.toRem(8)} ${p.theme.toRem(16)}`};

  ${FontButtonSecondary};

  ${DisabledButtonStyle};
`;

export const ButtonLabelBlack = styled.span`
  ${FontButton};
  color: ${props => props.theme.color.primary_black};
`;

export const MandarinButton = styled(ButtonBase)`
  background: ${p => p.theme.color.primary_mandarin};
`;

export const WhiteButton = styled(ButtonBase)`
  background: ${p => p.theme.color.primary_white};
`;

interface WhiteButtonSecondaryProps {
  $isSelected?: boolean;
}

export const WhiteButtonSecondary = styled(ButtonSecondaryBase)<WhiteButtonSecondaryProps>`
  background: ${p => p.theme.color.primary_white};
  border: ${p => (p.$isSelected ? `1px solid ${p.theme.color.primary_black}` : 'none')};
`;

export const TurquoiseButton = styled(ButtonBase)`
  background: ${p => p.theme.color.primary_turquoise};
`;

export const BlackButton = styled(ButtonBase)`
  background: ${p => p.theme.color.primary_black};
`;

export const MandarinButtonSecondary = styled(ButtonSecondaryBase)`
  background: ${p => p.theme.color.primary_mandarin};
`;

export const BlackButtonSecondary = styled(ButtonSecondaryBase)`
  background: ${p => p.theme.color.primary_black};
  color: ${p => p.theme.color.primary_white};
`;

export const FullWidthBlackButtonSecondary = styled(ButtonSecondaryBase)`
  background: ${p => p.theme.color.primary_black};
  color: ${p => p.theme.color.primary_white};
  width: 100%;
`;

const BaseRoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.theme.toRem(30)};
  height: ${p => p.theme.toRem(30)};
  border: none;
  border-radius: 100px;
  padding: ${p => p.theme.toRem(7)};

  svg {
    min-width: ${p => p.theme.toRem(23)};
    min-height: ${p => p.theme.toRem(23)};
  }
`;

interface MangoRoundButtonProps {
  $isActive?: boolean;
}

export const MangoRoundButton = styled(BaseRoundButton)<MangoRoundButtonProps>`
  background: ${p => (p.$isActive ? p.theme.color.secondary_mandarin_dark : p.theme.color.primary_mango)};

  &:hover {
    background: ${p => p.theme.color.secondary_mandarin_dark};
  }
`;

export const WhiteRoundButton = styled(BaseRoundButton)`
  background: ${p => p.theme.color.primary_white};
`;

export const TransparentButton = styled(ButtonBase)`
  padding: 0;
  margin: 0;
  background: transparent;
`;

export const TextEditorToolbarButton = styled(BaseRoundButton)`
  background: ${p => p.theme.color.primary_mango};
  width: ${p => p.theme.toRem(40)};
  height: ${p => p.theme.toRem(40)};

  svg {
    min-width: ${p => p.theme.toRem(30)};
    min-height: ${p => p.theme.toRem(30)};
  }
`;

export const ActiveTextEditorToolbarButton = styled(TextEditorToolbarButton)`
  background: ${p => p.theme.color.secondary_mandarin_dark};
`;

export const InvisibleButton = styled.button`
  display: none;
`;

export const CitrusRoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.theme.toRem(36)};
  height: ${p => p.theme.toRem(36)};
  border-radius: 50%;
  background: ${p => p.theme.color.secondary_citrus};
  border: none;
`;

export const BlackRoundedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.theme.toRem(44)};
  height: ${p => p.theme.toRem(44)};
  border-radius: ${p => p.theme.toRem(100)};
  border: none;

  background: ${p => p.theme.color.primary_black};
`;

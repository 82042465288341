import { TaskBasicTextCategoryEnum } from '@skribi/openapi/src/text-api';

export const textToTaskType = (text: string): TaskBasicTextCategoryEnum => {
  switch (text.toLocaleLowerCase()) {
    case 'geschichte':
      return TaskBasicTextCategoryEnum.Story;
    default:
      return TaskBasicTextCategoryEnum.Free;
  }
};

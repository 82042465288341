/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface SchoolComplete
 */
export interface SchoolComplete {
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof SchoolComplete
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  number: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolComplete
   */
  email?: string;
}

/**
 * Check if a given object implements the SchoolComplete interface.
 */
export function instanceOfSchoolComplete(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'number' in value;

  return isInstance;
}

export function SchoolCompleteFromJSON(json: any): SchoolComplete {
  return SchoolCompleteFromJSONTyped(json, false);
}

export function SchoolCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolComplete {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
    number: json['number'],
    type: !exists(json, 'type') ? undefined : json['type'],
    address: !exists(json, 'address') ? undefined : json['address'],
    postCode: !exists(json, 'post_code') ? undefined : json['post_code'],
    locality: !exists(json, 'locality') ? undefined : json['locality'],
    telephone: !exists(json, 'telephone') ? undefined : json['telephone'],
    email: !exists(json, 'email') ? undefined : json['email'],
  };
}

export function SchoolCompleteToJSON(value?: SchoolComplete | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    created: value.created === undefined ? undefined : value.created.toISOString(),
    number: value.number,
    type: value.type,
    address: value.address,
    post_code: value.postCode,
    locality: value.locality,
    telephone: value.telephone,
    email: value.email,
  };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserInRole } from './user-in-role';
/**
 * 
 * @export
 * @interface NoteUserTypeAggregate
 */
export interface NoteUserTypeAggregate {
    /**
     * 
     * @type {UserInRole}
     * @memberof NoteUserTypeAggregate
     */
    user?: UserInRole;
    /**
     * 
     * @type {string}
     * @memberof NoteUserTypeAggregate
     */
    type?: NoteUserTypeAggregateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NoteUserTypeAggregate
     */
    count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum NoteUserTypeAggregateTypeEnum {
    Comment = 'comment',
    Feedback = 'feedback'
}


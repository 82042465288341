/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  StudentBasic,
  StudentComplete,
  StudentCreated,
  StudentCredential,
  StudentExport,
  StudentPartial,
} from '../models';
import {
  StudentBasicFromJSON,
  StudentCompleteFromJSON,
  StudentCreatedFromJSON,
  StudentCreatedToJSON,
  StudentCredentialFromJSON,
  StudentExportFromJSON,
  StudentPartialToJSON,
} from '../models';
import * as runtime from '../runtime';

export interface DeleteRequest {
  studentId: string;
  deleteNow?: boolean;
}

export interface CreateStudentInSchoolClassRequest {
  classId: string;
  studentCreated?: StudentCreated;
}

export interface DeleteStudentProfilePropertyRequest {
  studentId: string;
  property: string;
}

export interface ExportStudentsOfSchoolClassRequest {
  classId: string;
}

export interface GetStudentRequest {
  studentId: string;
}

export interface GetStudentCredentialRequest {
  studentId: string;
}

export interface GetStudentsOfSchoolClassRequest {
  classId: string;
  includeCredentials?: boolean;
}

export interface ReplaceStudentCredentialRequest {
  studentId: string;
}

export interface UpdateRequest {
  studentId: string;
  studentPartial?: StudentPartial;
}

export interface UpdateStudentProfilePropertiesRequest {
  studentId: string;
  requestBody: { [key: string]: string | undefined };
}

export interface UpdateStudentProfilePropertyRequest {
  studentId: string;
  property: string;
  body: string;
}

/**
 *
 */
export class StudentsApi extends runtime.BaseAPI {
  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student ## Postconditions * The student user is deleted.
   * Deletes a student user
   */
  async _deleteRaw(
    requestParameters: DeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling _delete.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.deleteNow !== undefined) {
      queryParameters['delete_now'] = requestParameters.deleteNow;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/students/{studentId}`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student ## Postconditions * The student user is deleted.
   * Deletes a student user
   */
  async _delete(
    requestParameters: DeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this._deleteRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage students of their class or any student ## Postconditions * A student user is created and assigned to the class.
   * Creates a student user in a school class
   */
  async createStudentInSchoolClassRaw(
    requestParameters: CreateStudentInSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentCreated>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling createStudentInSchoolClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/classes/{classId}/students`.replace(
          `{${'classId'}}`,
          encodeURIComponent(String(requestParameters.classId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: StudentCreatedToJSON(requestParameters.studentCreated),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => StudentCreatedFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage students of their class or any student ## Postconditions * A student user is created and assigned to the class.
   * Creates a student user in a school class
   */
  async createStudentInSchoolClass(
    requestParameters: CreateStudentInSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentCreated> {
    const response = await this.createStudentInSchoolClassRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student. ## Postconditions * The property in the student\'s profile is deleted.
   * Deletes a property in a student\'s profile
   */
  async deleteStudentProfilePropertyRaw(
    requestParameters: DeleteStudentProfilePropertyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling deleteStudentProfileProperty.',
      );
    }

    if (requestParameters.property === null || requestParameters.property === undefined) {
      throw new runtime.RequiredError(
        'property',
        'Required parameter requestParameters.property was null or undefined when calling deleteStudentProfileProperty.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/students/{studentId}/profile/{property}`
          .replace(`{${'studentId'}}`, encodeURIComponent(String(requestParameters.studentId)))
          .replace(`{${'property'}}`, encodeURIComponent(String(requestParameters.property))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student. ## Postconditions * The property in the student\'s profile is deleted.
   * Deletes a property in a student\'s profile
   */
  async deleteStudentProfileProperty(
    requestParameters: DeleteStudentProfilePropertyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteStudentProfilePropertyRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any student
   * Exports the students of a school class
   */
  async exportStudentsOfSchoolClassRaw(
    requestParameters: ExportStudentsOfSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<StudentExport>>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling exportStudentsOfSchoolClass.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}/students/export`.replace(
          `{${'classId'}}`,
          encodeURIComponent(String(requestParameters.classId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(StudentExportFromJSON));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage any student
   * Exports the students of a school class
   */
  async exportStudentsOfSchoolClass(
    requestParameters: ExportStudentsOfSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<StudentExport>> {
    const response = await this.exportStudentsOfSchoolClassRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student.
   * Gets a students user
   */
  async getStudentRaw(
    requestParameters: GetStudentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentComplete>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling getStudent.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/students/{studentId}`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => StudentCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student.
   * Gets a students user
   */
  async getStudent(
    requestParameters: GetStudentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentComplete> {
    const response = await this.getStudentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student
   * Gets a student\'s credential
   */
  async getStudentCredentialRaw(
    requestParameters: GetStudentCredentialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentCredential>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling getStudentCredential.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/students/{studentId}/credential`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => StudentCredentialFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student
   * Gets a student\'s credential
   */
  async getStudentCredential(
    requestParameters: GetStudentCredentialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentCredential> {
    const response = await this.getStudentCredentialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be a member of the class or must be allowed to manage any student * When include_credentials is set to true, authenticated user must be allowed to manage students
   * Gets the students of a school class
   */
  async getStudentsOfSchoolClassRaw(
    requestParameters: GetStudentsOfSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<StudentBasic>>> {
    if (requestParameters.classId === null || requestParameters.classId === undefined) {
      throw new runtime.RequiredError(
        'classId',
        'Required parameter requestParameters.classId was null or undefined when calling getStudentsOfSchoolClass.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.includeCredentials !== undefined) {
      queryParameters['include_credentials'] = requestParameters.includeCredentials;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/classes/{classId}/students`.replace(
          `{${'classId'}}`,
          encodeURIComponent(String(requestParameters.classId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(StudentBasicFromJSON));
  }

  /**
   * ## Preconditions * Authenticated user must be a member of the class or must be allowed to manage any student * When include_credentials is set to true, authenticated user must be allowed to manage students
   * Gets the students of a school class
   */
  async getStudentsOfSchoolClass(
    requestParameters: GetStudentsOfSchoolClassRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<StudentBasic>> {
    const response = await this.getStudentsOfSchoolClassRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student ## Postconditions * The student\'s credential is replaced by a random value.
   * Replaces a student\'s credential
   */
  async replaceStudentCredentialRaw(
    requestParameters: ReplaceStudentCredentialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentCredential>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling replaceStudentCredential.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/students/{studentId}/credential`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => StudentCredentialFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student ## Postconditions * The student\'s credential is replaced by a random value.
   * Replaces a student\'s credential
   */
  async replaceStudentCredential(
    requestParameters: ReplaceStudentCredentialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentCredential> {
    const response = await this.replaceStudentCredentialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student ## Postconditions * The first name or last name of the student is updated.
   * Updates a student user
   */
  async updateRaw(
    requestParameters: UpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentComplete>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling update.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/students/{studentId}`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: StudentPartialToJSON(requestParameters.studentPartial),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, jsonValue => StudentCompleteFromJSON(jsonValue));
  }

  /**
   * ## Preconditions * Authenticated user must be allowed to manage the class of the student or any student ## Postconditions * The first name or last name of the student is updated.
   * Updates a student user
   */
  async update(
    requestParameters: UpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentComplete> {
    const response = await this.updateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student. ## Postconditions * The student\'s profile is updated.
   * Updates a student\'s profile
   */
  async updateStudentProfilePropertiesRaw(
    requestParameters: UpdateStudentProfilePropertiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling updateStudentProfileProperties.',
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling updateStudentProfileProperties.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/students/{studentId}/profile`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student. ## Postconditions * The student\'s profile is updated.
   * Updates a student\'s profile
   */
  async updateStudentProfileProperties(
    requestParameters: UpdateStudentProfilePropertiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateStudentProfilePropertiesRaw(requestParameters, initOverrides);
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student. ## Postconditions * The property in the student\'s profile is updated.
   * Updates a property in a student\'s profile
   */
  async updateStudentProfilePropertyRaw(
    requestParameters: UpdateStudentProfilePropertyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling updateStudentProfileProperty.',
      );
    }

    if (requestParameters.property === null || requestParameters.property === undefined) {
      throw new runtime.RequiredError(
        'property',
        'Required parameter requestParameters.property was null or undefined when calling updateStudentProfileProperty.',
      );
    }

    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling updateStudentProfileProperty.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/students/{studentId}/profile/{property}`
          .replace(`{${'studentId'}}`, encodeURIComponent(String(requestParameters.studentId)))
          .replace(`{${'property'}}`, encodeURIComponent(String(requestParameters.property))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * ## Preconditions * Authenticated user must match the student\'s ID or must be allowed to manage the class of the student. ## Postconditions * The property in the student\'s profile is updated.
   * Updates a property in a student\'s profile
   */
  async updateStudentProfileProperty(
    requestParameters: UpdateStudentProfilePropertyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateStudentProfilePropertyRaw(requestParameters, initOverrides);
  }
}

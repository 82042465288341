import { Configuration, NotesApi } from '@skribi/openapi/src/text-api';
import {
  NoteBasic,
  NoteBasicStatusEnum,
  NoteComplete,
  QueryResponseNoteBasic,
} from '@skribi/openapi/src/text-api/models';
import {
  getResposeErrorTypeByStatusCode,
  ResponseCodesEnum,
  ResponseErrorsEnum,
} from '@skribi/shared/src/resources/error';
import { getAppConfigParam } from '@skribi/shared/src/utils/helpers';
import { AxiosResponse } from 'axios';
import { getAxiosRequestConfig } from 'utils/helpers';
import { NoteBasicFromJson, NoteCompleteFromJson, QueryResponseNoteBasicFromJson } from 'utils/parser';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import {
  CreateNoteParams,
  DeleteNoteParams,
  NoteState,
  NoteStateActions,
  QueryNotesParams,
  UpdateNoteStatus,
} from './types';

const taskApiBaseconfig: Configuration = {
  basePath: getAppConfigParam('textApiUrl'),
};

const useNote = create<NoteState>()((set, get) => ({
  isRequestInProgress: false,
  error: null,
  notes: null,
  loadedNote: null,
  noteStatusFilter: NoteBasicStatusEnum.Submitted,
  notecounter: null,
  completeNotes: null,
  actions: {
    createNote: async (
      requestParams: CreateNoteParams & { onEnd: (ok: NoteComplete | null) => void },
    ): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });

        const response: AxiosResponse<NoteComplete> = await new NotesApi(taskApiBaseconfig).createNote(
          data.body,
          await getAxiosRequestConfig(),
        );

        set({ isRequestInProgress: false });

        if (response.data) {
          onEnd(response.data);
        }
      } catch (error: any) {
        console.warn('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(null);
      }
    },

    updateNoteStatus: async (requestParams: UpdateNoteStatus & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        const { onEnd, ...data } = requestParams;
        set({ isRequestInProgress: true });

        await new NotesApi(taskApiBaseconfig).updateNoteStatus(data.id, data.body, await getAxiosRequestConfig());

        set({ isRequestInProgress: false });
        onEnd(true);
      } catch (error: any) {
        console.warn('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(false);
      }
    },

    queryNotes: async (requestParams: QueryNotesParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });

        const response: AxiosResponse<QueryResponseNoteBasic> = await new NotesApi(taskApiBaseconfig).queryNotes(
          requestParams._class,
          requestParams.type,
          requestParams.status,
          requestParams.user,
          requestParams.text,
          requestParams.textAuthor,
          requestParams.reported,
          requestParams.offset,
          requestParams.limit,
          await getAxiosRequestConfig(),
        );

        if (requestParams.updateCounter) {
          set({
            isRequestInProgress: false,
            notes: response.data.results?.map(json => NoteBasicFromJson(json)),
            notecounter: QueryResponseNoteBasicFromJson(response.data).totalCount,
          });
        } else {
          set({ isRequestInProgress: false, notes: response.data.results?.map(json => NoteBasicFromJson(json)) });
        }
      } catch (error: any) {
        console.warn('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    updateNoteFilter: (requestParams: NoteBasicStatusEnum | null): void => {
      set({ noteStatusFilter: requestParams });
    },

    deleteNote: async (requestParams: DeleteNoteParams & { onEnd: (ok: boolean) => void }): Promise<void> => {
      try {
        set({ isRequestInProgress: true });
        await new NotesApi(taskApiBaseconfig).deleteNote(requestParams.id, await getAxiosRequestConfig());

        set({ isRequestInProgress: false });
        requestParams.onEnd(true);
      } catch (error: any) {
        console.warn('deleteNote function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
        requestParams.onEnd(true);
      }
    },

    queryCompleteNotes: async (requestParams: QueryNotesParams): Promise<void> => {
      try {
        set({ isRequestInProgress: true });
        const axiosConfig = await getAxiosRequestConfig();
        const response: AxiosResponse<QueryResponseNoteBasic> = await new NotesApi(taskApiBaseconfig).queryNotes(
          requestParams._class,
          requestParams.type,
          requestParams.status,
          requestParams.user,
          requestParams.text,
          requestParams.textAuthor,
          requestParams.reported,
          requestParams.offset,
          requestParams.limit,
          axiosConfig,
        );

        if (response.data.results) {
          const noteCompleteResponse: Array<AxiosResponse<NoteComplete>> = await Promise.all(
            response.data.results.map(el => {
              return new NotesApi(taskApiBaseconfig).getNote(el.id!, axiosConfig);
            }),
          );

          const noteCompleteList: Array<NoteComplete> = noteCompleteResponse.map(el => NoteCompleteFromJson(el.data));

          if (requestParams.updateCounter) {
            set({
              isRequestInProgress: false,
              completeNotes: noteCompleteList,
              notecounter: QueryResponseNoteBasicFromJson(response.data).totalCount,
            });
          } else {
            set({ isRequestInProgress: false, completeNotes: noteCompleteList });
          }
        }
      } catch (error: any) {
        console.warn('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    clearNotes: (): void => {
      try {
        set({ notes: null });
      } catch (error: any) {
        console.warn('createTask function error', error);
        set({
          error: getResposeErrorTypeByStatusCode(error?.response?.status ?? ResponseCodesEnum.UNHANDLED_ERROR),
          isRequestInProgress: false,
        });
      }
    },

    clearNoteError: (): void => {
      set({ error: null });
    },
  },
}));

export const useNoteIsRequestInProgress = (): boolean => useNote(state => state.isRequestInProgress);

export const useNoteNotes = (): Array<NoteBasic> | null => useNote(state => state.notes, shallow);

export const useNoteCompleteNotes = (): Array<NoteComplete> | null => useNote(state => state.completeNotes, shallow);

export const useNoteError = (): ResponseErrorsEnum | null => useNote(state => state.error);

export const useNoteActions = (): NoteStateActions => useNote(state => state.actions);

export const useNoteLoadedNote = (): NoteComplete | null => useNote(state => state.loadedNote);

export const useNoteNoteCounter = (): number | null => useNote(state => state.notecounter);

export const setNoteListState = (notelist: Array<NoteBasic> | null) => {
  useNote.setState({ notes: notelist });
};

export const useNoteNoteStatusFilter = (): NoteBasicStatusEnum | null => useNote(state => state.noteStatusFilter);

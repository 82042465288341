import { ErrorWatcher } from 'components/ErrorWatcher';
import { Navigation } from 'components/Navigation';
import { ToastMessageWatcher } from 'components/ToastMessageWatcher';
import { AlignmentEnum, OuterWrapper } from 'layouts/OuterWrapper';
import React from 'react';
import { NavigationType } from '../Navigation/types';
import { AppContainer, ContentContainer, Footer } from './style';

interface ContentWrapperProps {
  navigationType?: NavigationType;
  bgColor?: string;
}

export function ContentWrapper({ navigationType, bgColor, children }: React.PropsWithChildren<ContentWrapperProps>) {
  return (
    <AppContainer>
      <ContentContainer id="contentContainer" $bgColor={bgColor}>
        <ToastMessageWatcher />
        <OuterWrapper $alignment={AlignmentEnum.CENTER}>
          <ErrorWatcher />
        </OuterWrapper>
        {children}
      </ContentContainer>
      {!!navigationType && (
        <Footer>
          <Navigation navigationType={navigationType} />
        </Footer>
      )}
    </AppContainer>
  );
}

import React from 'react';
import { CustomRadio } from './style';

interface RadioProps extends React.ComponentProps<'button'> {
  checked: boolean;
}

export function Radio({ ...props }: RadioProps) {
  return <CustomRadio type="button" $isChecked={props.checked ?? false} {...props} />;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { Wrapper } from './style';

export function LoadingGif() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src="/assets/loading.gif" alt={t(messages.loading)} />
    </Wrapper>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * School Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 *
 * @export
 * @interface SchoolClassComplete
 */
export interface SchoolClassComplete {
  /**
   *
   * @type {string}
   * @memberof SchoolClassComplete
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolClassComplete
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof SchoolClassComplete
   */
  created?: Date;
  /**
   *
   * @type {number}
   * @memberof SchoolClassComplete
   */
  year?: number;
  /**
   *
   * @type {string}
   * @memberof SchoolClassComplete
   */
  teacherName?: string;
  /**
   *
   * @type {string}
   * @memberof SchoolClassComplete
   */
  access?: SchoolClassCompleteAccessEnum;
  /**
   *
   * @type {Set<string>}
   * @memberof SchoolClassComplete
   */
  levels?: Set<SchoolClassCompleteLevelsEnum>;
  /**
   *
   * @type {Set<number>}
   * @memberof SchoolClassComplete
   */
  grades?: Set<number>;
  /**
   *
   * @type {string}
   * @memberof SchoolClassComplete
   */
  userId?: string;
}

/**
 * @export
 */
export const SchoolClassCompleteAccessEnum = {
  Member: 'member',
  School: 'school',
} as const;
export type SchoolClassCompleteAccessEnum =
  (typeof SchoolClassCompleteAccessEnum)[keyof typeof SchoolClassCompleteAccessEnum];

/**
 * @export
 */
export const SchoolClassCompleteLevelsEnum = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const;
export type SchoolClassCompleteLevelsEnum =
  (typeof SchoolClassCompleteLevelsEnum)[keyof typeof SchoolClassCompleteLevelsEnum];

/**
 * Check if a given object implements the SchoolClassComplete interface.
 */
export function instanceOfSchoolClassComplete(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function SchoolClassCompleteFromJSON(json: any): SchoolClassComplete {
  return SchoolClassCompleteFromJSONTyped(json, false);
}

export function SchoolClassCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolClassComplete {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
    year: !exists(json, 'year') ? undefined : json['year'],
    teacherName: !exists(json, 'teacher_name') ? undefined : json['teacher_name'],
    access: !exists(json, 'access') ? undefined : json['access'],
    levels: !exists(json, 'levels') ? undefined : json['levels'],
    grades: !exists(json, 'grades') ? undefined : json['grades'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
  };
}

export function SchoolClassCompleteToJSON(value?: SchoolClassComplete | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    created: value.created === undefined ? undefined : value.created.toISOString(),
    year: value.year,
    teacher_name: value.teacherName,
    access: value.access,
    levels: value.levels,
    grades: value.grades,
    user_id: value.userId,
  };
}

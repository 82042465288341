/* tslint:disable */
/* eslint-disable */
/**
 * Skribi Text Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TextStub } from './text-stub';
import { UserStub } from './user-stub';
/**
 * 
 * @export
 * @interface TextRating
 */
export interface TextRating {
    /**
     * 
     * @type {string}
     * @memberof TextRating
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TextRating
     */
    classId?: string;
    /**
     * 
     * @type {TextStub}
     * @memberof TextRating
     */
    text?: TextStub;
    /**
     * 
     * @type {UserStub}
     * @memberof TextRating
     */
    user?: UserStub;
    /**
     * 
     * @type {Date}
     * @memberof TextRating
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof TextRating
     */
    response: TextRatingResponseEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TextRatingResponseEnum {
    Impressed = 'impressed',
    Surprised = 'surprised',
    Amused = 'amused',
    Pensive = 'pensive',
    Sad = 'sad'
}


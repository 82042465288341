import styled from 'styled-components';
import { FontBodyBold } from 'styles';

export const ErrorAlertWrapper = styled.div`
  display: flex;
  column-gap: ${p => p.theme.toRem(40)};
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  background-color: ${props => props.theme.color.red_error_alert_bg};
  width: 100%;
  // AVOID THE TABS COMPONENT (THAT HAVE POSITION ABSOLUTE) TO OVERRIDE THE ELEMENT.
  z-index: 1;
  height: 100%;
  padding: 0 20px;
`;

export const ErrorAlertMessage = styled.span`
  ${FontBodyBold}
  color: ${props => props.theme.color.red_error_alert_font};
`;

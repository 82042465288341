import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { getLoadTaskTemplateByIdUrl, getQueryTaskTemplateUrl } from './api';
import {
  DrupalJsonResponse,
  LoadTemplateDrupalJSonResponse,
  TaskTemplate,
  TaskTemplateActions,
  TaskTemplateState,
} from './types';

const useTaskTemplate = create<TaskTemplateState>()((set, get) => ({
  isRequestInProgress: false,
  counter: 0,
  taskTemplateList: [],
  loadedTemplate: null,
  actions: {
    queryTaskTemplateList: async (requestParams: URLSearchParams, refreshCounter: boolean): Promise<void> => {
      try {
        set({ isRequestInProgress: true });
        const queryUrl: string = getQueryTaskTemplateUrl(requestParams);
        const response: DrupalJsonResponse = await (await fetch(queryUrl)).json();

        if (refreshCounter) {
          set({
            isRequestInProgress: false,
            taskTemplateList: response.data,
            counter: response.meta.count,
          });
        } else {
          set({ isRequestInProgress: false, taskTemplateList: response.data });
        }
      } catch (error: any) {}
    },

    loadTaskTemplateById: async (templateId: string): Promise<void> => {
      try {
        set({ isRequestInProgress: true });
        const queryUrl: string = getLoadTaskTemplateByIdUrl(templateId);
        const response: LoadTemplateDrupalJSonResponse = await (await fetch(queryUrl)).json();
        set({ isRequestInProgress: false, loadedTemplate: response.data });
      } catch (error: any) {}
    },

    clearLoadedTemplate: (): void => {
      set({ loadedTemplate: null });
    },
  },
}));

export const useTaskTemplateIsRequestInProgress = (): boolean => useTaskTemplate(state => state.isRequestInProgress);
export const useTaskTemplateList = (): Array<TaskTemplate> => useTaskTemplate(state => state.taskTemplateList, shallow);
export const useTaskTemplateActions = (): TaskTemplateActions => useTaskTemplate(state => state.actions);
export const useTaskTemplateCounter = (): number => useTaskTemplate(state => state.counter);
export const useTaskTemplateLoadedTemplate = (): TaskTemplate | null => useTaskTemplate(state => state.loadedTemplate);
export const useTaskTemplateGetOrderedGradesFromLoadedTemplate = (): number[] | null =>
  useTaskTemplate(state => {
    if (state.loadedTemplate) {
      return state.loadedTemplate.grade.map(grade => parseInt(grade.meta.name)).sort((a, b) => (a > b ? 1 : -1));
    }
    return null;
  });

import React, { InputHTMLAttributes, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { Input, InputWrapper, ValidationMessage } from './style';
import { InputAlignmentType } from './types';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  $textAlignment?: InputAlignmentType;
  $outlineColor?: string;
}

export const TextInput = React.forwardRef((props: TextInputProps, ref) => {
  const { t } = useTranslation();

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const textInputRef = useRef<HTMLInputElement>(null);

  const handleBlurEvent = useCallback(
    e => {
      if (props.onBlur) {
        props.onBlur(e);
      }

      if (textInputRef.current && textInputRef.current.value && props.required && showMessage) {
        setShowMessage(false);
      }
    },
    [showMessage, props],
  );

  const fieldProps: TextInputProps = { ...props, onBlur: handleBlurEvent };

  const checkValidation = useCallback(e => {
    if (textInputRef.current && textInputRef.current.value === '') {
      e.preventDefault();
      e.stopPropagation();

      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, []);

  const getValue = useCallback(() => {
    if (textInputRef.current) {
      return textInputRef.current.value;
    } else {
      return undefined;
    }
  }, []);

  useImperativeHandle(ref, () => ({ ...textInputRef.current, getValue }));

  return (
    <InputWrapper>
      <Input formNoValidate={true} ref={textInputRef} {...fieldProps} onInvalid={e => checkValidation(e)} />
      {showMessage && <ValidationMessage>{t(messages.fieldRequired)}</ValidationMessage>}
    </InputWrapper>
  );
});

import { useConfigTokenMinValidity } from '@skribi/shared/src/store/Config';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import Cookies from 'universal-cookie';
import { COOKIE_NAME, TokenData } from '../types';

const keycloak: Keycloak = new Keycloak('/keycloak.json');
const cookies = new Cookies();

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
export const keycloakInitOptions: KeycloakInitOptions = {
  /**
   * Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page).
   * If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
   */
  onLoad: 'check-sso',
  pkceMethod: 'S256',
  checkLoginIframe: false,
};

const getKeycloakInitOptions = (): KeycloakInitOptions => {
  const tokenData: TokenData | null = cookies.get(COOKIE_NAME) ?? null;
  if (tokenData) {
    return {
      ...keycloakInitOptions,
      token: tokenData.accessToken,
      refreshToken: tokenData.refreshToken,
    };
  }
  return keycloakInitOptions;
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onInitCallback
 */
const initKeycloak = (onInitCallback: any) => {
  keycloak
    .init(getKeycloakInitOptions())
    .then(authenticated => {
      if (authenticated && isStudent()) {
        setTokenCookie(keycloak.token, keycloak.refreshToken);
      }
      return onInitCallback(keycloak);
    })
    .catch(console.error);
};

const getToken = () => keycloak.token;
const isLoggedIn = () => !!keycloak.token;
const getKeycloak = () => keycloak;

const isStudent = (): boolean => {
  return keycloak.hasRealmRole('student');
};

const isTeacher = (): boolean => {
  return keycloak.hasRealmRole('teacher');
};

const getFirstClassId = (): string => {
  if (keycloak.tokenParsed && keycloak.tokenParsed.class_ids && keycloak.tokenParsed.class_ids.length > 0) {
    return keycloak.tokenParsed.class_ids[0];
  }
  return '';
};
const getClasses = (): Array<string> => {
  if (keycloak.tokenParsed?.class_ids && keycloak.tokenParsed.class_ids.length > 0) {
    return keycloak.tokenParsed.class_ids;
  }
  return [];
};
const getFirstSchoolId = (): string => {
  if (keycloak.tokenParsed?.school_ids && keycloak.tokenParsed.school_ids.length > 0) {
    return keycloak.tokenParsed.school_ids[0];
  }
  return '';
};

const getSchoolIdList = (): string[] => {
  if (keycloak.tokenParsed?.school_ids && keycloak.tokenParsed.school_ids.length > 0) {
    return keycloak.tokenParsed.school_ids;
  }
  return [];
};

const getUserId = (): string => {
  if (keycloak.tokenParsed && keycloak.tokenParsed.sub) {
    return keycloak.tokenParsed.sub;
  }
  return '';
};

const getUserName = (): string => {
  if (keycloak.tokenParsed && keycloak.tokenParsed.name) {
    return keycloak.tokenParsed.name;
  }
  return '';
};

/**
 * Add success callback
 * redirect to login page
 */
const updateToken = (onUpdateCallback?: any, minValidity?: number) => {
  return keycloak
    .updateToken(minValidity ?? useConfigTokenMinValidity())
    .then(refreshed => {
      if (refreshed && isStudent()) {
        setTokenCookie(keycloak.token, keycloak.refreshToken);
      }
      if (onUpdateCallback) {
        return onUpdateCallback(keycloak.token as string);
      }
      return null;
    })
    .catch();
};

/**
 * Force the update of the token.
 */
const forceUpdateToken = (onUpdateCallback?: any) => {
  updateToken(onUpdateCallback, Number.MAX_SAFE_INTEGER);
};

const setTokenCookie = (accessToken?: string, refreshToken?: string) => {
  cookies.remove(COOKIE_NAME);
  if (accessToken && refreshToken) {
    cookies.set(
      COOKIE_NAME,
      {
        accessToken: accessToken,
        refreshToken: refreshToken,
      },
      { path: '/', secure: true, sameSite: 'strict' },
    );
  }
};

export const KeycloakService = {
  initKeycloak,
  isLoggedIn,
  isStudent,
  isTeacher,
  getFirstClassId,
  getUserName,
  getUserId,
  getKeycloak,
  getToken,
  updateToken,
  getFirstSchoolId,
  getSchoolIdList,
  getClasses,
  forceUpdateToken,
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { CloseModalBlock, CloseModalButton, IconClose, ModalAlertWrapper, ModalContentBlock } from './style';

interface ModalAlertProps {
  children: JSX.Element;
  isVisible: boolean;
  handleCloseModal(): void;
  showCloseButton: boolean;
  noWidth?: boolean;
}

export function ModalAlert({ children, isVisible, handleCloseModal, showCloseButton, noWidth }: ModalAlertProps) {
  const { t } = useTranslation();

  useEffect(() => {
    const contentContainerElement = document.getElementById('contentContainer');

    if (isVisible && contentContainerElement) {
      window.scrollTo({
        top: 0,
      });
      document.body.style.overflow = 'hidden';
      if (contentContainerElement) {
        contentContainerElement.style.overflowY = 'visible';
      }
    }

    if (!isVisible && contentContainerElement) {
      document.body.style.overflow = 'auto';
      if (contentContainerElement) {
        contentContainerElement.style.overflowY = 'auto';
      }
    }
  }, [isVisible]);

  return (
    <ModalAlertWrapper $isVisible={isVisible}>
      <ModalContentBlock $noWidth={noWidth}>
        {showCloseButton && (
          <CloseModalBlock>
            <CloseModalButton type="button" onClick={() => handleCloseModal()} aria-label={t(messages.closeModal)}>
              <IconClose />
            </CloseModalButton>
          </CloseModalBlock>
        )}
        {children}
      </ModalContentBlock>
    </ModalAlertWrapper>
  );
}

import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${p => `${p.theme.toRem(36)} auto ${p.theme.toRem(44)}`};
  justify-items: flex-start;
  row-gap: ${p => p.theme.toRem(59)};
  padding-top: ${p => p.theme.toRem(60)};
  width: 100%;
  margin-bottom: ${p => p.theme.toRem(20)};
`;

export const ClassItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: ${p => `${p.theme.toRem(48)} ${p.theme.toRem(28)}`};
  padding-bottom: ${p => p.theme.toRem(19)};
`;

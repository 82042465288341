import styled, { css } from 'styled-components';
import { FontBody, FontLabel, FontText, FontTextBold, pxToRem } from 'styles';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${pxToRem(97)} auto;
  row-gap: ${pxToRem(30)};
  background: ${p => p.theme.color.secondary_egg};
  width: 100vw;
  height: 100%;
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  row-gap: ${pxToRem(40)};
  width: ${pxToRem(772)};
  padding: ${pxToRem(60)} ${pxToRem(80)} ${pxToRem(80)} ${pxToRem(80)};
  background: ${p => p.theme.color.primary_white};
  border-radius: 0 30px 30px 30px;
  margin-bottom: ${pxToRem(130)};
`;

export const TitleBlock = styled.div`
  display: grid;
  grid-template-rows: ${pxToRem(36)} auto;
  row-gap: ${pxToRem(20)};
`;

export const MetadataWrapper = styled.div`
  display: grid;
  grid-template-rows: ${pxToRem(21)} ${pxToRem(21)} ${pxToRem(21)};
  grid-template-columns: 5fr 7fr;
  row-gap: ${pxToRem(2)};
`;

export const MetaLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${FontLabel};
  color: ${p => p.theme.color.turquoise_1};
  padding-top: ${pxToRem(2)};
`;

export const MetaValue = styled.span`
  ${FontBody};
  color: ${p => p.theme.color.primary_black};
  text-align: left;
  vertical-align: middle;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: ${pxToRem(407)};
`;

const textBlockStyle = css`
  ul,
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
      display: list-item;
      list-style: inherit;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`;

export const TextBlock = styled.div`
  ${FontText};
  color: ${p => p.theme.color.primary_black};
  ${textBlockStyle};
`;

export const AditionalTextsBlock = styled.div`
  display: grid;
  row-gap: ${pxToRem(20)};
  width: 100%;
`;

export const BorderedWrapper = styled.div<{ $borderColor: string }>`
  display: grid;
  grid-template-rows: auto auto;
  padding: ${pxToRem(20)};
  border: 1px solid ${p => p.$borderColor};
  row-gap: ${pxToRem(4)};
`;

export const AditionalTextTitle = styled.h3`
  ${FontTextBold};
  color: ${p => p.theme.color.primary_black};
`;

export const AditionalMaterialLink = styled.a`
  text-decoration: underline;
  ${FontText};
  color: ${p => p.theme.color.turquoise_1};
`;

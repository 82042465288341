import { css } from 'styled-components';
import { defaultTheme } from './defaultTheme';

export const { breakpoints } = defaultTheme;

export const media = {
  tablet: (...args) => css`
    @media only screen and (max-width: ${breakpoints.desktop - 1}px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media only screen and (min-width: ${breakpoints.desktop}px) {
      ${css(...args)}
    }
  `,
};

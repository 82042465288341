import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalHistory } from './global-history';
import { OpenRoute } from './OpenRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { openRoutes, protectedRoutes, RouteConfig } from './routeConfig';

export function Router() {
  return (
    <BrowserRouter>
      <GlobalHistory />
      <Routes>
        {protectedRoutes.map((route: RouteConfig) => (
          <Route
            key={route.path}
            element={<ProtectedRoute helmetProps={route.helmetProps} /* requiredRole={route.roleRequired} */ />}
          >
            <Route path={route.path} element={<route.element />} />
          </Route>
        ))}
        {openRoutes.map((route: RouteConfig) => (
          <Route key={route.path} element={<OpenRoute helmetProps={route.helmetProps} />}>
            <Route path={route.path} element={<route.element />} />
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}

import { SchoolClassBasic } from '@skribi/school-utils/src/resources';
import { WhiteWithBlackBorderLink } from 'components/Link';
import { t } from 'i18next';
import { IconPencil } from 'icons';
import React from 'react';
import { Routes } from 'routes';
import { messages } from './messages';
import { ClassName, ContentLink, LinkWrapper, Wrapper } from './style';

interface ClassCardProps {
  classInfo: SchoolClassBasic;
}

export function ClassCard({ classInfo }: ClassCardProps) {
  return (
    <Wrapper>
      <ContentLink to={Routes.ClassDashboard.replace(':classId', classInfo.id!)}>
        <ClassName>{classInfo.name}</ClassName>
      </ContentLink>
      <LinkWrapper>
        <WhiteWithBlackBorderLink to={Routes.ClassEditor.replace(':classId', classInfo.id!)}>
          <IconPencil />
          <span>{t(messages.edit)}</span>
        </WhiteWithBlackBorderLink>
      </LinkWrapper>
    </Wrapper>
  );
}
